import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, useTheme, NfstayProvider } from "./context/ThemeContext";
import { Providers } from "./rainbowKit";
import { AuthCoreContextProvider } from "@particle-network/auth-core-modal";
import { ModalProvider } from "@particle-network/connectkit";
import { BNBChain } from "@particle-network/chains";
import { evmWallets } from "@particle-network/connectors";

const RootComponent = () => {
  const { theme } = useTheme();

  return (
    <AuthCoreContextProvider
      options={{
        projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID,
        clientKey: process.env.REACT_APP_PARTICLE_CLIENT_ID,
        appId: process.env.REACT_APP_PARTICLE_APP_ID,
        customStyle: {
          zIndex: 2147483650, // must be greater than 2147483646
          logo:
            theme === "light"
              ? "https://photos.pinksale.finance/file/pinksale-logo-upload/1721288952175-35ed0ed869e731b5d32a13ffb3a36d5a.png"
              : "https://photos.pinksale.finance/file/pinksale-logo-upload/1721288907755-c9d48c1398d8b725a62b6d5baa11df3f.png",
          projectName: "NFsTay Auth",
          subtitle: "Login to App to continue",
          modalWidth: 400,
          modalHeight: 650,
          primaryBtnBorderRadius: 18,
          modalBorderRadius: 18,
          cardBorderRadius: 18,
        },
        themeType: theme,
      }}
    >
      <ModalProvider
        options={{
          projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID,
          clientKey: process.env.REACT_APP_PARTICLE_CLIENT_ID,
          appId: process.env.REACT_APP_PARTICLE_APP_ID,
          chains: [BNBChain],
          customStyle: {
            zIndex: 2147483650, // must be greater than 2147483646
            logo:
              theme === "light"
                ? "https://photos.pinksale.finance/file/pinksale-logo-upload/1721288952175-35ed0ed869e731b5d32a13ffb3a36d5a.png"
                : "https://photos.pinksale.finance/file/pinksale-logo-upload/1721288907755-c9d48c1398d8b725a62b6d5baa11df3f.png",
            projectName: "NFsTay Auth",
            subtitle: "Login to App to continue",
            modalWidth: 400,
            modalHeight: 650,
            primaryBtnBorderRadius: 18,
            modalBorderRadius: 18,
            cardBorderRadius: 18,
          },
          wallet: {
            customStyle: {
              supportChains: [BNBChain],
            },
          },
          connectors: [
            ...evmWallets({
              projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID,
              showQrModal: true,
            }),
          ],
          themeType: theme,
        }}
      >
        <Providers>
          <NfstayProvider>
            <App />
          </NfstayProvider>
        </Providers>
      </ModalProvider>
    </AuthCoreContextProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <RootComponent />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
