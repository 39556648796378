import React, { useState, useEffect } from "react";
import Card from "../common/Card";
import Layout from "../layouts/Layout";
import { GoGraph } from "react-icons/go";
import { IoDiamondOutline } from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";
import Stats from "../components/Stats";
import Swap from "../components/Swap";
import Cards from "../components/dashboard/Cards";
import Incinerator from "../components/dashboard/Incinerator";
import Refer from "../components/dashboard/Refer";
import usdc from "../assets/icons/usdc.png";
import rocks from "../assets/icons/rocks.png";
import stay from "../assets/icons/stay.png";
import bnb from "../assets/icons/bnb.png";
import stayIcon from "../assets/icons/stay.svg";
import cards from "../assets/images/cards2.png";
import MintNowModal from "../components/modal/dashboard/MintNow";
import USDCModal from "../components/modal/dashboard/USDC";
import BNBModal from "../components/modal/dashboard/BNB";
import { useNfstay } from "../context/ThemeContext";
import cardBg1 from "../assets/images/card_bg1.jfif";
import cardBg2 from "../assets/images/card_bg2.jfif";
import euro from "../assets/icons/euro.png";
import usd from "../assets/icons/usd.png";
import approx from "approximate-number";

const fiatOptions1 = [
  { value: "USD", label: "USD", icon: usd },
  { value: "EUR", label: "EURO", icon: euro },
];
// const fiatOptions2 = [{ value: "STAY", label: "STAY", icon: stay }];
const fiatOptions2 = [{ value: "USDC", label: "USDC", icon: usdc }];

const mintOptions1 = [
  { value: "USD", label: "USD", icon: usd },
  { value: "EUR", label: "EURO", icon: euro },
];

const mintOptions2 = [{ value: "USDC", label: "USDC", icon: usdc }];

const stayOptions1 = [
  { value: "USDC", label: "USDC", icon: usdc },
  { value: "BNB", label: "BNB", icon: bnb },
];
const stayOptions2 = [{ value: "STAY", label: "STAY", icon: stay }];

const Dashboard = () => {
  const {
    stayPrice,
    Router_getStayPrice,
    dashboardDetails,
    burnHistory,
    getDashboardValues,
    getBurnHistory,
    assetPrices,
  } = useNfstay();

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [fiatData1, setFiatData1] = useState(fiatOptions1?.[0] || {});
  const [fiatData2, setFiatData2] = useState(fiatOptions2?.[0] || {});
  const [mintData1, setMintData1] = useState(mintOptions1?.[0] || {});
  const [mintData2, setMintData2] = useState(mintOptions2?.[0] || {});
  const [mintValue2, setMintValue2] = useState("");
  const [stayData1, setStayData1] = useState(stayOptions1?.[0] || {});
  const [stayData2, setStayData2] = useState(stayOptions2?.[0] || {});
  const [stayValue, setStayValue] = useState("");
  const [stayFiatValue, setStayFiatValue] = useState("");
  const [usdcFiatValue, setUSDCFiatValue] = useState("");
  const [estimation, setEstimation] = useState("");
  const [estimation2, setEstimation2] = useState("");

  useEffect(() => {
    const details = async () => {
      await getDashboardValues();
      await getBurnHistory();
    };
    details();
  }, []);

  const stats = [
    {
      icon: (
        <GoGraph
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          {/* ${" "}
          <Counter
            end={dashboardDetails.tvl}
            decimalPlaces={2}
            thousandsSeparator=","
          /> */}
          $
          {approx(assetPrices.lpPrice * assetPrices.lpSupply || 0, {
            capital: true,
            min10k: true,
            precision: 3,
          })}
        </>
      ),
      text: "STAY Liquidity",
    },
    {
      icon: (
        <img
          src={stayIcon}
          alt="stayIcon"
        />
      ),
      count: (
        <>
          {/* ${" "}
        <Counter
          end={dashboardDetails.noOfStay}
          decimalPlaces={2}
          thousandsSeparator=","
        /> */}

          {approx(dashboardDetails.noOfStay || 0, {
            capital: true,
            min10k: true,
            precision: 3,
          })}
        </>
      ),
      text: "Total STAY Locked",
    },
    {
      icon: (
        <IoDiamondOutline
          stroke="white"
          size={22}
        />
      ),
      count: (
        <>
          {/* ${" "}
    <Counter
      end={dashboardDetails.noOfNFTs}
      decimalPlaces={2}
      thousandsSeparator=","
    /> */}

          {approx(dashboardDetails.noOfNFTs || 0, {
            capital: true,
            min10k: true,
            precision: 3,
          })}
        </>
      ),
      text: "ROCKS Staked",
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          {/* ${" "}
      <Counter
        end={dashboardDetails.totalPaid}
        decimalPlaces={2}
        thousandsSeparator=","
      /> */}
          $
          {approx(
            dashboardDetails.noOfNFTs * assetPrices.rockPrice +
              dashboardDetails.noOfStay * assetPrices.stayPrice +
              assetPrices.lpPrice * assetPrices.lpSupply || 0,
            {
              capital: true,
              min10k: true,
              precision: 3,
            },
          )}
        </>
      ),
      text: "Total Value Locked",
    },
  ];

  useEffect(() => {
    const price = async () => {
      // setIsLoading(true);
      if (stayData1.value === "BNB") {
        await Router_getStayPrice(true);
        // setIsLoading(false);

        return;
      }
      await Router_getStayPrice();
      // setIsLoading(false);
    };
    price();
  }, [stayData1]);

  useEffect(() => {
    const handleValueChange = async () => {
      if (stayFiatValue > 24) {
        const response = await fetch("https://gateway.ari10.com/currencies/USDC/calculate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Ari10-Widget-Id": process.env.REACT_APP_GATEWAY_ID,
          },
          body: JSON.stringify({
            offeredAmount: stayFiatValue,
            offeredCurrencyCode: fiatData1.value,
          }),
        });
        if (response.ok) {
          const data = await response.json();

          setEstimation(data.amount?.toFixed(2)); // Do something with the data
        } else {
          // Handle errors, e.g., show an error message
          console.error("Failed to fetch data: ", response.statusText);
        }
      } else {
        setEstimation("");
      }
    };
    handleValueChange();
  }, [fiatData1.value, stayFiatValue]);

  useEffect(() => {
    const handleValueChange = async () => {
      if (usdcFiatValue > 24) {
        const response = await fetch("https://gateway.ari10.com/currencies/USDC/calculate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Ari10-Widget-Id": process.env.REACT_APP_GATEWAY_ID,
          },
          body: JSON.stringify({
            offeredAmount: usdcFiatValue,
            offeredCurrencyCode: mintData1.value,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setEstimation2(data.amount?.toFixed(2));
        } else {
          // Handle errors, e.g., show an error message
          console.error("Failed to fetch data: ", response.statusText);
        }
      } else {
        setEstimation2("");
      }
    };
    handleValueChange();
  }, [mintData1.value, usdcFiatValue]);

  const handleFiatChange1 = value => {
    setFiatData1(value);
  };

  const handleFiatChange2 = value => {
    setFiatData2(value);
  };

  const handleMintChange1 = value => {
    setMintData1(value);
  };

  const handleMintChange2 = value => {
    setMintData2(value);
  };

  const handleStayChange1 = value => {
    setStayData1(value);
  };

  const handleStayChange2 = value => {
    setStayData2(value);
  };

  return (
    <Layout>
      <Stats
        title="Dashboard"
        subTitle="Welcome"
        stats={stats}
      />
      <p className="mb-12 md:mb-16 -mt-5 text-lg font-medium md:text-2xl md:font-semibold text-white">
        Buy ROCK NFT with CARD in 2 Steps
      </p>
      <div className="flex flex-wrap gap-12 md:gap-4 w-full justify-between">
        <Card
          className="w-full lg:w-[49%] relative !rounded-[0_0_11px_11px] before:rounded-[0_0_11px_11px] before:absolute before:bg-[#00000080] before:w-full before:h-full before:top-0 before:left-0 before:z-0"
          style={{ background: `url(${cardBg2}) no-repeat`, backgroundSize: "cover" }}
        >
          <div
            className="rounded-[11px_11px_0_0] absolute left-0 -top-[36px] blurry w-full text-white text-sm p-2 text-center"
            style={{ background: "#FFFFFF30" }}
          >
            Step 1
          </div>
          <Swap
            isWhite={true}
            isAri={true}
            source="USDCDEBIT"
            title="Buy USDC with CARD"
            image={cards}
            from="You're Paying"
            to="You’re Buying"
            value={usdcFiatValue}
            setValue={setUSDCFiatValue}
            estimation2={estimation2}
            options1={mintOptions1 || []}
            options2={mintOptions2 || []}
            data1={mintData1}
            data2={mintData2}
            handleChange1={handleMintChange1}
            handleChange2={handleMintChange2}
          />
        </Card>
        <Card
          className="w-full lg:w-[49%] relative !rounded-[0_0_11px_11px] before:rounded-[0_0_11px_11px] before:absolute before:bg-[#00000080] before:w-full before:h-full before:top-0 before:left-0 before:z-0"
          style={{ background: `url(${cardBg1}) no-repeat`, backgroundSize: "cover" }}
        >
          <div
            className="rounded-[11px_11px_0_0] absolute left-0 -top-[36px] blurry w-full text-white text-sm p-2 text-center"
            style={{ background: window.innerWidth <= 768 ? "#00000054" : "#FFFFFF30" }}
          >
            Step 2
          </div>
          <Swap
            isWhite={true}
            source="ROCK"
            title="Mint ROCKs with USDC"
            from="You're Buying"
            to="You're Paying"
            btn="Mint Now"
            value={mintValue2}
            setValue={setMintValue2}
            onClick={() => setShowModal1(true)}
            options1={[{ value: "ROCKS", label: "ROCKS", icon: rocks }] || []}
            options2={[{ value: "USDC", label: "USDC", icon: usdc }] || []}
            data1={{ value: "ROCKS", label: "ROCKS", icon: rocks }}
            data2={{ value: "USDC", label: "USDC", icon: usdc }}
          />
        </Card>
      </div>
      {/* <div className="w-full mt-6">
        <Card className="w-full">
          <Stats2 />
        </Card>
      </div> */}
      <div className="w-full mt-6">
        <Cards />
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card
          className="w-full lg:w-[49%] relative before:rounded-[11px] before:absolute before:bg-[#00000080] before:w-full before:h-full before:top-0 before:left-0 before:z-0"
          style={{ background: `url(${cardBg2}) no-repeat`, backgroundSize: "cover" }}
        >
          <Swap
            isWhite={true}
            isAri={true}
            // source="USDCDEBIT"
            title="BUY USDC with DEBIT CARD"
            image={cards}
            from="You're Paying"
            to="You’re Buying"
            source="STAYDEBIT"
            value={stayFiatValue}
            setValue={setStayFiatValue}
            estimation={estimation}
            options1={fiatOptions1 || []}
            options2={fiatOptions2 || []}
            data1={fiatData1}
            data2={fiatData2}
            handleChange1={handleFiatChange1}
            handleChange2={handleFiatChange2}
            // setValue={setUSDCFiatValue}
            // value={usdcFiatValue}
            // estimation2={estimation2}
            // options1={mintOptions1 || []}
            // options2={mintOptions2 || []}
            // data1={mintData1}
            // data2={mintData2}
            // handleChange1={handleMintChange1}
            // handleChange2={handleMintChange2}
          />
        </Card>
        <Card className="w-full lg:w-[49%]">
          <Swap
            source="buyStay"
            stayPrice={stayPrice && stayPrice}
            title="Buy STAY with Crypto"
            from="You're Paying"
            to="To Receive"
            btn="Swap Now"
            onClick={() => setShowModal2(true)}
            value={stayValue}
            setValue={setStayValue}
            options1={stayOptions1 || []}
            options2={stayOptions2 || []}
            data1={stayData1}
            data2={stayData2}
            handleChange1={handleStayChange1}
            handleChange2={handleStayChange2}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full my-10">
        <Incinerator burnHistory={burnHistory} />
      </div>
      <div className="flex flex-wrap gap-4 w-full">
        <Card className="w-full">
          <Refer />
        </Card>
      </div>

      {showModal1 && (
        <MintNowModal
          setMintValue2={setMintValue2}
          rocks={mintValue2}
          onHide={() => setShowModal1(false)}
        />
      )}
      {stayData1?.value === "USDC" && showModal2 ? (
        <USDCModal
          setStayValue={setStayValue}
          stayValue={stayValue}
          onHide={() => setShowModal2(false)}
        />
      ) : stayData1?.value === "BNB" && showModal2 ? (
        <BNBModal
          setStayValue={setStayValue}
          stayValue={stayValue}
          onHide={() => setShowModal2(false)}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Dashboard;
