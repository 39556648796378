import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../../layouts/Modal";
import Button from "../../../common/Button";
import image1 from "../../../assets/images/progress1_1.png";
import image2 from "../../../assets/images/progress1_2.png";
import image3 from "../../../assets/images/progress1_3.png";
import image4 from "../../../assets/images/progress1_4.png";
import { useNfstay } from "../../../context/ThemeContext";
import { discountedNftAddress, myusdAddress, oldLpAddress } from "../../../utils/constants";
import toast from "react-hot-toast";
import BLoader from "../../../common/BLoader";
import { useAccount } from "wagmi";

const SwapModalD = ({ setStayValue, onHide, selected1, selected2, amount }) => {
  const [isExploding, setIsExploding] = React.useState(false);
  const [status, setStatus] = useState({ isApproved: false, isGet: false });
  const [showLast, setShowLast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const {
    getUSDCContract,
    getDiscountedContract,
    usdcApprovalDiscounted,
    getItemsReminaing,
    getDicountedPrice,
  } = useNfstay();
  const { address: account } = useAccount();

  const approve = async () => {
    try {
      setLoading(true);
      let value = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

      let tx = await getUSDCContract(true).approve(discountedNftAddress, value);
      await tx.wait();
      setStatus({ isApproved: true, isGet: false });
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const buyNFT = async () => {
    try {
      setLoading2(true);
      const tx = await getDiscountedContract(true).buyNFT(amount);
      await tx.wait();

      setStatus({ isApproved: true, isGet: true });
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
      setStayValue("");
      await getItemsReminaing();
      await getDicountedPrice();
      toast.success("Transaction Successful");
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    setStatus({ isApproved: usdcApprovalDiscounted, isGet: false });
  }, []);

  return (
    <CustomModal
      title={`Buy Rocks`}
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={
              !status.isApproved && !status.isGet
                ? image1
                : status.isApproved && !status.isGet
                  ? image2
                  : status.isApproved && status.isGet
                    ? image3
                    : image4
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={image4}
            alt="step"
          />
        )}
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve {selected2?.value}</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>

          <Button
            // onClick={() => setStatus({ isApproved: true, isGet: false })}
            onClick={approve}
            className="w-full flex items-center gap-2 justify-center text-main"
            disabled={status.isApproved || loading}
            style={{
              color: !status.isApproved ? "#FFFFFF" : "var(--main)",
              background: status.isApproved
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
          >
            {loading ? (
              <BLoader />
            ) : (
              <>
                {!status.isApproved ? (
                  <>
                    <img
                      src={selected2?.icon}
                      alt={selected2?.value}
                    />
                    Approve {selected2?.value}
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Get {selected1?.value}</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color: status.isApproved && !status.isGet ? "#FFFFFF" : "var(--main)",
              background:
                status.isApproved && !status.isGet
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            // onClick={() => setStatus({ isApproved: true, isGet: true })}
            onClick={buyNFT}
            disabled={!status.isApproved || loading2}
          >
            {loading2 ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={selected1?.icon}
                  alt={selected1?.value}
                />
                Get {selected1?.value}
              </>
            )}
          </Button>
        </div>
        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(SwapModalD);
