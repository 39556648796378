import React from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";
import Button from "./Button";
import { useAccount } from "wagmi";

const Sidebar = ({ toggle, setToggle, links, activeClass, setSocialLoginModal }) => {
  const { isConnected } = useAccount();
  return (
    <div
      className={`sidebar lg:hidden fixed h-[100vh] ${toggle ? "left-0" : "left-[-300px]"} w-[300px] p-8 z-[9982] transition-all`}
    >
      <div className="w-full flex justify-end">
        <IoIosCloseCircle
          size={26}
          fill="#FFFFFF"
          className="lg:hidden cursor-pointer"
          style={{ transform: "rotateY(180deg" }}
          onClick={() => [
            setToggle(false),
            (document.querySelector("body").style.overflowY = "scroll"),
          ]}
        />
      </div>
      <div className="mt-4">
        {links?.map(itm => (
          <Link
            key={itm.to}
            to={itm.to}
            className={`mb-4 text-white capitalize block w-fit ${activeClass(itm.to)}`}
          >
            {itm.title}
          </Link>
        ))}
      </div>
      {!isConnected && (
        <div className="mt-7">
          <Button
            className="w-full flex items-center gap-2 justify-center"
            onClick={() => setSocialLoginModal(true)}
          >
            <span className="text-white">Sign In</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
