import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../../layouts/Modal";
import Button from "../../../common/Button";
import lp from "../../../assets/icons/stayUsdc.png";
import image1 from "../../../assets/images/progress3_1.png";
import image2 from "../../../assets/images/progress3_2.png";
import image3 from "../../../assets/images/progress3_3.png";
import image4 from "../../../assets/images/progress3_4.png";
import image5 from "../../../assets/images/progress3_5.png";
import image6 from "../../../assets/images/progress3_6.png";
import { useNfstay } from "../../../context/ThemeContext";
import toast from "react-hot-toast";
import BLoader from "../../../common/BLoader";

const USDCSTAYLiquidity = ({
  isApprovedToken,
  maxBalance,
  setInputVal,
  inputVal,
  onHide,
  data,
}) => {
  const {
    checkTokenApprovalToZapper,
    zapperApproval,
    approveToZapper,
    zapper_swapping,
    Farming_stakeLps,
  } = useNfstay();
  const [isApproved, setIsApproved] = useState(false);
  const [isApprovedLP, setIsApprovedLP] = useState(false);
  const [depositValue, setDepositValue] = useState("");
  const [isLoadingApproveLp, setIsLoadingApproveLp] = useState(false);
  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
  const [isLoadingApproval, setIsLoadingApproval] = useState(false);
  const [isLoadingZap, setIsLoadingZap] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState({
    isApprove: false,
    isZap: false,
    isApproved: false,
    isDeposit: false,
  });
  const [showLast, setShowLast] = React.useState(false);
  useEffect(() => {
    if (status.isApprove && status.isZap && status.isApproved && status.isDeposit) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  useEffect(() => {
    setStatus(prev => ({ ...prev, isApprove: isApprovedToken }));
  }, []);

  const approveToken = async currency => {
    try {
      if (currency === "LP") {
        setIsLoadingApproveLp(true);
        await approveToZapper(currency);
        status.isZap && setStatus(prev => ({ ...prev, isApproved: true }));
        setIsApprovedLP(true);
      } else {
        setIsLoadingApproval(true);
        await approveToZapper(currency);
        setStatus(prev => ({ ...prev, isApprove: true }));
        setIsApproved(true);
      }
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingApproval(false);
      setIsLoadingApproveLp(false);
    }
  };

  const handleZapIn = async currency => {
    try {
      setIsLoadingZap(true);
      const tx = await zapper_swapping(currency, inputVal);
      setDepositValue(tx);
      await maxBalance(currency);

      const res = await checkTokenApprovalToZapper("LP");
      if (res) {
        status.isApprove && setStatus(prev => ({ ...prev, isZap: true, isApproved: true }));
      }
      status.isApprove && setStatus(prev => ({ ...prev, isZap: true }));
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingZap(false);
    }
  };

  const handleDeposit = async () => {
    try {
      setIsLoadingDeposit(true);

      await Farming_stakeLps(depositValue);
      toast.success("Transaction Successful");
      setDepositValue("");
      setInputVal("");
      status.isApproved && setStatus(prev => ({ ...prev, isDeposit: true }));
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingDeposit(false);
    }
  };

  return (
    <CustomModal
      title="Stake To Earn"
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[340px] sm:h-[377px] top-[3px]"
            src={
              !status.isApprove && !status.isZap && !status.isApproved && !status.isDeposit
                ? image1
                : status.isApprove && !status.isZap && !status.isApproved && !status.isDeposit
                  ? image2
                  : status.isApprove && status.isZap && !status.isApproved && !status.isDeposit
                    ? image3
                    : status.isApprove && status.isZap && status.isApproved && !status.isDeposit
                      ? image4
                      : status.isApprove && status.isZap && status.isApproved && status.isDeposit
                        ? image5
                        : image6
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[350px] sm:h-[380px] top-[3px]"
            src={image6}
            alt="step"
          />
        )}
        <div className="mb-1">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve {data?.label}</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            onClick={() => approveToken(data?.label)}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color: !status.isApprove ? "#FFFFFF" : "var(--main)",
              background: status.isApprove
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
            disabled={
              isLoadingZap ||
              isLoadingApproval ||
              isLoadingApproveLp ||
              isLoadingDeposit ||
              status.isApprove
            }
          >
            {isLoadingApproval ? (
              <BLoader />
            ) : (
              <>
                {!status.isApprove ? (
                  <>
                    <img
                      src={data?.icon}
                      alt={data?.label}
                      className="h-[26px]"
                    />{" "}
                    Approve {data?.label}
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>
        <div className="mb-2">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Create LP Token</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            onClick={() => handleZapIn(data?.label)}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color: status.isApprove && !status.isZap ? "#FFFFFF" : "var(--main)",
              background:
                status.isApprove && !status.isZap
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            disabled={
              isLoadingZap ||
              isLoadingApproval ||
              isLoadingApproveLp ||
              isLoadingDeposit ||
              !status.isApprove ||
              status.isZap
            }
          >
            {isLoadingZap ? <BLoader /> : <>ZAP IN ⚡️</>}
          </Button>
        </div>
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve LP Token</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 3</span>
          </div>
          <Button
            onClick={() => approveToken("LP")}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color:
                status.isApprove && status.isZap && !status.isApproved ? "#FFFFFF" : "var(--main)",
              background:
                status.isApprove && status.isZap && !status.isApproved
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            disabled={
              isLoadingZap ||
              isLoadingApproval ||
              isLoadingApproveLp ||
              isLoadingDeposit ||
              !status.isApprove ||
              !status.isZap ||
              status.isApproved
            }
          >
            {isLoadingApproveLp ? (
              <BLoader />
            ) : (
              <>
                {!status.isApproved ? (
                  <>
                    <img
                      src={lp}
                      alt="lp"
                    />{" "}
                    Approve LP Token
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>

        <div className="mt-2">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Deposit LP Token</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 4</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color:
                status.isApprove && status.isZap && status.isApproved && !status.isDeposit
                  ? "#FFFFFF"
                  : "var(--main)",
              background:
                status.isApprove && status.isZap && status.isApproved && !status.isDeposit
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            onClick={handleDeposit}
            disabled={
              isLoadingApproval ||
              isLoadingApproveLp ||
              isLoadingZap ||
              isLoadingDeposit ||
              !status.isApprove ||
              !status.isZap ||
              !status.isApproved ||
              status.isDeposit
            }
          >
            {isLoadingDeposit ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={lp}
                  alt="lp"
                />{" "}
                Deposit LP Token
              </>
            )}
          </Button>
        </div>
        <div className="mt-2">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(USDCSTAYLiquidity);
