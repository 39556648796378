import React, { useEffect, useState } from "react";
import rentSaver from "../../assets/images/gif/rentSaver.gif";
import studioPioneer from "../../assets/images/gif/studioPioneer.gif";
import apartmentInvestor from "../../assets/images/gif/apartmentInvestor.gif";
import homeStrategist from "../../assets/images/gif/homeStrategist.gif";
import developmentExplorer from "../../assets/images/gif/developmentExplorer.gif";
import commercialDweller from "../../assets/images/gif/commercialDweller.gif";
import luxuryConnoisseur from "../../assets/images/gif/luxuryConnoisseur.gif";
import businessTycoon from "../../assets/images/gif/businessTycoon.gif";
import realEstateMogul from "../../assets/images/gif/realEstateMogul.gif";
import theWorldIsYours from "../../assets/images/gif/theWorldIsYours.gif";
import bw1 from "../../assets/images/disabled_gif/1.png";
import bw2 from "../../assets/images/disabled_gif/2.png";
import bw3 from "../../assets/images/disabled_gif/3.png";
import bw4 from "../../assets/images/disabled_gif/4.png";
import bw5 from "../../assets/images/disabled_gif/5.png";
import bw6 from "../../assets/images/disabled_gif/6.png";
import bw7 from "../../assets/images/disabled_gif/7.png";
import bw8 from "../../assets/images/disabled_gif/8.png";
import bw9 from "../../assets/images/disabled_gif/9.png";
import bw10 from "../../assets/images/disabled_gif/10.png";
import Button from "../../common/Button";
import CustomModal from "../../layouts/Modal";
import BNBBuyBadge from "./BNBBuyBadge";
import STAYBuyBadge from "./STAYBuyBadge";
import USDCBuyBadge from "./USDCBuyBadge";
import { useNfstay } from "../../context/ThemeContext";
import BLoader from "../../common/BLoader";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import StakeROCKsModal from "./StakeROCKsModal";
import stayBg from "../../assets/images/stayBg.png";
import ClaimPrinter from "../modal/printer/Claim";
import StartEarn from "../printer/StayEarned";
import Card from "../../common/Card";
import Select, { components } from "react-select";
import { Skeleton } from "@mui/material";
import { IoRocketOutline } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { RiErrorWarningLine } from "react-icons/ri";
import approx from "approximate-number";
import { stayAddress, supportedChain, usdcAddress, wbnb } from "../../utils/constants";
import { ethers } from "ethers";
import { IoChevronDown } from "react-icons/io5";

const badgeGif = {
  // 0: rentSaver, // no badge
  0: rentSaver,
  1: studioPioneer,
  2: apartmentInvestor,
  3: homeStrategist,
  4: developmentExplorer,
  5: commercialDweller,
  6: luxuryConnoisseur,
  7: businessTycoon,
  8: realEstateMogul,
  9: theWorldIsYours,
  // 11: theWorldIsYours, //coming soon
};
const disablesBadgeGif = {
  0: bw1,
  1: bw2,
  2: bw3,
  3: bw4,
  4: bw5,
  5: bw6,
  6: bw7,
  7: bw8,
  8: bw9,
  9: bw10,
};

const limitForEachLevel = [
  {
    requried: 1,
  },
  {
    requried: 6,
  },
  {
    requried: 16,
  },
  {
    requried: 26,
  },
  {
    requried: 36,
  },
  {
    requried: 51,
  },
  {
    requried: 76,
  },
  {
    requried: 101,
  },
  {
    requried: 151,
  },
  {
    requried: 251,
  },
];

// const limitForEachLevel = [
//   {
//     requried: 1,
//   },
//   {
//     requried: 3,
//   },
//   {
//     requried: 5,
//   },
//   {
//     requried: 7,
//   },
//   {
//     requried: 9,
//   },
//   {
//     requried: 11,
//   },
//   {
//     requried: 13,
//   },
//   {
//     requried: 15,
//   },
//   {
//     requried: 17,
//   },
//   {
//     requried: 19,
//   },
// ];
const Option = props => (
  <components.Option {...props}>
    <img
      src={props.data.icon}
      alt="logo"
      className="swap-icon"
      loading="lazy"
    />
    {props.data.label}
  </components.Option>
);

const RentSaver = ({ data, assets, active, badges, source }) => {
  const [asset, setAsset] = useState(assets[0]);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [badgeId, setBadgeId] = useState("");
  const [stayQuantity, setStayQuantity] = useState("");
  const [showStakeModal, setShowStakeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [badgePrice, setBadgePrice] = useState(0);

  const { chainId, isConnected } = useAccount();

  const [isApprovedRocksAndStay, setIsApprovedRocksAndStay] = useState({
    isApproveRocks: false,
    isApprovedStay: false,
  });

  const [isApprovedUSDCAndStay, setIsApprovedUSDCAndStay] = useState({
    isApprovedUSDC: false,
    isApprovedStay: false,
  });

  const {
    boostAPR,
    oldPrinterDetails,
    printerDetails,
    assetPrices,
    unstakeRocks,
    porfolioDetails,
    unstakeStay,
    printerRewards,
    getPortfolioValues,
    account,
    isApproveRocks,
    IsapproveStayToPrinter,
    IsapproveUsdcToPrinter,
    boosterAmounts,
    badgePrices,
    getRouterContract,
    getOldPrinterContract,
    getOldPrinterDetails,
  } = useNfstay();

  useEffect(() => {
    const details = async () => {
      if (isConnected) {
        await getPortfolioValues();
      }
    };
    details();
  }, [account, isConnected]);

  useEffect(() => {
    const set = async () => {
      if (isConnected) {
        await setBadgePricesFunc();
      }
    };
    set();
  }, [asset]);

  const unstakeRocksOld = async quantity => {
    const res = await getOldPrinterContract(true).unstakeROCKs(quantity);
    await res.wait();
    await getOldPrinterDetails();
  };

  const unstakeStayOld = async amount => {
    const res = await getOldPrinterContract(true).unstakeStay(
      ethers.utils.parseEther(String(amount)),
    );
    await res.wait();
    await getOldPrinterDetails();
  };

  const setBadgePricesFunc = async () => {
    try {
      let path = [usdcAddress, wbnb];
      let price;
      if (asset?.value === "BNB") {
        price = await getRouterContract().getAmountsOut(
          ethers.utils.parseEther(badgePrices[active]),
          path,
        );
        price = parseInt(price[1]._hex);
        price = Number(ethers.utils.formatEther(`${price}`)).toFixed(5);
      } else if (asset.value === "STAY") {
        path = [usdcAddress, stayAddress];
        price = await getRouterContract().getAmountsOut(
          ethers.utils.parseEther(badgePrices[active]),
          path,
        );
        price = Number(ethers.utils.formatEther(`${price[1]._hex}`)).toFixed(2);
      } else {
        price = badgePrices[active];
      }

      setBadgePrice(price);
    } catch (err) {
      console.log(err);
    }
  };

  const handleBoost = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    try {
      setLoading(true);
      await boostAPR();
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleUnstake = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    try {
      setLoading2(true);
      if (source == "oldprinter") {
        await unstakeRocksOld(quantity);
      } else {
        await unstakeRocks(quantity);
      }
      setQuantity("");
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading2(false);
    }
  };

  const handleOpen = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }
    setLoader(true);

    const res = await isApproveRocks();
    const res2 = await IsapproveStayToPrinter();
    setIsApprovedRocksAndStay({ isApproveRocks: res, isApprovedStay: res2 });

    setShowStakeModal(true);
    setLoader(false);
  };

  const handleStayUnstake = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    try {
      setLoading4(true);

      if (source === "oldprinter") {
        await unstakeStayOld(stayQuantity);
      } else {
        await unstakeStay(stayQuantity);
      }
      setStayQuantity("");
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading4(false);
    }
  };

  const handleUpgrade = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    await setBadgePricesFunc();
    setShowModal(true);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {asset?.icon && (
        <img
          src={asset?.icon}
          alt="Icon"
          loading="lazy"
          className="swap-selected-icon"
        />
      )}
      {children}
    </components.SingleValue>
  );
  const buyBadge = async () => {
    setLoader2(true);
    const id = active + 1;

    if (asset?.value === "STAY") {
      const res = await IsapproveStayToPrinter();
      setIsApprovedUSDCAndStay(prev => ({ ...prev, isApprovedStay: res }));
    } else if (asset?.value === "USDC") {
      const res = await IsapproveUsdcToPrinter();
      setIsApprovedUSDCAndStay(prev => ({ ...prev, isApprovedUSDC: res }));
    }
    setShowModal(false);
    setShowModal2(true);
    setBadgeId(id);
    setLoader2(true);
  };

  const [isGIFLoading, setIsGIFLoading] = useState(true);

  return (
    <div className="flex gap-5 max-lg:flex-wrap">
      <div className="flex gap-5 flex-wrap w-full">
        <div className="w-full md:w-[50%]">
          <div className="w-full h-full rounded-xl relative overflow-hidden">
            <img
              src={
                printerDetails.currentLevel - 1 === active
                  ? badgeGif[active]
                  : disablesBadgeGif[active]
              }
              className="w-full h-full"
              loading="lazy"
              alt="Badge"
              onLoad={() => setIsGIFLoading(false)}
              onContextMenu={e => e.preventDefault()}
            />
            {isGIFLoading && (
              <Skeleton
                sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
                animation="wave"
                variant="rounded"
                height="100%"
              />
            )}
          </div>
        </div>
        <div className="w-full md:w-[47%]">
          {/* <h2 className="text-main text-xl md:text-3xl font-semibold mb-2 flex items-center justify-between">
            <span>
              {printerDetails.currentLevel > 0 && badges[printerDetails.currentLevel - 1].text}
            </span>
            <p className="border border-primary rounded-lg px-3 py-2 text-main text-sm">
              Current APR:{" "}
              {printerDetails.currentLevel > 0
                ? printerDetails.isBoosted
                  ? aprs.boostedAPR[printerDetails.currentLevel - 1]
                  : aprs.NormalAPR[printerDetails.currentLevel - 1]
                : 0}
              %
            </p>
          </h2> */}

          <div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-main text-lg font-bold">Stake ROCKS</span>
              <p className="text-main text-sm font-bold">
                <span className="text-main opacity-70">Your Balance:</span>{" "}
                {porfolioDetails.rocksTokens} ROCKS
              </p>
            </div>
            <div className="space-y-4">
              <div className="flex gap-2 justify-between items-center max-sm:flex-wrap">
                <div className="w-full bg-[#F1F4F9] bg-bg_theme2 p-2 rounded-md flex gap-2 items-center">
                  <input
                    disabled={source === "oldprinter" && oldPrinterDetails.stakedNFTs == 0}
                    type="number"
                    pattern="\d*"
                    value={quantity}
                    placeholder="ROCKS to be staked"
                    min={0.0}
                    className="w-full min-h-full bg-transparent"
                    onChange={e => setQuantity(e.target.value)}
                  />
                  <Button
                    className="border-primary text-main"
                    style={{ background: "transparent", paddingTop: "6px", paddingBottom: "6px" }}
                    onClick={() => setQuantity(porfolioDetails.rocksTokens)}
                    disabled={porfolioDetails.rocksTokens === 0 || source === "oldprinter"}
                  >
                    Max
                  </Button>
                </div>
              </div>
            </div>
            <div className="max-sm:space-y-4 sm:space-x-4 flex max-sm:flex-col items-center my-4">
              <Button
                className="w-full text-nowrap text-[14px]"
                // onClick={handlestake}
                onClick={handleOpen}
                disabled={
                  loading ||
                  loading2 ||
                  loading3 ||
                  loading4 ||
                  quantity === "0" ||
                  quantity === "" ||
                  porfolioDetails.rocksTokens === 0 ||
                  loader ||
                  source === "oldprinter"
                }
              >
                {loading3 ? <BLoader /> : "Stake ROCKS"}
              </Button>
              <Button
                className="text-[14px] max-md:w-full text-nowrap bg-transparent text-main border-[var(--main)]"
                onClick={handleUnstake}
                disabled={
                  loading ||
                  loading2 ||
                  loading3 ||
                  loading4 ||
                  quantity === "0" ||
                  quantity === "" ||
                  (source === "oldprinter" && oldPrinterDetails.stakedNFTs == 0) ||
                  (source !== "oldprinter" && printerDetails.stakedNFTs == 0)
                }
              >
                {loading2 ? <BLoader isWhite={false} /> : "Unstake"}
              </Button>
              {showStakeModal && (
                <StakeROCKsModal
                  loader={loader}
                  isApprovedRocksAndStay={isApprovedRocksAndStay}
                  setInputVal={setQuantity}
                  inputVal={quantity}
                  onHide={() => setShowStakeModal(false)}
                />
              )}
            </div>

            <Card
              className="overflow-hidden relative before:absolute before:bg-[#00000080] before:w-full before:h-full before:top-0 before:left-0 before:z-0 !p-4"
              style={{ background: `url(${stayBg}) no-repeat`, backgroundSize: "cover" }}
            >
              <StartEarn
                source={source}
                printerRewards={
                  (Number(printerRewards) + Number(printerDetails?.pendingRewards) || 0) *
                  (Number(assetPrices?.stayPrice) || 0)
                }
                isClaimOnly={false}
                showModal={() => setShowModal1(true)}
              />
            </Card>
            {/* <Button
              className="w-full glow-btn !mt-5"
              style={{ background: "var(--bg_grad)", border: 0 }}
              onClick={handleUpgrade}
              disabled={printerDetails.currentLevel === 10}
            >
              {printerDetails.currentLevel === 10
                ? badges[printerDetails.currentLevel].text
                : `Upgrade to ${printerDetails.currentLevel === 0 ? badges[0].text : printerDetails.currentLevel === getEstimatedLevel(printerDetails.stakedNFTs) ? badges[printerDetails.currentLevel].text : badges[getEstimatedLevel(printerDetails.stakedNFTs) - 1].text} `}
            </Button> */}
          </div>
        </div>
      </div>
      <div className="max-lg:h-[2px] lg:min-h-full max-lg:w-full lg:w-[2px] bg-[#0000002b]"></div>
      <div className="max-lg:w-full lg:max-w-[300px]">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-bold">{badges[active].text}</h3>
          {printerDetails?.badges[active] > 0 ? (
            ""
          ) : printerDetails.stakedNFTs + porfolioDetails.rocksTokens >=
            limitForEachLevel[active].requried ? (
            <Button
              className="glow-btn"
              style={{ background: "var(--bg_grad)", border: 0 }}
              onClick={handleUpgrade}
            >
              Buy badge
            </Button>
          ) : (
            <div className="flex justify-center mt-3">
              <Tooltip anchorSelect={`#buy-badge`}>
                {`You need ${limitForEachLevel[active].requried - (printerDetails.stakedNFTs + porfolioDetails.rocksTokens)} ROCKs to unlock this level`}
              </Tooltip>
              <RiErrorWarningLine
                id={`buy-badge`}
                fill="var(--main)"
                size={26}
                className="cursor-pointer"
              />
            </div>
          )}
        </div>
        {/* <div className="flex items-center justify-between">
          <p className="text-main text-sm font-semibold opacity-70">Next Badge:</p>
          <p className="text-main text-sm font-semibold">
            {badges[printerDetails.currentLevel].text}
          </p>
        </div> */}
        <div className="flex gap-2 md:gap-3 flex-wrap my-3 md:my-4">
          {data[active]?.map((itm, idx) => (
            <div
              key={idx}
              className="rounded-[10px] border border-bg_theme2 max-sm:p-2 py-2 px-3 w-[48%]"
            >
              <div>
                <p className="text-main font-semibold mb-2 flex items-end">
                  <span className="text-base sm:text-lg pr-1 -mb-1">
                    {itm?.text === "Per ROCKS" || itm?.text === "Boost"
                      ? `~${assetPrices.stayPrice > 0 ? `${Math.ceil(itm.count / assetPrices.stayPrice).toLocaleString("en-US")}` : 0}`
                      : itm?.count}
                  </span>
                  {itm.desc && <span className="text-[10px]">{itm.desc}</span>}
                </p>
                <p className="text-main opacity-70 text-sm font-semibold">{itm.text}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="flex justify-between items-center mt-4 mb-2">
          <span className="text-main text-sm opacity-70 font-bold">Lock STAYS</span>
          <p className="text-main text-sm font-bold">
            <span className="text-main opacity-70">Your Balance:</span>
            {Number(porfolioDetails.stayTokens).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </p>
        </div> */}
        {/* <div className="space-y-4">
          <div className="w-full bg-[#F1F4F9] bg-bg_theme2 p-2 rounded-md flex gap-2 items-center">
            <input
              type="number"
              pattern="\d*"
              value={stayQuantity}
              placeholder="Amount to be Unstake"
              min={0.0}
              className="w-full min-h-full bg-transparent"
              onChange={e => setStayQuantity(e.target.value)}
              disabled={printerDetails.isLock || Number(printerDetails.stakedSTAY) === 0}
            />
            <Button
              className="border-primary text-main"
              style={{ background: "transparent", paddingTop: "6px", paddingBottom: "6px" }}
              onClick={() => setStayQuantity(printerDetails.stakedSTAY)}
              disabled={printerDetails.isLock || Number(printerDetails.stakedSTAY) === 0}
            >
              Max
            </Button>
          </div>
        </div> */}
        <div className="max-sm:space-y-4 sm:space-x-4 w-full flex max-sm:flex-col items-center">
          <div className="w-full">
            <p className="text-main text-xs font-bold mb-1">
              <span className="text-main opacity-70">Your Balance: </span>
              {approx(porfolioDetails.stayTokens, {
                capital: true,
                min10k: true,
                precision: 3,
              })}{" "}
              STAYS
            </p>
            <div className="mb-4 w-full bg-[#F1F4F9] bg-bg_theme2 p-2 rounded-md flex gap-2 items-center">
              <input
                type="number"
                pattern="\d*"
                min={0.0}
                className="w-full min-h-full bg-transparent"
                disabled
                placeholder={`${
                  boosterAmounts.length > 0
                    ? printerDetails.stakedSTAY >= boosterAmounts[active]
                      ? 0
                      : Number(boosterAmounts[active] - printerDetails.stakedSTAY).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          },
                        )
                    : "Amount of"
                }  STAY`}
              />
            </div>

            <Button
              className="w-full text-nowrap text-[14px] flex items-center justify-center gap-2"
              onClick={handleBoost}
              disabled={
                loading ||
                printerDetails.isBoosted ||
                loading2 ||
                loading3 ||
                loading4 ||
                printerDetails.currentLevel - 1 !== active ||
                source === "oldprinter"
              }
            >
              {loading ? (
                <BLoader />
              ) : (
                <>
                  {printerDetails.currentLevel - 1 === active && printerDetails.isBoosted
                    ? "Boosted"
                    : "Boost APR"}
                  <IoRocketOutline
                    size={22}
                    stroke="#ffffff"
                  />
                </>
              )}
            </Button>
          </div>
          <div className="w-full md:max-w-[97px]">
            <p className="text-main text-xs font-bold mb-1">
              <span className="text-main opacity-70">Locked: </span>

              {approx(printerDetails.stakedSTAY, {
                capital: true,
                min10k: true,
                precision: 3,
              })}
            </p>
            <div className="mb-4 bg-[#F1F4F9] bg-bg_theme2 p-2 rounded-md flex gap-2 items-center">
              <input
                type="number"
                pattern="\d*"
                min={0.0}
                className="w-full min-h-full bg-transparent"
                placeholder="Amount of STAY"
                value={stayQuantity}
                onChange={e => {
                  setStayQuantity(e.target.value);
                }}
              />
            </div>
            <Button
              className="text-[14px] max-md:w-full text-nowrap bg-transparent text-main border-[var(--main)] !py-[11px]"
              disabled={
                loading ||
                loading2 ||
                loading3 ||
                loading4 ||
                stayQuantity === "0" ||
                stayQuantity === "" ||
                printerDetails.isLock ||
                (source === "oldprinter" && oldPrinterDetails.isLock)
              }
              onClick={handleStayUnstake}
            >
              {loading4 ? <BLoader isWhite={false} /> : "Unlock"}
            </Button>
          </div>
        </div>
      </div>
      {showModal && (
        <CustomModal
          setLoader2={setLoader2}
          setShowModal={setShowModal}
          source={"upgrade"}
          setBadgeId={setBadgeId}
          setAsset={setAsset}
          assets={assets}
          title={badges[active].text}
          isTitleCenter={true}
          onHide={() => setShowModal(false)}
        >
          <div className="flex items-center flex-col w-full">
            <img
              className="size-[260px] object-cover rounded-xl mb-6"
              src={badgeGif[active]}
              loading="lazy"
              alt="step"
              onContextMenu={e => e.preventDefault()}
            />
            <p className="mb-2 self-start text-lg font-semibold">Choose currency</p>
            <div className="self-start flex items-center gap-4 w-full">
              <div className="relative w-full">
                <Select
                  className="h-[50px] react-select-modal w-full"
                  classNamePrefix="react-select-modal"
                  value={asset}
                  options={assets}
                  isSearchable={false}
                  onChange={setAsset}
                  styles={{
                    singleValue: base => ({
                      ...base,
                      display: "flex",
                      alignItems: "center",
                    }),
                  }}
                  components={{
                    Option,
                    SingleValue,
                  }}
                />
                <IoChevronDown
                  className="absolute right-2 top-4"
                  stroke={"var(--main)"}
                />
              </div>
              <div className="max-w-[150px] h-[50px] text-xs w-full bg-[#F1F4F9] bg-bg_theme2 p-2  rounded-md flex gap-2 items-center">
                <input
                  type="text"
                  value={badgePrice > 0 ? badgePrice : 0}
                  className="w-full min-h-full bg-transparent text-base"
                  disabled
                />
                ≈${badgePrices[active]}
              </div>
            </div>
          </div>
          {/* <p className="text-main my-4 text-[14px] opacity-60 font-medium">
            {printerDetails.currentLevel === 10
              ? "Coming Soon....."
              : getEstimatedLevel(printerDetails.stakedNFTs) === printerDetails.currentLevel
                ? `You need ${limitForEachLevel[printerDetails.currentLevel].requried - printerDetails.stakedNFTs} ROCKs to unlock this Level`
                : "Buy the badge to activate this level"}
          </p> */}

          <br />
          <Button
            className="w-full"
            onClick={buyBadge}
            disabled={
              // printerDetails.currentLevel === 10 ||
              // (printerDetails.currentLevel === 0 && printerDetails.badges[0] > 0) ||
              // (printerDetails.currentLevel === getEstimatedLevel(printerDetails.stakedNFTs) &&
              //   printerDetails.badges[getEstimatedLevel(printerDetails.stakedNFTs)] > 0) ||
              // (printerDetails.currentLevel !== getEstimatedLevel(printerDetails.stakedNFTs) &&
              //   printerDetails.badges[getEstimatedLevel(printerDetails.stakedNFTs) - 1] > 0) ||
              loader2 || source === "oldprinter"
            }
          >
            Buy Badge
          </Button>
          {/* <div className="flex justify-center mt-3">
          <Tooltip anchorSelect={`#buy-badge`}>
            Minimum Purchase is 25 {data?.value === "USD" ? "USD" : "EURO"}
          </Tooltip>
          <RiErrorWarningLine
            id={`buy-badge`}
            fill="#FFFFFF"
            size={26}
            className="cursor-pointer"
          />
        </div> */}
        </CustomModal>
      )}

      {showModal1 && <ClaimPrinter onHide={() => setShowModal1(false)} />}
      {showModal2 && asset?.value === "BNB" && (
        <BNBBuyBadge
          setLoader2={setLoader2}
          setShowModal={setShowModal}
          source={"token"}
          badgeId={badgeId}
          setAsset={setAsset}
          assets={assets}
          onHide={() => setShowModal2(false)}
        />
      )}
      {showModal2 && asset?.value === "STAY" && (
        <STAYBuyBadge
          setLoader2={setLoader2}
          source={"token"}
          setShowModal={setShowModal}
          isApprovedSTAY={isApprovedUSDCAndStay.isApprovedStay}
          badgeId={badgeId}
          setAsset={setAsset}
          assets={assets}
          onHide={() => setShowModal2(false)}
        />
      )}
      {showModal2 && asset?.value === "USDC" && (
        <USDCBuyBadge
          setLoader2={setLoader2}
          source={"token"}
          setShowModal={setShowModal}
          isApprovedUSDC={isApprovedUSDCAndStay.isApprovedUSDC}
          badgeId={badgeId}
          setAsset={setAsset}
          assets={assets}
          onHide={() => setShowModal2(false)}
        />
      )}
    </div>
  );
};

export default RentSaver;
