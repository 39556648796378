import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../layouts/Modal";
import Button from "../../common/Button";
import image1 from "../../assets/images/modal1_1.png";
import image2 from "../../assets/images/modal1_2.png";
import { useNfstay } from "../../context/ThemeContext";
import { BNB } from "../../utils/constants";
import BLoader from "../../common/BLoader";
import toast from "react-hot-toast";

const BNBBuyBadgeModal = ({
  source,
  setShowModal,
  setLoader2,
  badgeId,
  setAsset,
  assets,
  onHide,
}) => {
  const { buyBadge } = useNfstay();
  const [isExploding, setIsExploding] = React.useState(false);
  const [status, setStatus] = useState({ isBuy: false });
  const [showLast, setShowLast] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (status.isBuy) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  const handleBuy = async () => {
    try {
      setLoading(true);
      await buyBadge(badgeId, BNB);
      setStatus({ isBuy: true });
      toast.success("Transaction Successful");
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <CustomModal
      source={source}
      setShowModal={setShowModal}
      setLoader2={setLoader2}
      title="Buy Badge"
      setAsset={setAsset}
      assets={assets}
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[103px] md:h-[114px] top-[3px]"
            src={!status.isBuy ? image1 : image2}
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[103px] md:h-[114px] top-[3px]"
            src={image2}
            alt="step"
          />
        )}

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Buy Badge</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color: !status.isBuy ? "#FFFFFF" : "var(--main)",
              background: !status.isBuy
                ? "var(--primary)"
                : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            onClick={() => handleBuy()}
            disabled={status.isBuy || isLoading}
          >
            {isLoading ? <BLoader /> : <>Buy Badge</>}
          </Button>
        </div>
        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(BNBBuyBadgeModal);
