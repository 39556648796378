import React, { memo, useState } from "react";
import Button from "../common/Button";
import Select, { components } from "react-select";
import swap from "../assets/icons/swap.png";
import swapIcon2 from "../assets/icons/swap-arrow.png";
import { useLocation } from "react-router-dom";
import { useNfstay } from "../context/ThemeContext";
import toast from "react-hot-toast";
import ExchangeWidget from "../widget/ExchangeWidget";
import particle from "../assets/images/particle.png";
import { RiErrorWarningLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { IoChevronDown } from "react-icons/io5";
import { supportedChain } from "../utils/constants";
import { useAccount } from "wagmi";

const Option = props => (
  <components.Option {...props}>
    <img
      src={props.data.icon}
      alt="logo"
      className="swap-icon"
      loading="lazy"
    />
    {props.data.label}
  </components.Option>
);

const SwapD = ({
  isWhite = false,
  isAri = false,
  stayPrice,
  source,
  title,
  from,
  to,
  btn,
  data1,
  data2,
  handleChange1,
  handleChange2,
  value,
  setValue,
  options1,
  options2,
  onClick,
  image,
  estimation,
  estimation2,
}) => {
  const { checkUsdcTokenApprovalToDiscounted } = useNfstay();
  const [loader, setLoader] = useState(false);

  const { chainId, isConnected } = useAccount();

  const handleClick = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }
    setLoader(true);

    await checkUsdcTokenApprovalToDiscounted();

    onClick();
    setLoader(false);
  };

  const SingleValue1 = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {data1?.icon && (
        <img
          src={data1?.icon}
          alt="Icon"
          loading="lazy"
          className="swap-selected-icon"
        />
      )}
      {children}
    </components.SingleValue>
  );

  const SingleValue2 = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {data2?.icon && (
        <img
          src={data2?.icon}
          alt="Icon"
          loading="lazy"
          className="swap-selected-icon"
        />
      )}
      {children}
    </components.SingleValue>
  );

  return (
    <div className="relative z-10 flex h-full justify-between flex-col">
      <div className="flex gap-2 items-center justify-between">
        <h2 className={`${isWhite ? "text-white" : "text-main"} text-2xl font-bold`}>{title}</h2>
        {image && (
          <img
            src={image}
            alt={title}
          />
        )}
      </div>
      {isAri && (
        <div className="flex items-center gap-1 mt-2">
          <img
            src={particle}
            alt="particle"
            className="w-[200px]"
          />
        </div>
      )}
      <div
        className={`flex max-sm:flex-wrap items-center justify-center sm:justify-between gap-y-3 gap-x-[11px] my-6 ${!title ? "mt-0" : ""}`}
      >
        <Card
          isAri={isAri}
          isWhite={isWhite}
          bg={isWhite ? "bg-[#ffffff2e]" : "bg-[#20e19f33]"}
          _class="primary"
          title={from}
          price="0"
          data={data1}
          options={options1}
          handleChange={handleChange1}
          SingleValue={SingleValue1}
          value={value}
          setValue={setValue}
          isDouble={false}
        />
        <img
          src={isWhite ? swapIcon2 : swap}
          alt="Swap"
          width={40}
          height={40}
          loading="lazy"
          className="cursor-pointer max-sm:rotate-[90deg]"
        />
        <Card
          isWhite={isWhite}
          stayPrice={stayPrice}
          source={source}
          bg={isWhite ? "bg-[#ffffff2e]" : "bg-[#9a47ff1a]"}
          _class="secondary"
          title={to}
          price="0"
          data={data2}
          options={options2}
          handleChange={handleChange2}
          SingleValue={SingleValue2}
          value={value}
          estimation={estimation}
          estimation2={estimation2}
          isDouble={true}
          fiatCode={data1.value}
        />
      </div>
      {source === "USDCDEBIT" || source === "STAYDEBIT" ? (
        <ExchangeWidget
          // crypto={source === "STAYDEBIT" ? "STAY" : "USDC"}
          crypto="USDC"
          value={value}
          fiatCurrency={data1.value}
        />
      ) : (
        <Button
          onClick={handleClick}
          className="w-full mt-2"
          disabled={(value > 0 ? false : true) || loader}
        >
          {btn}
        </Button>
      )}
    </div>
  );
};

const Card = ({
  isWhite = false,
  isAri = false,
  stayPrice,
  source,
  data,
  bg,
  value,
  setValue,
  _class,
  handleChange,
  title,
  price,
  options,
  SingleValue,
  isDouble,
  estimation,
  estimation2,
}) => {
  const location = useLocation();
  const id = Math.floor(100000 + Math.random() * 900000);
  return (
    <div
      className={`${bg} rounded-xl p-4 flex flex-col justify-between w-[100%] ${location.pathname === "/migrate" ? "sm:w-[200px]" : "sm:w-[184px]"} h-[165px]`}
    >
      <div className="flex items-center justify-between">
        <span className={`font-bold ${isWhite ? "text-white" : "text-main"}`}>{title}</span>
        {isAri && value < 25 && (
          <>
            <Tooltip anchorSelect={`#ari_${id}`}>
              Minimum Purchase is 25 {data?.value === "USD" ? "USD" : "EURO"}
            </Tooltip>
            <RiErrorWarningLine
              id={`ari_${id}`}
              fill="#FFFFFF"
              size={22}
              className="cursor-pointer"
            />
          </>
        )}
      </div>
      <input
        value={
          source && source === "buyStay"
            ? (value * stayPrice)?.toFixed(2)
            : source === "swapper"
              ? value * options[0].price
              : source === "ROCK"
                ? value * 500
                : source === "USDCDEBIT"
                  ? estimation2
                  : source === "STAYDEBIT"
                    ? estimation
                    : value
        }
        onChange={e => setValue(e.target.value)}
        type="number"
        pattern="\d*"
        min={0.0}
        placeholder="0"
        className={`w-full text-3xl ${isWhite ? "text-white" : "text-main"} opacity-50 font-bold bg-transparent`}
        disabled={isDouble}
      />
      <div className="relative">
        <Select
          className={`select-${_class} ${isWhite && "isWhite-select"}`}
          classNamePrefix="react-select"
          value={data}
          options={options}
          onChange={handleChange}
          isSearchable={false}
          isDisabled={options?.length === 1 || false}
          styles={{
            singleValue: base => ({
              ...base,
              display: "flex",
              alignItems: "center",
            }),
          }}
          components={{
            Option,
            SingleValue,
          }}
        />
        {options?.length > 1 && (
          <IoChevronDown
            className="absolute right-2 top-3"
            stroke={isWhite ? "#ffffff" : "var(--main)"}
          />
        )}
      </div>
    </div>
  );
};

export default memo(SwapD);
