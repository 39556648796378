import React from "react";

function Loader() {
  return (
    <div
      className={`preloader grid place-items-center min-h-screen h-screen fixed left-0 top-0 z-[99999] w-screen ${window.location.pathname === "/" ? "bg-[#2f2f30]" : "bg-bg_theme"}`}
    >
      <div className="m-auto size-[40px] relative max-sm:-translate-y-5">
        <img
          src="/logo512.png"
          alt="Logo"
          className="spinner w-full"
        />
      </div>
    </div>
  );
}

export default Loader;
