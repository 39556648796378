import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, rainbowWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, createConfig, http } from "wagmi";
import { bsc } from "wagmi/chains";
import {
  particleGoogleWallet,
  particleTwitterWallet,
  particleWallet,
} from "../particleWallet/index";
import { RPC } from "../utils/constants";

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [
        particleGoogleWallet,
        particleTwitterWallet,
        particleWallet,
        metaMaskWallet,
        rainbowWallet,
        walletConnectWallet,
      ],
    },
  ],
  {
    appName: "NFsTay",
    projectId: process.env.REACT_APP_PROJECT_ID,
  },
);

const config = createConfig({
  connectors,
  chains: [bsc],
  transports: {
    [bsc.id]: http(RPC),
  },
  ssr: false,
});

const queryClient = new QueryClient();

export function Providers({ children }) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
