import "react-tooltip/dist/react-tooltip.css";
import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";
import { IoMdSunny } from "react-icons/io";
import { IoMoon } from "react-icons/io5";
import { FaUnlockAlt, FaLock, FaUserCircle } from "react-icons/fa";
import logo from "../assets/images/logo2.svg";
import { MdOutlineSegment } from "react-icons/md";
import { useNfstay, useTheme } from "../context/ThemeContext";
import Sidebar from "./Sidebar";
import SocialLoginsModal from "../components/modal/sociaLogins";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { Tooltip } from "react-tooltip";
import { particleWagmiWallet } from "../particleWallet/particleWagmiWallet";
import { BNBChain } from "@particle-network/chains";
import walletIcon from "../assets/icons/wallet.svg";
import {
  AuthType,
  AuthCoreEvent,
  getLatestAuthType,
  isSocialAuthType,
  particleAuth,
} from "@particle-network/auth-core";
import {
  useAuthCore,
  useConnect as useParticleConnectAuth,
} from "@particle-network/auth-core-modal";
import { useParticleConnect, useConnectKit } from "@particle-network/connectkit";
import { supportedChain } from "../utils/constants";
import toast from "react-hot-toast";

const links = [
  { to: "/", title: "Dashboard" },
  { to: "/farm", title: "Farm" },
  { to: "/printer", title: "Printer" },
  { to: "/old-printer", title: "Old Printer" },
  { to: "/marketplace", title: "Marketplace" },
  { to: "/portfolio", title: "Portfolio" },
];

const Header = () => {
  const location = useLocation();
  const [socialLoginModal, setSocialLoginModal] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { theme, toggleTheme } = useTheme();

  const activeClass = path => {
    if (location.pathname === path) return "underline font-bold";
    return "font-medium";
  };

  useEffect(() => {
    setToggleSidebar(false);
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);

  return (
    <>
      <Sidebar
        links={links}
        activeClass={activeClass}
        toggle={toggleSidebar}
        setToggle={setToggleSidebar}
        setSocialLoginModal={setSocialLoginModal}
      />
      <header className="absolute w-full top-6 left-0 right-0 px-6 max-lg:px-4">
        <div
          className="mx-auto max-w-[1220px] rounded-xl bg-[#FFFFFF1A] w-full border-[2px] border-[#FFFFFF1A]"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <div className="flex w-full items-center justify-between px-4 py-[13px] max-lg:px-3 max-lg:py-4">
            <div className="flex gap-[10px] items-center justify-between">
              <MdOutlineSegment
                size={24}
                fill="#FFFFFF"
                className="lg:hidden cursor-pointer"
                style={{ transform: "rotateY(180deg" }}
                onClick={() => [
                  setToggleSidebar(true),
                  (document.querySelector("body").style.overflowY = "hidden"),
                ]}
              />
              <a href="https://nfstay.com">
                <img
                  src={logo}
                  width={150}
                  height={33}
                  className="max-sm:w-[115px]"
                  alt="NFstay"
                />
              </a>
            </div>
            <div className="flex items-center gap-6 max-lg:hidden">
              {links?.map((itm, idx) => (
                <Link
                  key={`${itm.to}-${idx}`}
                  to={itm.to}
                  className={`text-white hover:underline hover:transition-all ${activeClass(itm.to)}`}
                >
                  {itm.title}
                </Link>
              ))}
            </div>
            <Right
              toggleTheme={toggleTheme}
              theme={theme}
              setSocialLoginModal={setSocialLoginModal}
              socialLoginModal={socialLoginModal}
            />
          </div>
        </div>
      </header>
    </>
  );
};

const Right = ({ toggleTheme, theme, socialLoginModal, setSocialLoginModal }) => {
  const { openAccountModal } = useAccountModal();
  const { isMobile } = useNfstay();
  const [loading, setLoading] = useState(false);
  const [isSecured, setSecured] = useState(
    sessionStorage.getItem("isSecured") === "true" ? true : false,
  );
  const { isConnected, address, chainId } = useAccount();

  // start: fix social auth login
  const { connect } = useConnect();
  const { connectionStatus } = useParticleConnectAuth();
  const { connect: particleConnectKit } = useParticleConnect();
  const connectKit = useConnectKit();
  const { disconnect } = useDisconnect();
  const { openWallet } = useAuthCore();

  useEffect(() => {
    if (isConnected) setSocialLoginModal(false);
  }, [isConnected]);

  const addBinanceSmartChainNetwork = async () => {
    try {
      if (window.ethereum === undefined) {
        throw new Error("Wallet Not Found");
      }
      if (!isConnected) {
        toast.error("Wallet is not Connected");
        return;
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }
      setLoading(true);

      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38", // 56 in hexadecimal
            rpcUrls: ["https://bsc.rpc.blxrbdn.com/"],
            chainName: "BSC Shield",
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            blockExplorerUrls: ["https://bscscan.com/"],
          },
        ],
      });
      toast.success("Network Secured");
      sessionStorage.setItem("isSecured", "true");
      setSecured(sessionStorage.getItem("isSecured") === "true" ? true : false);
    } catch (error) {
      console.log("Error adding Binance Smart Chain:", error);
      toast.error(error.message || error.reason || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const connectProviderLogin = async id => {
    try {
      if (!connectKit) {
        return;
      }
      await particleConnectKit({ preferredAuthType: AuthType[id], id: "particle" });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (connectionStatus === "connected" && isSocialAuthType(getLatestAuthType())) {
      connect({
        connector: particleWagmiWallet({
          socialType: getLatestAuthType(),
        }),
        chainId: BNBChain.id,
      });
    }
    const onDisconnect = () => {
      disconnect();
    };
    particleAuth.on(AuthCoreEvent.ParticleAuthDisconnect, onDisconnect);
    return () => {
      particleAuth.off(AuthCoreEvent.ParticleAuthDisconnect, onDisconnect);
    };
  }, [connect, connectionStatus, disconnect]);
  // end: fix social auth login

  return (
    <div className="flex items-center gap-3 sm:gap-4">
      {theme === "light" ? (
        <IoMoon
          fill="#FFFFFF"
          size={22}
          className="cursor-pointer min-w-[22px]"
          onClick={toggleTheme}
        />
      ) : (
        <IoMdSunny
          fill="#FFFFFF"
          size={22}
          className="cursor-pointer min-w-[22px]"
          onClick={toggleTheme}
        />
      )}

      {isConnected && !isSocialAuthType(getLatestAuthType()) && !isMobile && (
        <>
          <Tooltip anchorSelect="#pad-unlock">
            {isSecured ? "Secured Network" : "Unsecured Network"}
          </Tooltip>
          <button
            id="pad-unlock"
            className="relative cursor-pointer block max-sm:p-[6px] p-2 rounded-lg bg-[#FFFFFF26]"
            onClick={addBinanceSmartChainNetwork}
            disabled={loading || isSecured}
          >
            {isSecured ? (
              <>
                <FaLock
                  fill="#FFFFFF"
                  size={22}
                />
                <BadgeGreen />
              </>
            ) : (
              <>
                <FaUnlockAlt
                  fill="#FFFFFF"
                  size={22}
                />
                <BadgeRed />
              </>
            )}
          </button>
        </>
      )}
      {!isConnected ? (
        <>
          <Button
            className="w-full flex items-center gap-2 justify-center"
            onClick={() => setSocialLoginModal(true)}
          >
            {isMobile && (
              <img
                src={walletIcon}
                alt="Metamask"
              />
            )}

            <span className="text-white max-lg:hidden">Sign In</span>
          </Button>
        </>
      ) : (
        <button
          className="flex items-center border-[1.5px] border-[#ffffffb6] gap-3 max-sm:p-[6px] p-2 cursor-pointer rounded-lg bg-[#FFFFFF26]"
          onClick={openAccountModal}
        >
          <FaUserCircle
            fill="#FFFFFF"
            size={22}
          />
          <span className="max-sm:hidden text-white max-w-[88px] overflow-hidden text-ellipsis">
            {address}
          </span>
        </button>
      )}
      {isSocialAuthType(getLatestAuthType()) && (
        <Button
          className="flex items-center border-[1.5px] border-[#ffffffb6] gap-3 max-sm:p-[6px] p-2 cursor-pointer rounded-lg bg-[#FFFFFF26]"
          onClick={() => {
            openWallet({
              windowSize: "small",
              topMenuType: "close",
            });
          }}
        >
          <img
            src={walletIcon}
            alt="particleWallet"
          />
        </Button>
      )}
      {socialLoginModal && (
        <SocialLoginsModal
          onConnect={connectProviderLogin}
          onHide={() => setSocialLoginModal(false)}
        />
      )}
    </div>
  );
};

const BadgeRed = () => {
  return (
    <span className="absolute w-2 h-2 top-[6px] left-[8px] sm:left-[10px] border-[1px] border-[#615985] rounded-full bg-[#ff4d4d]"></span>
  );
};

const BadgeGreen = () => {
  return (
    <span className="absolute w-2 h-2 top-[6px] left-[8px] sm:left-[10px] border-[1px] border-[#615985] rounded-full bg-[#20e19f]"></span>
  );
};

export default memo(Header);
