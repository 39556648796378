import React, { memo, useMemo, useState, useRef } from "react";
import Switch from "react-switch";
import { useTheme } from "../../context/ThemeContext";
import { Pagination } from "@mui/material";

const StakedNFTs = ({ title, data }) => {
  const { theme } = useTheme();
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef(null);
  const itemsPerPage = 4;

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data.slice(indexOfFirstItem, indexOfLastItem);
  }, [data, currentPage]);

  const handleChangePage = (_, value) => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
    setCurrentPage(value);
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <span
          ref={scrollRef}
          className="text-xl md:text-[26px] text-main font-bold"
        >
          {title}
        </span>
        <Switch
          onChange={setIsDisabled}
          checked={isDisabled}
          height={30}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#9945ff"
        />
      </div>
      {isDisabled && (
        <>
          <div className="flex flex-wrap gap-4 z-20 relative">
            {paginatedData?.map((itm, idx) => (
              <div
                key={idx}
                className={`relative w-full md:w-[32%] lg:w-[23%] xl:w-[23.8%] ${theme !== "dark" ? "bg-[#FFFFFF]" : "bg-bg_theme2"} rounded-2xl p-3 overflow-hidden `}
              >
                {!isDisabled && (
                  <div className="absolute left-0 top-0 right-0 bottom-0 bg-[#ffffff70] z-40"></div>
                )}
                <div className="relative z-10 overflow-hidden [&>div]:hover:translate-y-0">
                  <img
                    src={itm.nft}
                    alt={itm.title}
                    className={`w-full rounded-2xl aspect-[14/13] ${theme !== "dark" ? "bg-bg_theme2" : "bg-bg_theme"}`}
                  />
                </div>
                <div className="py-3 relative z-40">
                  <h3 className="text-main font-semibold text-lg mb-3">$ROCKS #{itm.title}</h3>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                      <div className="bg-[#D9D9D9] size-9 rounded-md"></div>
                      <div>
                        <p className="overflow-hidden text-main text-ellipsis">{`${itm?.address?.slice(0, 4)}...${itm?.address?.slice(-4)}`}</p>
                        <span className="text-main text-sm opacity-70">{itm?.role}</span>
                      </div>
                    </div>
                    <div className="text-end">
                      <div className="flex items-center">
                        {/* <img
                          src={itm.coin}
                          alt="Creator Icon"
                          className="size-4"
                        />
                        <span className="text-main font-bold text-lg ml-1">{itm?.price}</span> */}
                      </div>
                      {/* <span className="ext-main text-sm opacity-70 font-medium">Price</span> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {data?.length > 4 && (
            <div className="flex justify-center mt-6 w-full">
              <Pagination
                hideNextButton
                hidePrevButton
                count={Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(StakedNFTs);
