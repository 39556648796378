import { useState } from "react";
import Button from "../common/Button";
import toast from "react-hot-toast";
import { supportedChain } from "../utils/constants";
import { useAccount } from "wagmi";
import CustomModal from "../layouts/Modal";

const ExchangeWidget = ({ crypto, value, fiatCurrency }) => {
  const { chainId, isConnected, address } = useAccount();
  const [isVisible, setIsVisible] = useState(false);
  const theme = localStorage.getItem("theme") || "light";
  const handleClick = () => {
    try {
      if (!isConnected) {
        throw new Error("Wallet is not Connected");
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }
      setIsVisible(true);
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    }
  };

  const isMobile = window.matchMedia("screen and (max-width: 768px)").matches ? true : false;

  return (
    <div>
      <Button
        className="w-full mt-2"
        onClick={handleClick}
        disabled={value < 25}
      >
        Buy With Fiat
      </Button>

      {isVisible && (
        <CustomModal
          // title={`Buy ${crypto === "STAY" ? "STAY" : "USDC"}`}
          title="Buy USDC"
          onHide={() => setIsVisible(false)}
        >
          <div style={{ width: `${isMobile ? "283px" : "373px"}`, overflow: "hidden" }}>
            <iframe
              width={isMobile ? "300" : "390"}
              height="480"
              frameborder="0"
              title="Buy Crypto"
              allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
              src={`https://ramp.particle.network/index.html?fiatCoin=${fiatCurrency}&cryptoCoin=${crypto}&fiatAmt=${value}&chainName=Binance+Smart+Chain&theme=${theme}&walletAddress=${address}`}
            ></iframe>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default ExchangeWidget;
