import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Gradient from "../common/Gradient";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Gradient />
      <div className="mt-[-340px] md:mt-[-280px] mb-24 mx-auto max-w-[1140px] 2xl:max-w-[1330px] px-6">
        {children}
      </div>
      <Footer />
    </>
  );
};

export default Layout;
