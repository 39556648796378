import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Card from "../common/Card";
import NFTs from "../components/Marketplace/NFTs";
// import ownedNft from "../assets/images/ownedNft.png";
import USDC from "../assets/icons/usdc.png";
import BNB from "../assets/icons/bnb.png";
import STAY from "../assets/icons/stay.png";
import approx from "approximate-number";
import Switch from "react-switch";
import Stats from "../components/Stats";
import { AiOutlineDollar } from "react-icons/ai";
import { IoDiamondOutline } from "react-icons/io5";
import TestDrive from "../components/Marketplace/TestDrive";
import Counter from "../common/Counter";
import { useNfstay } from "../context/ThemeContext";
import { stayAddress, usdcAddress, wbnb } from "../utils/constants";
import { IoRocketOutline } from "react-icons/io5";
import { useAccount } from "wagmi";
import ListItems from "../components/boardroom/ListItems";
import { ethers } from "ethers";

const Marketplace = () => {
  const { address: account } = useAccount();
  const {
    getRouterContract,
    marketplaceNfts,
    myNfts,
    loadListedItems,
    volume,
    floorPrice,
    getFloorPrice,
    getBoughtItems,
    marketSalesVolume,
  } = useNfstay();
  const [isToggleMarket, setIsToggleMarket] = useState(true);

  const totalSVL = marketSalesVolume?.reduce((acc, curr) => acc + curr, 0);

  const _ownedNftData =
    myNfts &&
    myNfts?.map(i => ({
      nft: i.image,
      title: `${i.id}`,
      role: "Creator",
      address: account,
    }));

  const ownedNftData =
    marketplaceNfts &&
    marketplaceNfts?.map(i => ({
      nft: i.image,
      title: `${i.id}`,
      role: "Creator",
      address: i.seller,
      price: i.price,
      currency: i.currency.toLowerCase(),
      coin:
        i.currency.toLowerCase() === usdcAddress.toLowerCase()
          ? USDC
          : i.currency.toLowerCase() === stayAddress.toLowerCase()
            ? STAY
            : BNB,
    }));

  const stats = [
    {
      icon: (
        <IoDiamondOutline
          stroke="white"
          size={22}
        />
      ),
      count: <Counter end={ownedNftData.length} />,
      text: "ROCKS Listed",
    },
    {
      icon: (
        <IoDiamondOutline
          stroke="white"
          size={22}
        />
      ),
      count: <Counter end={marketSalesVolume.length} />,
      text: "ROCKS Bought",
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          $
          <Counter
            end={Number(floorPrice)}
            thousandsSeparator=","
            decimalPlaces={2}
          />
        </>
      ),
      text: "Floor price",
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          {/* $
          <Counter
            end={Number(volume)}
            thousandsSeparator=","
            decimalPlaces={2}
          /> */}
          $
          {approx(Number(totalSVL) || 0, {
            capital: true,
            min10k: true,
            precision: 3,
          })}
        </>
      ),
      text: "Total Sales Volume",
    },
  ];

  useEffect(() => {
    const details = async () => {
      await loadListedItems();
      await getFloorPrice();
      await getBoughtItems();
    };
    details();
  }, []);
  return (
    <Layout>
      <Stats
        title="NFT Marketplace"
        subTitle="Buy & Sell ROCKS"
        stats={stats}
      />
      <div className="flex flex-wrap gap-4 w-full">
        <Card className="w-full">
          <div className="flex items-center justify-between">
            <h2 className="text-xl md:text-[26px] text-main font-bold">Test Drive Your ROCK</h2>
            <div className="flex items-center gap-3">
              <IoRocketOutline
                size={22}
                stroke="#9945ff"
              />
              <p className="md:text-lg font-medium bg_gradient_text">Boosted APR</p>
              <Switch
                onChange={() => setIsToggleMarket(!isToggleMarket)}
                checked={isToggleMarket}
                height={30}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#9945ff"
              />
            </div>
          </div>
          <TestDrive isToggleMarket={isToggleMarket} />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card className="w-full">
          <NFTs
            title="Listed NFTs"
            data={ownedNftData}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card className="w-full">
          <ListItems
            title="Owned NFTs"
            data={_ownedNftData}
          />
        </Card>
      </div>
    </Layout>
  );
};

export default Marketplace;
//
