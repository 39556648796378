import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Card from "../common/Card";
import Stats from "../components/Stats";
import Swap from "../components/Swap";
import { IoIosCalculator } from "react-icons/io";
import { IoDiamondOutline } from "react-icons/io5";
import RentSaver from "../components/printer/RentSaver";
import usdc from "../assets/icons/usdc.png";
import bnb from "../assets/icons/bnb.png";
import stay from "../assets/icons/stay.png";
import Counter from "../common/Counter";
import USDCModal from "../components/modal/dashboard/USDC";
import BNBModal from "../components/modal/dashboard/BNB";
import StaykingModal from "../components/modal/stayking/stake";
import { FaLock } from "react-icons/fa";
import ListItems from "../components/boardroom/ListItems";
import StakedNFTs from "../components/boardroom/StakedNFTs";
import MyListNFTs from "../components/boardroom/MyListNFTs";
import TestDrive from "../components/Marketplace/TestDrive";
import Switch from "react-switch";
import { IoRocketOutline } from "react-icons/io5";
import { useNfstay } from "../context/ThemeContext";
import { stayAddress, usdcAddress } from "../utils/constants";
import USDC from "../assets/icons/usdc.png";
import BNB from "../assets/icons/bnb.png";
import STAY from "../assets/icons/stay.png";
import stayImg from "../assets/images/Stay.png";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import approx from "approximate-number";
import { AiOutlineDollar } from "react-icons/ai";
import Badges2 from "../components/printer/Badges2";
import CCountdown from "../components/printer/CCountdown";

const stayOptions1 = [
  { value: "USDC", label: "USDC", icon: usdc },
  { value: "BNB", label: "BNB", icon: bnb },
];

const stayOptions2 = [{ value: "STAY", label: "STAY", icon: stay }];

const assets = [
  { value: "USDC", label: "USDC", icon: USDC },
  { value: "BNB", label: "BNB", icon: BNB },
  { value: "STAY", label: "STAY", icon: STAY },
];

const Printer = () => {
  const { address: account } = useAccount();
  const {
    stayPrice,
    Router_getStayPrice,
    myNfts,
    marketplaceNfts,
    printerDetails,
    aprs,
    stakedIds,
    assetPrices,
    getPrinterContract,
    printerRewards,
    setPrinterRewards,
    getPrinterDetails,
    badgePrices,
    getAllbadgePrices,
    loadMyNfts,
    loadListedItems,
    getAssetPrices,
    getOldPrinterDetails,
  } = useNfstay();
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [stayData1, setStayData1] = useState(stayOptions1?.[0] || {});
  const [stayData2, setStayData2] = useState(stayOptions2?.[0] || {});
  const [stayValue, setStayValue] = useState("");
  const [isToggle, setIsToggle] = useState(true);
  const [active, setActive] = useState(0);
  const [gradient, setGradient] = useState("");
  const { isConnected } = useAccount();
  useEffect(() => {
    (async () => {
      await getAssetPrices();
    })();
  }, []);

  useEffect(() => {
    if (isConnected && printerDetails?.currentLevel !== 0) {
      setActive(printerDetails?.currentLevel - 1);
      setGradient(printerDetails?.currentLevel - 1);
    }
  }, [account, isConnected, printerDetails?.currentLevel]);

  const data = [
    [
      { count: `$${badgePrices[0]}`, text: "Badge Cost" },
      { count: "1-5", text: "#ROCKS" },
      {
        count: `${(500 * aprs.NormalAPR[0]) / 100}`,
        text: "Per ROCKS",
        desc: "STAYS",
      },
      {
        count: `${(500 * aprs.boostedAPR[0]) / 100}`,
        text: "Boost",
        desc: "STAYS",
      },
      {
        count: `${aprs.NormalAPR[0]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[0]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[1]}`, text: "Badge Cost" },
      { count: "6-15", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[1]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[1]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[1]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[1]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[2]}`, text: "Badge Cost" },
      { count: "16-25", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[2]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[2]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[2]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[2]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[3]}`, text: "Badge Cost" },
      { count: "26-35", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[3]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[3]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[3]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[3]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[4]}`, text: "Badge Cost" },
      { count: "36-50", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[4]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[4]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[4]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[4]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[5]}`, text: "Badge Cost" },
      { count: "51-75", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[5]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[5]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[5]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[5]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[6]}`, text: "Badge Cost" },
      { count: "76-100", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[6]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[6]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[6]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[6]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[7]}`, text: "Badge Cost" },
      { count: "101-150", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[7]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[7]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[7]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[7]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[8]}`, text: "Badge Cost" },
      { count: "151-250", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[8]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[8]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[8]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[8]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: `$${badgePrices[9]}`, text: "Badge Cost" },
      { count: "251-251+", text: "#ROCKS" },
      { count: `${(500 * aprs.NormalAPR[9]) / 100}`, text: "Per ROCKS", desc: "STAYS" },
      { count: `${(500 * aprs.boostedAPR[9]) / 100}`, text: "Boost", desc: "STAYS" },
      {
        count: `${aprs.NormalAPR[9]}%`,
        text: "APR",
      },
      {
        count: `${aprs.boostedAPR[9]}%`,
        text: "APR Boosted",
      },
    ],
    [
      { count: "---", text: "Badge Cost" },
      { count: "---", text: "#ROCKS" },
      { count: "---", text: "Per ROCKS", desc: "" },
      { count: "---", text: "Boost", desc: "" },
      { count: "---", text: "APR" },
      { count: "---", text: "APR Boosted" },
    ],
  ];

  const badges = [
    {
      text: "Rent Saver",
    },
    {
      text: "Studio Pioneer",
    },
    {
      text: "Apartment Investor",
    },
    {
      text: "Home Strategist",
    },
    {
      text: "Development Explorer",
    },
    {
      text: "Commercial Dweller",
    },
    {
      text: "Luxury Connoisseur",
    },
    {
      text: "Business Tycoon",
    },
    {
      text: "Real Estate Mogul",
    },
    {
      text: "The World is Yours",
    },
    {
      text: "Coming Soon...",
    },
  ];
  const stats = [
    {
      icon: (
        <IoDiamondOutline
          stroke="white"
          size={22}
        />
      ),
      count: (
        <Counter
          end={printerDetails.stakedNFTs}
          thousandsSeparator=","
        />
      ),
      text: "Staked ROCKS",
    },
    {
      icon: (
        <FaLock
          fill="white"
          size={22}
        />
      ),
      count: approx(printerDetails.stakedSTAY || 0, {
        capital: true,
        min10k: true,
        precision: 3,
      }),
      text: "Locked STAYS",
    },
    {
      isGradient: true,
      icon: (
        <IoIosCalculator
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          <Counter
            end={
              printerDetails.currentLevel > 0
                ? printerDetails.isBoosted
                  ? parseInt(data[printerDetails?.currentLevel - 1][5].count)
                  : parseInt(data[printerDetails?.currentLevel - 1][4].count)
                : 0
            }
            decimalPlaces={0}
            thousandsSeparator=","
          />
          %
        </>
      ),
      text: (
        <div className="flex items-center gap-[6px]">
          Current APR
          {printerDetails.isBoosted && (
            <IoRocketOutline
              size={22}
              stroke="#ffffff"
            />
          )}
        </div>
      ),
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          $
          <Counter
            end={
              (Number(printerRewards) || 0) +
              (Number(printerDetails?.pendingRewards) || 0) * (Number(assetPrices?.stayPrice) || 0)
            }
            decimalPlaces={2}
            thousandsSeparator=","
          />
          {/* {" "}
          <span className="text-sm text-white opacity-70">= $1,250</span> */}
        </>
      ),
      text: "Unclaimed Rewards",
    },
  ];

  // TODO: Chris ask to divide the printer reward by 2

  const printerEarnings = async () => {
    try {
      const res = await getPrinterContract().earned(account);
      setPrinterRewards(Number(ethers.utils.formatEther(res._totalRewardsInDollar) / 2).toFixed(2));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const earned = async () => {
      await printerEarnings();
    };
    if (isConnected) earned();

    if (printerDetails.stakedNFTs > 0 && isConnected) {
      const interval = setInterval(earned, 1000);
      return () => clearInterval(interval);
    }
  }, [printerDetails.stakedNFTs, account]); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  const ownedNftData =
    myNfts &&
    myNfts?.map(i => ({
      nft: i.image,
      title: `${i.id}`,
      role: "Creator",
      address: account,
    }));

  const stakedData =
    stakedIds &&
    stakedIds?.map(i => ({
      nft: i.image,
      title: `${i.id}`,
      role: "Creator",
      address: account,
    }));

  const listedNftData =
    marketplaceNfts &&
    marketplaceNfts
      .filter(i => i?.seller === account)
      .map(i => ({
        nft: i?.image,
        title: `${i?.id}`,
        role: "Creator",
        address: i?.seller,
        price: ethers.utils.formatEther(`${i?.price}`),
        currency: i?.currency.toLowerCase(),
        coin:
          i.currency.toLowerCase() === usdcAddress.toLowerCase()
            ? USDC
            : i.currency.toLowerCase() === stayAddress.toLowerCase()
              ? STAY
              : BNB,
      }));

  const handleStayChange1 = value => {
    setStayData1(value);
  };

  const handleStayChange2 = value => {
    setStayData2(value);
  };
  useEffect(() => {
    const price = async () => {
      // setIsLoading(true);
      if (stayData1.value === "BNB") {
        await Router_getStayPrice(true);
        // setIsLoading(false);

        return;
      }
      await Router_getStayPrice();
      // setIsLoading(false);
    };
    price();
  }, [stayData1]);

  useEffect(() => {
    const details = async () => {
      if (isConnected) {
        await getPrinterDetails();
        await getOldPrinterDetails();
        await loadMyNfts();
      }
    };
    details();
  }, [account, isConnected]);

  useEffect(() => {
    const details = async () => {
      await getAllbadgePrices();
      await loadListedItems();
    };
    details();
  }, []);

  return (
    <Layout>
      <CCountdown />
      <Stats
        title="Printer"
        subTitle="Stake rocks earn stays"
        stats={stats}
      />
      <div>
        <Badges2
          active={active}
          setActive={setActive}
          gradient={gradient}
        />
        <Card className="w-full !rounded-[0_0_11px_11px]">
          <RentSaver
            active={active}
            data={data}
            assets={assets}
            badges={badges}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <div className="w-full lg:w-[420px]">
          <Card>
            <Swap
              source="buyStay"
              stayPrice={stayPrice && stayPrice}
              title="Buy STAY"
              from="You're Paying"
              to="To Receive"
              btn="Swap Now"
              onClick={() => setShowModal3(true)}
              value={stayValue}
              setValue={setStayValue}
              options1={stayOptions1 || []}
              options2={stayOptions2 || []}
              data1={stayData1}
              data2={stayData2}
              handleChange1={handleStayChange1}
              handleChange2={handleStayChange2}
            />
          </Card>
          <Card className="mt-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <img
                  src={stayImg}
                  alt="Stay token"
                  className="min-w-[40px] rounded-full size-[40px]"
                />
                <div>
                  <p className="text-main font-semibold text-lg md:text-xl">NFsTay.com</p>
                  <p className="text-main">
                    STAY / <span className="opacity-70">USDC</span>
                  </p>
                </div>
              </div>
              <div className="text-end">
                <p className="text-lg md:text-xl text-main font-semibold">
                  ${assetPrices?.stayPrice?.toFixed(3)}
                </p>
              </div>
            </div>
            <div className="text-main flex items-center justify-between gap-3 mt-3">
              <div className="rounded-[10px] p-2 w-full md:w-[48%] border border-primary text-center">
                <p className="text-sm opacity-70 mb-1">LIQUIDITY</p>
                <p className="text-lg font-semibold flex items-center justify-center w-full gap-2">
                  $
                  {approx(assetPrices?.lpPrice * assetPrices?.lpSupply || 0, {
                    capital: true,
                    min10k: true,
                    precision: 3,
                  })}
                  <FaLock fill="#7F8798" />
                </p>
              </div>
              <div className="rounded-[10px] p-2 w-full md:w-[48%] border border-primary text-center">
                <p className="text-sm opacity-70 mb-1">TOTAL MKT CAP</p>
                <p className="text-lg font-semibold">
                  $
                  {approx(assetPrices?.stayPrice * assetPrices?.staySupply || 0, {
                    capital: true,
                    min10k: true,
                    precision: 3,
                  })}
                </p>
              </div>
            </div>
          </Card>
        </div>
        <Card className="w-full lg:w-[calc(100%_-_436px)] min-h-full">
          <div className="flex items-center justify-between">
            <h2 className="text-xl md:text-[26px] text-main font-bold">Test Drive Your ROCK</h2>
            <div className="flex items-center gap-3">
              <IoRocketOutline
                size={22}
                stroke="#9945ff"
              />
              <p className="md:text-lg font-medium bg_gradient_text">Boosted APR</p>
              <Switch
                onChange={() => setIsToggle(!isToggle)}
                checked={isToggle}
                height={30}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#9945ff"
              />
            </div>
          </div>
          <TestDrive
            isToggle={isToggle}
            isPrinter={true}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card className="w-full">
          <ListItems
            title="Owned NFTs"
            data={ownedNftData}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card className="w-full">
          <StakedNFTs
            title="Staked NFTs"
            data={stakedData}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card className="w-full">
          <MyListNFTs
            title="My Listed NFTs"
            data={listedNftData}
          />
        </Card>
      </div>

      {stayData1?.value === "USDC" && showModal3 ? (
        <USDCModal
          stayValue={stayValue}
          onHide={() => setShowModal3(false)}
        />
      ) : stayData1?.value === "BNB" && showModal3 ? (
        <BNBModal
          stayValue={stayValue}
          onHide={() => setShowModal3(false)}
        />
      ) : (
        ""
      )}
      {showModal2 && <StaykingModal onHide={() => setShowModal2(false)} />}
    </Layout>
  );
};

export default Printer;
