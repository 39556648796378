import React, { memo, useState } from "react";
import Select, { components } from "react-select";
import CustomModal from "../../../layouts/Modal";

import usdc from "../../../assets/icons/usdc.png";
import stay from "../../../assets/icons/stay.png";
import bnb from "../../../assets/icons/bnb.png";
import Button from "../../../common/Button";
import BLoader from "../../../common/BLoader";
import { BNB, stayAddress, usdcAddress } from "../../../utils/constants";
import toast from "react-hot-toast";
import { useNfstay } from "../../../context/ThemeContext";
import { ethers } from "ethers";

const options = [
  { value: "BNB", label: "BNB", icon: bnb, address: BNB },
  { value: "USDC", label: "USDC", icon: usdc, address: usdcAddress },
  { value: "STAY", label: "STAY", icon: stay, address: stayAddress },
];

const UpdateModal = ({ id, onHide, title }) => {
  const { updateItem } = useNfstay();
  const [data, setData] = useState(options[0]);
  const [updateValue, setUpdateValue] = useState("");
  const [isLoading, setIsloading] = useState(false);

  const Option = props => (
    <components.Option {...props}>
      {props.data.icon && (
        <img
          src={props.data.icon}
          alt="logo"
          className="swap-icon"
          loading="lazy"
        />
      )}
      {props.data.label}
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {data?.icon && (
        <img
          src={data?.icon}
          alt="Icon"
          loading="lazy"
          className="swap-selected-icon"
        />
      )}
      {children}
    </components.SingleValue>
  );

  const handleChange = value => {
    if (!value?.value) return;
    setData(value);
  };

  const handleValueChange = e => {
    setUpdateValue(e.target.value);
  };

  const handleUpdate = async (Id, currency, price) => {
    try {
      
      setIsloading(true);
      await updateItem(Id, currency.address, ethers.utils.parseEther(`${price}`));
      onHide();
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setIsloading(false);
    }
  };

  return (
    <CustomModal
      title={title}
      onHide={onHide}
    >
      <form
        onSubmit={e => e.preventDefault()}
        className="pl-4"
      >
        <div className="mb-4">
          <label className="block text-main font-semibold mb-2">Update Price</label>
          <input
            disabled={isLoading}
            onChange={e => handleValueChange(e)}
            value={updateValue}
            type="number"
            pattern="\d*"
            min={0}
            className="w-full bg-transparent border-[1px] border-main rounded-xl p-[6px]"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-main font-semibold mb-2">Update Currency</label>
          <Select
            isDisabled={isLoading}
            classNamePrefix="react-select-modal"
            value={data}
            options={options}
            onChange={handleChange}
            required
            isSearchable={false}
            styles={{
              singleValue: base => ({
                ...base,
                display: "flex",
                alignItems: "center",
              }),
            }}
            components={{
              Option,
              SingleValue,
            }}
          />
        </div>
        <Button
          className="w-full"
          type="submit"
          disabled={!(updateValue > 0) || isLoading}
          onClick={() => handleUpdate(id, data, updateValue)}
        >
          {isLoading ? <BLoader /> : "Update"}
        </Button>
      </form>
    </CustomModal>
  );
};

export default memo(UpdateModal);
