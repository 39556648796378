import React, { memo, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from "react-icons/bs";
import { useNfstay, useTheme } from "../context/ThemeContext";

const filters = ["24H", "7D", "30D", "1Y", "ALL"];

const AreaGraph = () => {
  const { theme } = useTheme();
  const [active, setActive] = useState("24H");
  const { assetPrices, getStayChart, chartData, getPercentChange, percentChange } = useNfstay();
  const data = chartData;

  const options = useMemo(
    () => ({
      theme: {
        mode: theme,
      },
      chart: {
        zoom: {
          enabled: false,
        },
      },
      series: [
        {
          name: "Price",
          color: percentChange.lineColor,
          data,
        },
      ],
      xaxis: {
        categories: data?.map(item => item.x),
        type: "datetime",
      },
      yaxis: {
        labels: {
          formatter: value => `$${value}`,
        },
        tickAmount: 4,
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: [percentChange.lineColor],
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
          dashed: {
            opacity: 1,
            lineDashArray: [2, 2],
          },
        },
      },
      tooltip: {
        followCursor: true,
      },
      fill: {
        opacity: 1,
      },
      responsive: [
        {
          breakpoint: 575,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                show: false,
              },
            },
          },
        },
      ],
    }),
    [active, theme, chartData, percentChange.lineColor],
  );

  useEffect(() => {
    (async () => {
      await getStayChart(active);
    })();
  }, [active]);

  useEffect(() => {
    (async () => {
      await getPercentChange();
    })();
  }, []);
  return (
    <div>
      <div className="flex justify-between flex-wrap-reverse mb-2">
        <div className="flex items-center space-x-4 max-md:w-full">
          <span className="text-base font-bold uppercase">STAY Value</span>
          <div className="flex items-center gap-1">
            <div className="bg-green rounded-full">
              <span className="text-white">
                {percentChange.price_change_24h < 0 ? (
                  <BsArrowDownCircleFill fill={percentChange.lineColor} />
                ) : (
                  <BsArrowUpCircleFill fill={percentChange.lineColor} />
                )}
              </span>
            </div>
            <span className={`text-[15px] font-[600] mr-1`}>
              <span className="text-[15px] font-[600] text-[#A0A3AA] font-bold ml-1">$ </span>
              {String(percentChange.price_change_24h.toFixed(4)).replace(/[+|-]/g, '')}
            </span>
            <span className="text-[15px] font-bold uppercase opacity-30">•</span>
            <span
              className={`text-[15px] font-[600] ${percentChange.price_change_24h < 0 ? " text-[#ff0000]" : "text-[#20E19F]"} ml-1`}
            >
              {" "}
              {String(percentChange.price_change_percentage_24h).replace(/[+|-]/g, '')}%
            </span>
          </div>
        </div>
        <div className="max-md:w-full max-md:mb-4 max-md:grid max-md:place-items-center">
          <div
            className={`mx-auto flex items-center rounded-lg overflow-hidden p-1 ${theme === "light" ? "bg-[#F1F4F9]" : "bg-[#F1F4F91F]"}`}
          >
            {filters?.map((itm, idx) => (
              <span
                key={idx}
                onClick={() => setActive(itm)}
                className={`transition-all cursor-pointer px-3 py-1 font-[500] ${active === itm ? "bg-primary text-white" : "opacity-60 text-main"} rounded-md text-[12px]`}
              >
                {itm}
              </span>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className="mb-2">
          <span className="text-2xl md:text-[34px] text-[#A0A3AA] font-bold ml-1">$</span>
          <span className="text-2xl md:text-[34px] font-bold">
            {assetPrices.stayPrice?.toFixed(3)}
          </span>
        </div>
        <ReactApexChart
          options={options}
          series={options.series}
          type="area"
          height={250}
        />
      </div>
    </div>
  );
};

export default memo(AreaGraph);
