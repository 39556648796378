import React, { useMemo, useState } from "react";
import gradientEllipse from "../../assets/images/home/cyberscope/gradientEllipse.png";
import Button from "../../common/Button";
import { Pagination, Stack } from "@mui/material";
import approx from "approximate-number";

const Incinerator = burnHistory => {
  const data = burnHistory.burnHistory;
  let totalValue = burnHistory?.burnHistory?.reduce((acc, obj) => acc + parseFloat(obj.value), 0);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data?.slice(indexOfFirstItem, indexOfLastItem);
  }, [data, currentPage]);

  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };

  const handleClick = () => {
    window.open('https://bscscan.com/advanced-filter?fadd=0x7F14ce2A5df31Ad0D2BF658d3840b1F7559d3EE0&tadd=0x7F14ce2A5df31Ad0D2BF658d3840b1F7559d3EE0&mtd=0x42966c68%7eBurn', '_blank');
  };

  return (
    <div
      className="mt-4 p-8 md:p-20 relative overflow-hidden rounded-3xl w-full"
      style={{ background: `linear-gradient(180deg, #01060F 0%, #1F242D 100%)` }}
    >
      <span className="text-4xl md:text-6xl right-[18%] absolute -top-1">🔥</span>
      <span className="text-3xl md:text-6xl right-2 absolute top-[7%] -rotate-[20deg]">🔥</span>
      <span className="max-md:hidden text-3xl md:text-5xl right-3 absolute top-[34%] rotate-[20deg]">
        🔥
      </span>
      <span className="max-md:hidden text-3xl md:text-5xl right-[8%] absolute top-[24%] -rotate-[20deg]">
        🔥
      </span>
      <img
        src={gradientEllipse}
        alt="gradientEllipse"
        loading="lazy"
        className="max-sm:h-[200px] max-sm:min-w-[150vh] w-full absolute top-0 left-0 right-0"
      />
      <div className="relative z-50 flex justify-between items-center gap-y-5 flex-wrap">
        <div>
          <h2 className="text-white text-3xl md:text-5xl font-semibold">Incinerator</h2>
          <p className="text-white max-w-[450px] md:text-lg font-medium my-5 md:my-8">
            Burns are performed randomly to avoid price manipulation and all transactions are
            displayed below for transparency.
          </p>
          <Button
            style={{
              color: "white",
              background: `linear-gradient(90.57deg, rgba(153, 69, 255, 0.3) 0%, rgba(32, 225, 159, 0.3) 100%)`,
            }}
          onClick = {handleClick}
          >
            Check on BscScan
          </Button>
        </div>
        <div className="py-7 px-4 md:py-10 md:px-7 rounded-2xl border border-[#FFFFFF73] flex gap-3 bg-[#b4b4b442] backdrop-blur-md">
          <span className="text-5xl md:text-7xl">🔥</span>
          <div>
            <p className="text-white text-3xl md:text-4xl font-semibold">
              {approx(totalValue || 0, { capital: true, min10k: true, precision: 3 })}
            </p>
            <p className="text-white md:text-lg font-medium mt-4">Tokens Burned</p>
          </div>
        </div>
      </div>
      <div className="w-full table-scroll overflow-x-scroll relative z-50 mt-6 md:mt-8">
        <table className="w-full text-sm text-left rtl:text-right mb-5">
          <thead className={`text-xs uppercase bg-[#424344] rounded-[8px]`}>
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-white"
              >
                #
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-white"
              >
                Source
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-white"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-white"
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-white"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, idx) => (
              <tr key={idx}>
                <th
                  scope="row"
                  className="px-4 py-2 font-medium text-white"
                >
                  {idx + 1}
                </th>
                <td className="text-nowrap px-4 py-2 text-white font-medium">{`${item.source?.slice(0, 8)}....${item.source?.slice(-4)}`}</td>
                <td className="text-nowrap px-4 py-2 font-medium text-[#7F8798]">{item.date}</td>
                <td className="text-nowrap px-4 py-2 text-white font-medium">
                  {Number(item.value).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  STAY
                </td>
                <td className="text-nowrap px-4 py-2">
                  <button
                    className="flex items-center p-4 py-[2px] text-sm font-medium rounded-full"
                    style={{ background: "linear-gradient(180deg, #FF6445 0%, #FF9E43 100%)" }}
                  >
                    <span className="text-[12px] text-white">Burned</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {paginatedData?.length === 0 && <p className="text-white min-h-[220px] text-center text-lg">No data found.</p>}
        {paginatedData?.length > 0 && (
          <div className="white-pagination">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(data.length / itemsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </div>
        )}
      </div>
    </div>
  );
};

export default Incinerator;
