import React, { memo, useMemo, useState } from "react";
import { FaMobile, FaWallet } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import CustomModal from "../../../layouts/Modal";
import blackLogo from "../../../assets/icons/logo_black.png";
import whiteLogo from "../../../assets/icons/logo_white.png";
import Button from "../../../common/Button";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useTheme } from "../../../context/ThemeContext";
import classNames from "classnames";

const SocialLoginsModal = ({ onConnect, onHide }) => {
  const { theme } = useTheme();
  const [disabled, setDisabled] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isShaking, setIsShaking] = useState(false);
  const { openConnectModal } = useConnectModal();
  const btnClass = useMemo(
    () =>
      `${!isChecked ? "opacity-70 cursor-not-allowed" : ""} bg-transparent !text-main !border-main disabled:opacity-70 flex items-center gap-1.5 justify-center text-sm`,
    [isChecked],
  );

  const connectWallet = async method => {
    if (!isChecked) {
      setIsShaking(true);
      setTimeout(() => {
        setIsShaking(false);
      }, 500);
      return;
    }
    try {
      setDisabled(true);
      await onConnect(method);
    } catch (e) {
      console.error("Error connecting wallet: ", e);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <CustomModal
      title=""
      onHide={onHide}
    >
      <div className="space-y-3">
        <div className="grid place-items-center gap-2 mb-5">
          <img
            src={theme === "light" ? blackLogo : whiteLogo}
            alt="NFsTay"
            className="h-[70px]"
          />
          <p className="text-xl md:text-2xl font-semibold mt-1">NFsTay Login</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Button
            onClick={() => connectWallet("google")}
            className={btnClass}
            disabled={disabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 48 48"
              className="min-w-[20px]"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              ></path>
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              ></path>
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
            </svg>
            Google
          </Button>
          <Button
            onClick={() => connectWallet("discord")}
            className={btnClass}
            disabled={disabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 48 48"
              className="min-w-[20px]"
            >
              <path
                fill="#8c9eff"
                d="M40,12c0,0-4.585-3.588-10-4l-0.488,0.976C34.408,10.174,36.654,11.891,39,14c-4.045-2.065-8.039-4-15-4s-10.955,1.935-15,4c2.346-2.109,5.018-4.015,9.488-5.024L18,8c-5.681,0.537-10,4-10,4s-5.121,7.425-6,22c5.162,5.953,13,6,13,6l1.639-2.185C13.857,36.848,10.715,35.121,8,32c3.238,2.45,8.125,5,16,5s12.762-2.55,16-5c-2.715,3.121-5.857,4.848-8.639,5.815L33,40c0,0,7.838-0.047,13-6C45.121,19.425,40,12,40,12z M17.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C21,28.209,19.433,30,17.5,30z M30.5,30c-1.933,0-3.5-1.791-3.5-4c0-2.209,1.567-4,3.5-4s3.5,1.791,3.5,4C34,28.209,32.433,30,30.5,30z"
              ></path>
            </svg>
            Discord
          </Button>
          <Button
            onClick={() => connectWallet("twitter")}
            className={btnClass}
            disabled={disabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 50 50"
              className="min-w-[20px]"
              fill={theme === "dark" ? "white" : "black"}
            >
              <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
            </svg>
            Twitter
          </Button>
          <Button
            onClick={() => connectWallet("facebook")}
            className={btnClass}
            disabled={disabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 48 48"
              className="min-w-[20px]"
            >
              <linearGradient
                id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1"
                x1="9.993"
                x2="40.615"
                y1="9.993"
                y2="40.615"
                gradientUnits="userSpaceOnUse"
              >
                <stop
                  offset="0"
                  stop-color="#2aa4f4"
                ></stop>
                <stop
                  offset="1"
                  stop-color="#007ad9"
                ></stop>
              </linearGradient>
              <path
                fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)"
                d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"
              ></path>
              <path
                fill="#fff"
                d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"
              ></path>
            </svg>
            Facebook
          </Button>
          <Button
            onClick={() => connectWallet("phone")}
            className={btnClass}
            disabled={disabled}
          >
            <FaMobile size={20} />
            Phone
          </Button>
          <Button
            onClick={() => connectWallet("email")}
            className={btnClass}
            disabled={disabled}
          >
            <MdEmail size={20} />
            Email
          </Button>
        </div>
        <p className="text-center text-xs opacity-80">or connect with</p>
        <Button
          className={`${btnClass} !w-full`}
          onClick={openConnectModal}
          disabled={disabled || !isChecked}
        >
          <FaWallet size={20} />
          Connect with Wallet
        </Button>
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            disabled={disabled}
            checked={isChecked}
            className={classNames("h-fit disabled:cursor-not-allowed", {
              "animate-shake bg-red-500": isShaking,
            })}
            onClick={() => setIsChecked(prev => !prev)}
          />
          <p
            style={{ letterSpacing: 0.5 }}
            className={classNames("text-xs", {
              "animate-shake !text-red-500": isShaking,
              "opacity-70": disabled,
              "opacity-90": !disabled,
            })}
          >
            By signing up to NFsTay{" "}
            <a
              href="#"
              className={classNames("hover:underline text-main", {
                "!text-red-500": isShaking,
              })}
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="#"
              className={classNames("hover:underline text-main", {
                "!text-red-500": isShaking,
              })}
            >
              Privacy Policy
            </a>{" "}
            you acknowledge that you have read and understand NFsTay protocol{" "}
            <a
              href="#"
              className={classNames("hover:underline text-main", {
                "!text-red-500": isShaking,
              })}
            >
              disclaimer
            </a>
          </p>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(SocialLoginsModal);
