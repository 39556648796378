import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../../layouts/Modal";
import Button from "../../../common/Button";
import stay from "../../../assets/icons/stay.png";
import image1 from "../../../assets/images/modal1_1.png";
import image2 from "../../../assets/images/modal1_2.png";
import image3 from "../../../assets/images/modal1_3.png";
import { stayAddress, usdcAddress, wbnb } from "../../../utils/constants";
import { ethers } from "ethers";
import { useNfstay } from "../../../context/ThemeContext";
import toast from "react-hot-toast";
import BLoader from "../../../common/BLoader";
import { useAccount } from "wagmi";

const BNBModal = ({ setStayValue, stayValue, onHide }) => {
  const { address: account } = useAccount();

  const { stayPrice, getRouterContract } = useNfstay();

  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState({ isBuy: false });
  const [showLast, setShowLast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleBuy = async () => {
    try {
      setIsLoading(true);
      const path = [wbnb, usdcAddress, stayAddress];

      const swap = await getRouterContract(true).swapExactETHForTokens(
        0,
        path,
        account,
        Math.floor(Date.now() / 1000) + 5 * 10 * 10,
        { value: ethers.utils.parseEther(`${stayValue}`) },
      );

      await swap.wait();
      toast.success("Transaction Successful");

      setStatus({ isBuy: true });
      setStayValue("");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (status.isBuy) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  return (
    <CustomModal
      title="Buy STAY"
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-2 h-[114px] top-[3px]"
            src={!status.isBuy ? image1 : image2}
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-2 h-[114px] top-[3px]"
            src={image3}
            alt="step"
          />
        )}
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Buy STAY</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            onClick={() => handleBuy()}
            className="w-full flex items-center gap-2 justify-center text-main"
            disabled={status.isBuy || isLoading}
            style={{
              color: status.isBuy ? "var(--main)" : "#FFFFFF",
              background: status.isBuy
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
          >
            {isLoading ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={stay}
                  alt="STAY"
                  className="size-6"
                />
                Buy STAY
              </>
            )}
          </Button>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(BNBModal);
