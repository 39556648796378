import React, { useEffect, useMemo, useState } from "react";
import Card from "../common/Card";
import Layout from "../layouts/Layout";
import PortfolioValues from "../components/portfolio/PortfolioValues";
import PortFolioTable from "../components/portfolio/PortFolioTable";
import PortfolioAssets from "../components/portfolio/PortfolioAssets";
import { Pagination, Stack } from "@mui/material";
import AreaGraph from "../components/AreaGraph";
import Counter from "../common/Counter";
import { AiOutlineDollar } from "react-icons/ai";
import { IoIosMan } from "react-icons/io";
import Stats from "../components/Stats";
import { IoDiamondOutline } from "react-icons/io5";
import { useNfstay } from "../context/ThemeContext";
import approximateNumber from "approximate-number";
import { useAccount } from "wagmi";

const Portfolio = () => {
  const {
    referralDetails,
    farmHistory,
    printerHistory,
    getPortfolioValues,
    Referral_getDetails,
    getFarmHistory,
    getPrinterHistory,
    getPrinterDetails,
    porfolioDetails,
    printerDetails,
    userDetails,
    assetPrices,
  } = useNfstay();

  const { address: account, isConnected } = useAccount();

  let totalUsdBalanceRocks =
    (porfolioDetails.rocksTokens + printerDetails.stakedNFTs) * assetPrices.rockPrice;
  let totalUsdBalanceSTay =
    (porfolioDetails.stayTokens + printerDetails.stakedSTAY) * assetPrices.stayPrice;
  let totalUsdBalanceLp =
    (porfolioDetails.lpTokens + Number(userDetails.amount)) * assetPrices.lpPrice;
  let totalUsdBalance = totalUsdBalanceRocks + totalUsdBalanceSTay + totalUsdBalanceLp;
  let rocksPercent =
    totalUsdBalance > 0 ? ((totalUsdBalanceRocks / totalUsdBalance) * 100).toFixed(2) : 0;
  let lpPercent =
    totalUsdBalance > 0 ? ((totalUsdBalanceLp / totalUsdBalance) * 100).toFixed(2) : 0;
  let stayPercent =
    totalUsdBalance > 0 ? ((totalUsdBalanceSTay / totalUsdBalance) * 100).toFixed(2) : 0;

  let mergedData = [...farmHistory, ...printerHistory];
  if (mergedData.length > 0) {
    mergedData = mergedData.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  const data = mergedData; // Replace this with your actual data
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const stats = [
    {
      icon: (
        <IoIosMan
          fill="white"
          size={24}
        />
      ),

      count: (
        <>
          <Counter
            end={referralDetails.noOfRefrees}
            thousandsSeparator=","
          />
        </>
      ),

      text: "Total Referees",
    },
    {
      icon: (
        <IoDiamondOutline
          stroke="#FFFFFF"
          size={22}
        />
      ),
      count: (
        <>
          <Counter
            end={referralDetails.noOfrockSold}
            thousandsSeparator=","
          />
        </>
      ),

      text: "Total ROCKS Sold",
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          <Counter
            end={referralDetails.rewardEarned}
            thousandsSeparator=","
            decimalPlaces={2}
          />
        </>
      ),
      text: "Total Earnings",
    },
  ];

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return data?.slice(indexOfFirstItem, indexOfLastItem);
  }, [data, currentPage]);

  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const details = async () => {
      if (isConnected) {
        await Referral_getDetails();
        await getPrinterDetails();
        await getFarmHistory();
        await getPrinterHistory();
        await getPortfolioValues();
      }
    };
    details();
  }, [account, isConnected]);

  return (
    <Layout>
      <Stats
        title="PORTFOLIO"
        subTitle="REFERRAL DETAILS"
        stats={stats}
      />
      <div className="flex flex-wrap gap-4 w-full mb-6">
        <Card className="w-full lg:w-[calc(100%_-_336px)]">
          <AreaGraph />
        </Card>
        <Card className="w-full lg:w-[320px]">
          <p className="text-base text-center font-bold uppercase">PORTFOLIO VALUE</p>
          <p className="text-xl pl-1 font-bold text-center mt-3">
            $
            {approximateNumber(totalUsdBalance || 0, {
              capital: true,
              min10k: true,
              precision: 3,
            })}
          </p>
          <PortfolioValues
            totalUsdBalance={totalUsdBalance}
            rocksPercent={rocksPercent}
            lpPercent={lpPercent}
            stayPercent={stayPercent}
          />
        </Card>
      </div>
      <div className="flex flex-wrap gap-4 w-full">
        <Card className="w-full lg:w-[calc(100%_-_446px)]">
          <div className="flex items-center justify-between mb-4">
            <span className="text-base font-bold uppercase">Claim History</span>
          </div>
          <PortFolioTable paginatedData={paginatedData} />
          {paginatedData.length > 0 && (
            <>
              <div className="mt-2 flex items-center justify-center md:justify-between flex-wrap-reverse gap-3">
                <span className="max-md:w-full max-md:text-center text-[14px] font-[400] uppercase text-main">
                  Showing <span className="text-main font-semibold">1-5</span> of {data?.length}{" "}
                  entities
                </span>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(data.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                  />
                </Stack>
              </div>
            </>
          )}{" "}
        </Card>
        <Card className="w-full lg:w-[430px]">
          <span className="text-base font-bold uppercase">Assets overview</span>
          <PortfolioAssets
            totalUsdBalanceRocks={totalUsdBalanceRocks}
            totalUsdBalanceSTay={totalUsdBalanceSTay}
            totalUsdBalanceLp={totalUsdBalanceLp}
            totalUsdBalance={totalUsdBalance}
            rocksPercent={rocksPercent}
            lpPercent={lpPercent}
            stayPercent={stayPercent}
          />
        </Card>
      </div>
    </Layout>
  );
};

export default Portfolio;
