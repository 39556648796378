import React from "react";
import { ClipLoader } from "react-spinners";

const BLoader = ({ isWhite = true }) => {
  return (
    <ClipLoader
      color={isWhite ? "#FFFFFF" : "#9945ff"}
      loading={true}
      cssOverride={{
        display: "block",
        margin: "0 auto",
        border: "3px solid",
        borderColor: isWhite ? "#FFFFFF" : "#9945ff",
      }}
      size={25}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default BLoader;
