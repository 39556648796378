import React from "react";

const Card = ({ children, className, ...rest }) => {
  return (
    <div
      className={`bg-bg_theme rounded-[11px] p-4 md:p-6 ${className}`}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
