import React from "react";
import { Link, useHistory } from "react-router-dom";
import speakerIcon from "../assets/icons/speaker.svg";
import logo from "../assets/images/logo.svg";
import { SlLock } from "react-icons/sl";
import Button from "./Button";

const quickLinks = [
  { to: "/", title: "Dashboard" },
  // { to: "/boardroom", title: "Boardroom" },
  { to: "/farm", title: "Farm" },
  { to: "/printer", title: "Printer" },
  { to: "/marketplace", title: "Marketplace" },
  { to: "/migrate", title: "Migrate" },
  { to: "/portfolio", title: "Portfolio" },
];

const resources = [
  { to: "https://docs.nfstay.com/protocol/referral-system-and-whitelisted", title: "Referral" },
  { to: "https://docs.nfstay.com/legal/token-sales-agreement", title: "Token Sale Agreement" },
  { to: "https://docs.nfstay.com/legal/disclaimer", title: "Disclaimer" },
  { to: "https://docs.nfstay.com/", title: "Docs" },
  { to: "https://docs.nfstay.com/legal/terms-and-conditions", title: "Terms & Conditions" },
];

const accept = [
  { to: "https://twitter.com/nfstayofficial", title: "X" },
  { to: "https://t.me/NFsTay", title: "Telegram" },
  { to: "https://www.linkedin.com/company/nfstay/", title: "LinkedIn" },
  { to: "https://www.instagram.com/nfstayofficial/", title: "Instagram" },
  { to: "https://nfstay.medium.com/", title: "Medium" },
];

const Footer = () => {
  const history = useHistory();
  return (
    <footer style={{ background: "var(--bg_secondary)" }}>
      <div className="mx-auto max-w-[1330px] px-6 py-16">
        <Ready />

        <h1 className="text-white text-center text-2xl md:text-4xl font-bold">
          Want to travel around the globe for FREE?
        </h1>
        <p className="text-center text-white opacity-70 mt-4 mx-auto w-full max-w-[600px]">
          Join the NFsTay Traveller’s Club and enjoy multiple perks, including FREE STAYS, exclusive
          Airport Lounge Access, Stay Debit Ring and much more.
        </p>
        <br />
        <Button
          className="block mx-auto"
          onClick={() => history.push("/")}
        >
          Get Started Now
        </Button>
        <br />

        <Links />
        <div className="flex items-center justify-between mt-2 gap-4 max-sm:flex-col-reverse">
          <span className="text-white text-[12px]">
            Copyright{" "}
            <Link
              to="/"
              className="text-inherit"
            >
              NFsTay.com
            </Link>
            . All Rights Reserved
          </span>
          <div className="flex items-center gap-2">
            <SlLock
              fill="#FFFFFF"
              size={20}
            />
            <span className="text-white text-sm">
              Safe and Secure <br /> SSL Encrypted
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

const Ready = () => {
  return (
    <div
      className="w-fit mb-4 mx-auto flex items-center rounded-full border-[1px] border-[#FFFFFF1C] px-4 py-[5px]"
      style={{
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))",
      }}
    >
      <img
        src={speakerIcon}
        alt="Speaker icon"
      />
      <span className="text-white text-[15px] font-[500] ml-3 tracking-[-0.38px]">
        Are You Ready?
      </span>
    </div>
  );
};

const Links = () => {
  return (
    <div className="w-full flex flex-wrap max-lg:gap-y-6 pt-16">
      <div className="w-[35%] max-lg:w-[50%] max-sm:w-[100%] px-2">
        <Link to="/">
          <img
            src={logo}
            width={158}
            height={36}
            alt="NFstay"
          />
        </Link>
        <p className="text-white mt-4 pt-3 max-w-[316px]">
          Pioneers in the Rent2Earn & NFT Timeshare Ownership
        </p>
      </div>
      <div className="w-[25%] max-lg:w-[50%] max-sm:w-[100%] px-2">
        <p className="text-lg font-[600] text-white mb-8">Quick Links</p>
        {quickLinks?.map(itm => (
          <a
            key={itm.to}
            href={itm.to}
            className="mb-4 text-white opacity-70 capitalize block w-fit hover:underline hover:transition-all hover:translate-x-1"
          >
            {itm.title}
          </a>
        ))}
      </div>
      <div className="w-[25%] max-lg:w-[50%] max-sm:w-[100%] px-2">
        <p className="text-lg font-[600] text-white mb-8">Resources</p>
        {resources?.map(itm => (
          <a
            key={itm.to}
            href={itm.to}
            target="_blank"
            className="mb-4 text-white opacity-70 capitalize block w-fit hover:underline hover:transition-all hover:translate-x-1"
          >
            {itm.title}
          </a>
        ))}
      </div>
      <div className="w-[15%] max-lg:w-[50%] max-sm:w-[100%] px-2">
        <p className="text-lg font-[600] text-white mb-8">Follow us</p>
        {accept?.map((itm, idx) => (
          <a
            key={`${itm.to}_${idx}`}
            href={itm.to}
            target="_blank"
            className="mb-4 text-white opacity-70 capitalize block w-fit hover:underline hover:transition-all hover:translate-x-1"
          >
            {itm.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Footer;
