import React, { memo, useState } from "react";
import Select, { components } from "react-select";
import CustomModal from "../../../layouts/Modal";

import usdc from "../../../assets/icons/usdc.png";
import stay from "../../../assets/icons/stay.png";
import bnb from "../../../assets/icons/bnb.png";
import Button from "../../../common/Button";
import { BNB, stayAddress, supportedChain, usdcAddress } from "../../../utils/constants";
import { useNfstay } from "../../../context/ThemeContext";
import BLoader from "../../../common/BLoader";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";

const options = [
  { value: "BNB", label: "BNB", icon: bnb, address: BNB },
  { value: "USDC", label: "USDC", icon: usdc, address: usdcAddress },
  { value: "STAY", label: "STAY", icon: stay, address: stayAddress },
];

const ListModal = ({ id, onHide, title }) => {
  const { listItem, checkForApproval, approveToMarketplace } = useNfstay();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(options[0]);
  const [price, setPrice] = useState("");
  const { chainId, isConnected } = useAccount();

  const handleList = async (Id, currency, pr) => {
    try {
      if (!isConnected) {
        toast.error("Wallet is not Connected");
        return;
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }
      setIsLoading(true);
      const res = await checkForApproval(Id);

      if (!res) {
        await approveToMarketplace(Id);
      }

      await listItem(Id, currency, pr);
      toast.success("Transaction Successful");
      onHide();
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const Option = props => (
    <components.Option {...props}>
      {props.data.icon && (
        <img
          src={props.data.icon}
          alt="logo"
          className="swap-icon"
          loading="lazy"
        />
      )}
      {props.data.label}
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {data?.icon && (
        <img
          src={data?.icon}
          alt="Icon"
          loading="lazy"
          className="swap-selected-icon"
        />
      )}
      {children}
    </components.SingleValue>
  );

  const handleChange = value => {
    if (!value?.value) return;
    setData(value);
  };

  const handlePriceChange = e => {
    setPrice(e.target.value);
  };

  return (
    <CustomModal
      title={title}
      onHide={onHide}
    >
      <form
        onSubmit={e => e.preventDefault()}
        className="pl-4"
      >
        <div className="mb-4">
          <label className="block text-main font-semibold mb-2">Listing Price</label>
          <input
            disabled={isLoading}
            value={price}
            onChange={e => handlePriceChange(e)}
            type="number"
            pattern="\d*"
            min={0}
            className="w-full bg-transparent border-[1px] border-main rounded-xl p-[6px]"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-main font-semibold mb-2">Choose Currency</label>
          <Select
            isDisabled={isLoading}
            classNamePrefix="react-select-modal"
            value={data}
            options={options}
            onChange={handleChange}
            isSearchable={false}
            required
            styles={{
              singleValue: base => ({
                ...base,
                display: "flex",
                alignItems: "center",
              }),
            }}
            components={{
              Option,
              SingleValue,
            }}
          />
        </div>
        <Button
          className="w-full"
          type="submit"
          disabled={isLoading || !(price > 0)}
          onClick={() => handleList(id, data.address, price)}
        >
          {isLoading ? <BLoader /> : "List Item"}
        </Button>
      </form>
    </CustomModal>
  );
};

export default memo(ListModal);
