import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTheme, useNfstay } from "../../context/ThemeContext";
import Switch from "react-switch";
import { Pagination } from "@mui/material";
import toast from "react-hot-toast";
import { stayAddress, supportedChain, usdcAddress } from "../../utils/constants";
import BLoader from "../../common/BLoader";
import { ethers } from "ethers";
import UpdateModal from "../modal/boardroom/UpdateModal";
import { useAccount } from "wagmi";

const NFTs = ({ title, data }) => {
  const { theme } = useTheme();
  const { chainId, isConnected } = useAccount();

  const { account, checkStayTokenApproval, checkUsdcTokenApproval } = useNfstay();
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef(null);
  const itemsPerPage = window.innerWidth > 991 ? 12 : 4;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [id, setId] = useState({ id: "", currency: "", price: "" });

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    return data && data?.slice(indexOfFirstItem, indexOfLastItem);
  });

  const handleChangePage = (_, value) => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
    setCurrentPage(value);
  };

  const handleUpdate = async id => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }
    setId(id);
    setShowUpdateModal(true);
  };

  useEffect(() => {
    const details = async () => {
      if (isConnected) {
        await checkStayTokenApproval();
        await checkUsdcTokenApproval();
      }
    };
    details();
  }, [account, isConnected]);

  return (
    <div>
      <div className="flex justify-between items-center mb-5">
        <span
          ref={scrollRef}
          className="text-xl md:text-[26px] text-main font-bold"
        >
          {title}
        </span>
        <Switch
          onChange={setIsDisabled}
          checked={isDisabled}
          height={30}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#9945ff"
        />
      </div>
      {isDisabled && paginatedData.length > 0 && (
        <>
          <div className="flex flex-wrap gap-4 z-20 relative">
            {paginatedData?.map((itm, idx) => {
              return (
                <div
                  key={idx}
                  className={`relative w-full md:w-[32%] lg:w-[23%] xl:w-[23.8%] ${theme !== "dark" ? "bg-[#FFFFFF]" : "bg-bg_theme2"} rounded-2xl p-3 overflow-hidden `}
                >
                  {!isDisabled && (
                    <div className="absolute left-0 top-0 right-0 bottom-0 bg-[#ffffff70] z-40"></div>
                  )}
                  <div className="relative z-10 overflow-hidden [&>div]:hover:translate-y-[-50%]">
                    <img
                      src={itm.nft}
                      alt={itm.title}
                      className={`w-full rounded-2xl aspect-[14/13] ${theme !== "dark" ? "bg-bg_theme2" : "bg-bg_theme"}`}
                    />

                    <ApproveToBuy
                      id={itm.title}
                      currency={itm.currency}
                      price={itm.price}
                      seller={itm.address}
                      showModal={() => handleUpdate(itm.title)}
                    />
                  </div>
                  <div className="py-3 relative z-40">
                    <h3 className="text-main font-semibold text-lg mb-3">$ROCKS #{itm.title}</h3>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <div className="bg-[#D9D9D9] size-9 rounded-md"></div>
                        <div>
                          <p className="overflow-hidden text-main text-ellipsis">
                            {`${itm?.address?.slice(0, 4)}...${itm?.address?.slice(-4)}`}
                          </p>
                          <span className="text-main text-sm opacity-70">{itm?.role}</span>
                        </div>
                      </div>
                      <div className="text-end">
                        <div className="flex items-center">
                          <img
                            src={itm.coin}
                            alt="Creator Icon"
                            className="size-4"
                          />
                          <span className="text-main font-bold text-lg ml-1">
                            {Number(ethers.utils.formatEther(itm?.price)).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </span>
                        </div>
                        <span className="text-main text-sm opacity-70 font-medium">Price</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-center mt-6 w-full">
            <Pagination
              hideNextButton
              hidePrevButton
              count={Math.ceil(data.length / itemsPerPage)}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </>
      )}
      {showUpdateModal && (
        <UpdateModal
          id={id}
          onHide={() => setShowUpdateModal(false)}
          title={`$ROCKS ${id}`}
        />
      )}
    </div>
  );
};

const ApproveToBuy = props => {
  const { address: account, chainId } = useAccount();

  const { buyItem, approveToken, stayApproval, usdcApproval, cancelItem } = useNfstay();
  let { currency, id, price, seller, showModal } = props;
  const [isLoadingBuy, setLoadingBuy] = useState(false);
  const { isConnected } = useAccount();
  const [isLoadingApprove, setLoadingApprove] = useState(false);
  const [isLoadingCancel, setLoadingCancel] = useState(false);

  const handleBuy = async (Id, currency, price) => {
    try {
      if (!isConnected || localStorage.getItem(account) == null) {
        toast.error("Wallet is not Connected");
        return;
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }

      setLoadingBuy(true);
      await buyItem(Id, currency, price);
      toast.success("Transaction Successful");
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setLoadingBuy(false);
    }
  };

  const handleCancel = async Id => {
    try {
      if (!isConnected) {
        toast.error("Wallet is not Connected");
        return;
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }
      setLoadingCancel(true);
      await cancelItem(Id);
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setLoadingCancel(false);
    }
  };

  const handleApprove = async currency => {
    try {
      if (!isConnected || localStorage.getItem(account) == null) {
        toast.error("Wallet is not Connected");
        return;
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }

      setLoadingApprove(true);
      await approveToken(currency);

      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);

      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoadingApprove(false);
    }
  };
  return (
    <div className="absolute transition-transform translate-y-[400%] top-[50%] left-0 w-full h-100% z-20 p-4 grid place-items-center">
      {account === seller ? (
        <>
          <button
            disabled={isLoadingCancel || isLoadingApprove || isLoadingBuy}
            onClick={() => showModal()}
            className="rounded-full bg-primary p-[7px_20px] text-white font-semibold"
          >
            Update
          </button>
          <button
            disabled={isLoadingCancel || isLoadingApprove || isLoadingBuy}
            onClick={() => handleCancel(id)}
            className="block mt-4 rounded-full bg-primary p-[7px_20px] text-white font-semibold min-w-[95px]"
          >
            {isLoadingCancel ? <BLoader /> : "Cancel"}
          </button>
        </>
      ) : (
        <>
          {currency?.toLowerCase() === usdcAddress?.toLowerCase() && !usdcApproval && (
            <button
              disabled={isLoadingCancel || isLoadingApprove || isLoadingBuy}
              onClick={() => handleApprove(currency)}
              className="rounded-full bg-primary p-[7px_20px] text-white font-semibold min-w-[159px]"
            >
              {isLoadingApprove ? <BLoader /> : "Approve to Buy"}
            </button>
          )}

          {currency?.toLowerCase() === stayAddress?.toLowerCase() && !stayApproval && (
            <button
              disabled={isLoadingCancel || isLoadingApprove || isLoadingBuy}
              onClick={() => handleApprove(currency)}
              className="rounded-full bg-primary p-[7px_20px] text-white font-semibold min-w-[159px]"
            >
              {isLoadingApprove ? <BLoader /> : "Approve to Buy"}
            </button>
          )}

          <button
            disabled={
              isLoadingCancel ||
              isLoadingApprove ||
              isLoadingBuy ||
              (currency?.toLowerCase() === stayAddress?.toLowerCase() && !stayApproval) ||
              (currency?.toLowerCase() === usdcAddress?.toLowerCase() && !usdcApproval)
            }
            onClick={() => handleBuy(id, currency, price)}
            className="block mt-4 rounded-full bg-primary p-[7px_20px] text-white font-semibold min-w-[109px]"
          >
            {isLoadingBuy ? <BLoader /> : "Buy Now"}
          </button>
        </>
      )}
    </div>
  );
};

export default memo(NFTs);
