import React from "react";

const Gradient = () => {
  return (
    <div
      className="h-[500px]"
      style={{ background: "var(--bg_secondary)" }}
    ></div>
  );
};

export default Gradient;
