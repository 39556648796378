import React, { useEffect, useState } from "react";
import Card from "../common/Card";
import Layout from "../layouts/Layout";
import { GoGraph } from "react-icons/go";
import { AiOutlineDollar } from "react-icons/ai";
import { IoIosCalculator } from "react-icons/io";
import Stats from "../components/Stats";
import StayEarned from "../components/StayEarned";
import StartEarn from "../components/StartEarn";
import Counter from "../common/Counter";
import BNBLiquidity from "../components/modal/farm/BNBLiquidity";
import ClaimStay from "../components/modal/farm/ClaimStay";
import stay from "../assets/icons/stayCoin.png";
import usdc from "../assets/icons/usdc.png";
import bnb from "../assets/icons/bnb.png";
import lp from "../assets/icons/stayUsdc.png";
import LPLiquidity from "../components/modal/farm/LPLiquidity";
import USDCSTAYmodal from "../components/modal/farm/USDCSTAYmodal";
import toast from "react-hot-toast";
import { useNfstay } from "../context/ThemeContext";
import { useAccount } from "wagmi";
import {
  BNB,
  lpAddress,
  stayAddress,
  supportedChain,
  usdcAddress,
} from "../utils/constants";
import Swap from "../components/Swap";
import cardBg1 from "../assets/images/card_bg1.jfif";
import cardBg2 from "../assets/images/card_bg2.jfif";
import euro from "../assets/icons/euro.png";
import usd from "../assets/icons/usd.png";
import cards from "../assets/images/cards2.png";
import USDCModal from "../components/modal/dashboard/USDC";
import BNBModal from "../components/modal/dashboard/BNB";


const assets = [
  { value: "BNB", label: "BNB", icon: bnb },
  { value: "STAY", label: "STAY", icon: stay },
  { value: "USDC", label: "USDC", icon: usdc },
  { value: "LP TOKEN", label: "LP TOKEN", icon: lp },
];

const fiatOptions1 = [
  { value: "USD", label: "USD", icon: usd },
  { value: "EUR", label: "EURO", icon: euro },
];
const fiatOptions2 = [{ value: "USDC", label: "USDC", icon: usdc }];
// const fiatOptions2 = [{ value: "STAY", label: "STAY", icon: stay }];

const stayOptions1 = [
  { value: "USDC", label: "USDC", icon: usdc },
  { value: "BNB", label: "BNB", icon: bnb },
];
const stayOptions2 = [{ value: "STAY", label: "STAY", icon: stay }];


const Farm = () => {
  const {
    Router_getStayPrice,
    stayPrice,
    getbalance,
    getFarmingContract,
    Farming_getUserInfo,
    userDetails,
    assetPrices,
    checkTokenApprovalToZapper,
    farmingPendingShare,
    Farming_getPendingShare,
    farmingRewardPerSecond,
  } = useNfstay();
  const { address: account, chainId, isConnected } = useAccount();
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const [poolEnd, setFarmingEnd] = useState(0);

  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [asset, setAsset] = useState(assets[0]);
  const [inputVal, setInputVal] = useState("");
  const [maxbal, setMaxBal] = useState(0);
  const [stayData1, setStayData1] = useState(stayOptions1?.[0] || {});
  const [stayData2, setStayData2] = useState(stayOptions2?.[0] || {});
  const [stayValue, setStayValue] = useState("");
  const [fiatData1, setFiatData1] = useState(fiatOptions1?.[0] || {});
  const [fiatData2, setFiatData2] = useState(fiatOptions2?.[0] || {});
  const [stayFiatValue, setStayFiatValue] = useState("");
  const [estimation, setEstimation] = useState("");

  const stats = [
    {
      icon: (
        <GoGraph
          fill="white"
          size={22}
        />
      ),
      count: (
        <div className="flex max-md:flex-wrap md:gap-2 items-end">
          <Counter
            end={Number(userDetails.amount)}
            decimalPlaces={2}
            thousandsSeparator=","
          />
          <span className="text-xs md:text-sm opacity-70">
            ≈$
            {userDetails.amount > 0
              ? (Number(userDetails.amount) * assetPrices.lpPrice).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0}
          </span>
        </div>
      ),
      text: "Your Liquidity",
    },
    {
      icon: (
        <IoIosCalculator
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          <Counter
            end={
              farmingRewardPerSecond > 0
                ? Number(
                    ((farmingRewardPerSecond * 31536000 * assetPrices.stayPrice.toFixed(5)) /
                      (assetPrices.lpPrice * assetPrices.lpSupply)) *
                      100,
                  )
                : 0
            }
            decimalPlaces={2}
            thousandsSeparator=","
          />
          %
        </>
      ),
      text: "APR",
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          $
          <Counter
            end={
              ((Number(farmingPendingShare) || 0) + (Number(userDetails?.pendingRewards) || 0)) *
              (Number(assetPrices?.stayPrice) || 0)
            }
            thousandsSeparator=","
            decimalPlaces={2}
          />
        </>
      ),
      text: "Unclaimed Rewards",
    },
  ];
  const handleAddLiquidity = async value => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }
    setLoader(true);

    if (asset?.value !== "BNB") {
      const res = await checkTokenApprovalToZapper(asset?.value);
      setIsApproved(res);
    }

    setInputVal(value);
    setShowModal(true);
    setLoader(false);
  };

  const handleStayChange1 = value => {
    setStayData1(value);
  };

  const handleStayChange2 = value => {
    setStayData2(value);
  };

  const handleFiatChange1 = value => {
    setFiatData1(value);
  };

  const handleFiatChange2 = value => {
    setFiatData2(value);
  };

  const maxBalance = async asset => {
    let bal;
    if (asset === "BNB") {
      bal = await getbalance(BNB);
    } else if (asset === "STAY") {
      bal = await getbalance(stayAddress);
    } else if (asset === "USDC") {
      bal = await getbalance(usdcAddress);
    } else {
      bal = await getbalance(lpAddress);
    }
    setMaxBal(bal);
  };

  const Farming_getPoolEndTime = async () => {
    try {
      const endTime = await getFarmingContract().getPoolEndTime();
      setFarmingEnd(Number(endTime));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchPendingShare = async () => {
      await Farming_getPendingShare();
    };
    if (isConnected) fetchPendingShare();

    if (userDetails.amount > 0 && poolEnd > Math.floor(Date.now() / 1000) && isConnected) {
      const interval = setInterval(fetchPendingShare, 1000);
      return () => clearInterval(interval);
    }
  }, [userDetails.amount, account, poolEnd]); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  useEffect(() => {
    const price = async () => {
      if (stayData1.value === "BNB") {
        await Router_getStayPrice(true);
        return;
      }
      await Router_getStayPrice();
    };
    price();
  }, [stayData1]);

  useEffect(() => {
    (async () => {
      await Farming_getPoolEndTime();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isConnected) {
        await Farming_getUserInfo();
      }
    })();
  }, [account, isConnected]);

  useEffect(() => {
    const handleValueChange = async () => {
      if (stayFiatValue > 24) {

        const response = await fetch("https://gateway.ari10.com/currencies/USDC/calculate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Ari10-Widget-Id": process.env.REACT_APP_GATEWAY_ID,
          },
          body: JSON.stringify({
            offeredAmount: stayFiatValue,
            offeredCurrencyCode: fiatData1.value,
          }),
        });

        if (response.ok) {
          const data = await response.json();
  
          setEstimation(data.amount?.toFixed(2)); // Do something with the data
        } else {
          // Handle errors, e.g., show an error message
          console.error("Failed to fetch data: ", response.statusText);
        }
      } else {
        setEstimation("");
      }
    };
    handleValueChange();
  }, [fiatData1.value, stayFiatValue]);
  useEffect(() => {
    if (isConnected) {
      const bal = async () => {
        await maxBalance(asset.value);
        setInputVal("");
      };
      bal();
    }
  }, [account, asset.value, isConnected]);

  return (
    <Layout>
      <Stats
        title="FARM"
        subTitle="EARN STAY BY PROVIDING LIQUIDITY"
        stats={stats}
      />
      <div className="flex flex-wrap gap-4 w-full">
        <Card className="w-full lg:w-[calc(100%_-_436px)]">
          <StartEarn
            isFarm={true}
            loader={loader}
            balanceTitle="Provide Liquidity To Earn $STAY"
            btnTitle1="Add Liquidity"
            btnTitle2="Unstake LPs"
            onClick1={handleAddLiquidity}
            assets={assets}
            asset={asset}
            handleChangeAsset={setAsset}
            inputVal={inputVal}
            setInputVal={setInputVal}
            maxbal={maxbal}
            maxBalance={maxBalance}
          />
        </Card>
        <Card className="w-full lg:w-[420px]">
          <StayEarned
            farmingPendingShare={Number(farmingPendingShare) + Number(userDetails.pendingRewards)}
            showModal={() => setShowModal2(true)}
            text="All STAY tokens claimed and reinvested will automatically be converted into LP Tokens and sent to the FARM."
          />
        </Card>
      </div>

      <div className="flex flex-wrap gap-4 w-full mt-6">
        <Card
          className="w-full lg:w-[49%] relative before:rounded-[11px] before:absolute before:bg-[#00000080] before:w-full before:h-full before:top-0 before:left-0 before:z-0"
          style={{ background: `url(${cardBg2}) no-repeat`, backgroundSize: "cover" }}
        >
          <Swap
            isWhite={true}
            isAri={true}
            source="STAYDEBIT"
            // source="USDCDEBIT"
            title="Buy USDC with DEBIT CARD"
            image={cards}
            from="You’re Buying"
            to="You're Paying"
            value={stayFiatValue}
            setValue={setStayFiatValue}
            estimation={estimation}
            options1={fiatOptions1 || []}
            options2={fiatOptions2 || []}
            data1={fiatData1}
            data2={fiatData2}
            handleChange1={handleFiatChange1}
            handleChange2={handleFiatChange2}
          />
        </Card>
        <Card
          className="w-full lg:w-[49%] relative before:rounded-[11px] before:absolute before:bg-[#00000080] before:w-full before:h-full before:top-0 before:left-0 before:z-0"
          style={{ background: `url(${cardBg1}) no-repeat`, backgroundSize: "cover" }}
        >
          <Swap
            isWhite={true}
            source="buyStay"
            stayPrice={stayPrice && stayPrice}
            title="Buy STAY with Crypto"
            from="You're Paying"
            to="To Receive"
            btn="Swap Now"
            onClick={() => setShowModal3(true)}
            value={stayValue}
            setValue={setStayValue}
            options1={stayOptions1 || []}
            options2={stayOptions2 || []}
            data1={stayData1}
            data2={stayData2}
            handleChange1={handleStayChange1}
            handleChange2={handleStayChange2}
          />
        </Card>
      </div>

      {showModal && asset?.value === "BNB" && (
        <BNBLiquidity
          maxBalance={maxBalance}
          setInputVal={setInputVal}
          inputVal={inputVal}
          onHide={() => setShowModal(false)}
        />
      )}
      {showModal && ["USDC", "STAY"].includes(asset?.value) && (
        <USDCSTAYmodal
          isApprovedToken={isApproved}
          maxBalance={maxBalance}
          setInputVal={setInputVal}
          inputVal={inputVal}
          onHide={() => setShowModal(false)}
          data={asset}
        />
      )}
      {showModal && asset?.value === "LP TOKEN" && (
        <LPLiquidity
          isApprovedToken={isApproved}
          maxBalance={maxBalance}
          setInputVal={setInputVal}
          inputVal={inputVal}
          onHide={() => setShowModal(false)}
        />
      )}
      {showModal2 && (
        <ClaimStay
          maxBalance={maxBalance}
          onHide={() => setShowModal2(false)}
        />
      )}

      {stayData1?.value === "USDC" && showModal3 ? (
        <USDCModal
          stayValue={stayValue}
          onHide={() => setShowModal3(false)}
        />
      ) : stayData1?.value === "BNB" && showModal3 ? (
        <BNBModal
          stayValue={stayValue}
          onHide={() => setShowModal3(false)}
        />
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Farm;
