import Dashboard from "../pages/Dashboard";
import Farm from "../pages/Farm";
import Marketplace from "../pages/Marketplace";
import Portfolio from "../pages/Portfolio";
import SliderPage from "../pages/SliderPage";
import Printer from "../pages/Printer";
import Swap from "../pages/Swap";
import SwapperD from "../pages/SwapD";
import PrinterOld from "../pages/PrinterOld";

export const ROUTES = [
  { path: "/", component: Dashboard },
  { path: "/portfolio", component: Portfolio },
  { path: "/farm", component: Farm },
  { path: "/printer", component: Printer },
  { path: "/marketplace", component: Marketplace },
  { path: "/migrate", component: Swap },
  { path: "/sliderpage", component: SliderPage },
  { path: "/nbx", component: SwapperD },
  { path: "/old-printer", component: PrinterOld },
];
