import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../../layouts/Modal";
import Button from "../../../common/Button";
import rock from "../../../assets/icons/rocks.png";
import usdc from "../../../assets/icons/usdc.png";
import image1 from "../../../assets/images/progress1_1.png";
import image2 from "../../../assets/images/progress1_2.png";
import image3 from "../../../assets/images/progress1_3.png";
import image4 from "../../../assets/images/progress1_4.png";
import toast from "react-hot-toast";
import { useNfstay } from "../../../context/ThemeContext";
import { referralAddress } from "../../../utils/constants";
import { ethers } from "ethers";
import BLoader from "../../../common/BLoader";

const MintNowModal = ({ setMintValue2, rocks, onHide }) => {
  const [isApproved, setIsApproved] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState({ isApproved: false, isMint: false });
  const [showLast, setShowLast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { Referral_buyNFT, approveUSDCtoRocks, USDCApprovaltoRocks } = useNfstay();

  const approveUSDC = async () => {
    try {
      setLoading(true);
      await approveUSDCtoRocks();
      setStatus({ isApproved: true, isMint: false });
      setIsApproved(true);
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const buyRocks = async () => {
    try {
      setLoading2(true);
      await Referral_buyNFT(rocks);
      setStatus({ isApproved: true, isMint: true });
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
      setMintValue2("");
      toast.success("Transaction Successful");
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (USDCApprovaltoRocks) setStatus({ isApproved: true, isGet: false });
  }, []);

  return (
    <CustomModal
      title="Mint ROCKs with USDC"
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={
              !status.isApproved && !status.isMint
                ? image1
                : status.isApproved && !status.isMint
                  ? image2
                  : status.isApproved && status.isMint
                    ? image3
                    : image4
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={image4}
            alt="step"
          />
        )}
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve USDC</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            onClick={approveUSDC}
            className="w-full flex items-center gap-2 justify-center text-main"
            disabled={status.isApproved || loading}
            style={{
              color: status.isApproved ? "var(--main)" : "#FFFFFF",
              background: status.isApproved
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
          >
            {loading ? (
              <BLoader />
            ) : (
              <>
                {!status.isApproved ? (
                  <>
                    <img
                      src={usdc}
                      alt="USDC"
                      className="size-6"
                    />
                    Approve USDC
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Mint ROCKS</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color: status.isApproved && !status.isMint ? "#FFFFFF" : "var(--main)",
              background:
                status.isApproved && !status.isMint
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            // onClick={() => setStatus({ isApproved: true, isMint: true })}
            onClick={buyRocks}
            disabled={!status.isApproved || loading2}
          >
            {loading2 ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={rock}
                  alt="Rock"
                  className="size-6"
                />
                Mint ROCKs
              </>
            )}
          </Button>
        </div>
        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(MintNowModal);
