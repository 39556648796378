import { Slider } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNfstay, useTheme } from "../../context/ThemeContext";
import approx from "approximate-number";
import rockIcon from "../../assets/images/rockSvg.svg";

const TestDrive = ({ isToggleMarket, isToggle, isPrinter = false }) => {
  const { theme } = useTheme();
  const [active, setActive] = useState(36);
  const { assetPrices } = useNfstay();
  const [values, setValues] = useState({
    stay: 0,
    rocks: 0,
    noOfRocks: 0,
  });
  const [inUsd, setInUsd] = useState(0);
  const options = useMemo(() => {
    const gradientStops = [
      { stop: 0, color: "#9945ff" },
      { stop: 15, color: "#9945ff" },
      { stop: 35, color: "#8531eb" },
      { stop: 65, color: "#20E19F" },
    ];

    const generateGradientColor = value => {
      const stop = gradientStops.find(stop => value <= stop.stop);

      return stop ? stop.color : "#20E19F";
    };
    return {
      series: [parseFloat((1 * values?.noOfRocks) / 2.51).toFixed(1)],
      chart: {
        height: 400,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: [generateGradientColor(values?.noOfRocks)],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
    };
  }, [values?.noOfRocks]);

  useEffect(() => {
    setValues(prev => ({ ...prev, stay: parseFloat(assetPrices.stayPrice).toFixed(4) }));
  }, [assetPrices.stayPrice]);

  useEffect(() => {
    let value;
    let boost;

    if (isPrinter) {
      boost = isToggle;
    } else {
      boost = isToggleMarket;
    }
    if (boost) {
      value =
        values?.noOfRocks > 0 && values?.noOfRocks < 6
          ? 200
          : values?.noOfRocks < 16
            ? 205
            : values?.noOfRocks < 26
              ? 210
              : values?.noOfRocks < 36
                ? 215
                : values?.noOfRocks < 51
                  ? 220
                  : values?.noOfRocks < 76
                    ? 225
                    : values?.noOfRocks < 101
                      ? 230
                      : values?.noOfRocks < 151
                        ? 235
                        : values?.noOfRocks < 251
                          ? 240
                          : 250;
    } else {
      value =
        values?.noOfRocks > 0 && values?.noOfRocks < 6
          ? 175
          : values?.noOfRocks < 16
            ? 200
            : values?.noOfRocks < 26
              ? 205
              : values?.noOfRocks < 36
                ? 210
                : values?.noOfRocks < 51
                  ? 215
                  : values?.noOfRocks < 76
                    ? 220
                    : values?.noOfRocks < 101
                      ? 225
                      : values?.noOfRocks < 151
                        ? 230
                        : values?.noOfRocks < 251
                          ? 235
                          : 240;
    }

    // value = values?.noOfRocks * (values?.stay * value);
    value = values?.noOfRocks * value;
    setInUsd(value);
  }, [values?.noOfRocks, values?.stay, assetPrices.stayPrice, isToggle, active, isToggleMarket]);

  return (
    <div className="flex justify-between items-center flex-wrap gap-4">
      <div className="w-full md:w-[54%] relative z-20">
        <div className="flex justify-between items-center w-full mt-5 mb-3 text-main md:text-[18px] font-semibold">
          <span>Number of ROCKS</span>
          <input
            type="number"
            // pattern="\d*"
            value={values?.noOfRocks}
            onChange={e => setValues(prev => ({ ...prev, noOfRocks: e.target.value }))}
            min={0}
            max={300}
            className="bg-[#F1F4F9] bg-bg_theme2 px-2 py-1 rounded-md text-sm w-[100px] font-medium"
          />
        </div>

        <Slider
          value={values?.noOfRocks}
          onChange={e => setValues(prev => ({ ...prev, noOfRocks: e.target.value }))}
          min={0}
          max={300}
          valueLabelDisplay="auto"
          marks={[
            {
              value: 0,
              label: "0",
            },
            {
              value: 300,
              label: "300",
            },
          ]}
        />
        {!isPrinter && (
          <>
            <div className="flex w-full items-center justify-between mt-5 mb-3 text-main md:text-[18px] font-semibold">
              <span>Price of ROCKS</span>
              <input
                type="number"
                // pattern="\d*"
                value={values?.rocks}
                onChange={e => setValues(prev => ({ ...prev, rocks: e.target.value }))}
                min={0}
                max={10000}
                className="bg-[#F1F4F9] bg-bg_theme2 px-2 py-1 rounded-md text-sm w-[100px] font-medium"
              />
            </div>
            <Slider
              value={values?.rocks}
              onChange={e => setValues(prev => ({ ...prev, rocks: e.target.value }))}
              min={0}
              max={10000}
              valueLabelDisplay="auto"
              marks={[
                {
                  value: 0,
                  label: "0",
                },
                {
                  value: 10000,
                  label: "10000",
                },
              ]}
            />
          </>
        )}
        <div className="flex w-full justify-between items-center mt-5 mb-3 text-main md:text-[18px] font-semibold">
          <span>Price of STAY</span>
          <input
            type="number"
            // pattern="\d*"
            value={values?.stay}
            onChange={e => setValues(prev => ({ ...prev, stay: e.target.value }))}
            min={0.0}
            max={0.1}
            step={0.001}
            className="bg-[#F1F4F9] bg-bg_theme2 px-2 py-1 rounded-md text-sm w-[100px] font-medium"
          />
        </div>
        <Slider
          value={values?.stay}
          onChange={e => setValues(prev => ({ ...prev, stay: e.target.value }))}
          min={0}
          max={0.1}
          step={0.001}
          valueLabelDisplay="auto"
          marks={[
            {
              value: 0,
              label: "0",
            },
            {
              value: 0.1,
              label: "0.1",
            },
          ]}
        />

        <div className="mt-5 text-main md:text-[18px] mb-3 font-semibold">
          Staking Period <span className="text-sm opacity-70">(Months)</span>
        </div>
        <div
          className={`w-fit flex items-center rounded-lg overflow-hidden p-1 pl-0 ${theme === "light" ? "bg-[#F1F4F9]" : "bg-[#F1F4F91F]"}`}
        >
          {[12, 24, 36]?.map((itm, idx) => (
            <span
              key={idx}
              onClick={() => setActive(itm)}
              className={`transition-all cursor-pointer px-5 py-1 font-[500] ${active === itm ? "text-white" : "opacity-60 text-main"} rounded-md`}
              style={{
                background:
                  active === itm && "linear-gradient(105.48deg, #9945FF 3.32%, #20E19F 97.39%)",
              }}
            >
              {itm}
            </span>
          ))}
        </div>
      </div>

      <div className="w-full md:w-[42%] flex flex-col items-center relative z-10">
        <ReactApexChart
          options={options}
          series={options.series}
          type="radialBar"
          width="440px"
        />
        <div className="absolute text-center top-[15.5%]">
          <div className="flex justify-center mb-3">
            <img
              src={rockIcon}
              alt="rockIcon"
            />
          </div>
          <p className="text-primary font-bold text-2xl md:text-[28px] mb-2 w-full">
            $
            {inUsd > 0
              ? approx(
                  Number(
                    isPrinter
                      ? active === 12
                        ? inUsd
                        : active === 24
                          ? inUsd * 2
                          : inUsd * 3
                      : values?.rocks > 0
                        ? active === 12
                          ? inUsd
                          : active === 24
                            ? inUsd * 2
                            : inUsd * 3
                        : 0,
                  ),
                  {
                    capital: true,
                    min10k: true,
                    precision: 3,
                  },
                )
              : 0}
          </p>
          <p className="block text-[12px] max-w-[160px] mx-auto w-full text-center text-main opacity-65 font-medium">
            Total Rewards
          </p>
          <div className="flex items-center justify-between mt-5 gap-2 w-full">
            <div>
              <p className="text-lg text-main font-semibold w-[70px]">
                {approx(
                  Number(
                    isPrinter
                      ? values?.noOfRocks > 0
                        ? (inUsd / (500 * values?.noOfRocks)) * 100
                        : 0
                      : values?.noOfRocks > 0 && values?.rocks > 0
                        ? (inUsd / (values?.rocks * values?.noOfRocks)) * 100
                        : 0,
                  ),
                  {
                    capital: true,
                    min10k: true,
                    precision: 3,
                  },
                )}
                %
              </p>
              <span className="text-main opacity-60 text-[12px]">APR</span>
            </div>
            <div className="translate-x-[8px]">
              <p className="text-lg text-main font-semibold w-[105px]">
                $
                {inUsd > 0
                  ? approx(Number(isPrinter ? inUsd / 12 : values.rocks > 0 ? inUsd / 12 : 0), {
                      capital: true,
                      min10k: true,
                      precision: 3,
                    })
                  : 0}
              </p>
              <span className="text-main opacity-60 text-[12px]">Per Month</span>
            </div>
          </div>
        </div>
        <p className="mt-3 text-center text-sm opacity-70 font-medium max-w-[300]">
          The number of STAYS to be received is fixed. However, the dollar value and APR will vary
          and depend on the price of STAY.
        </p>
      </div>
    </div>
  );
};

export default memo(TestDrive);
