import React, { useState } from "react";
import BLoader from "../../common/BLoader";
import Button from "../../common/Button";
import Stay from "../../assets/images/Stay.png";
import { useNfstay } from "../../context/ThemeContext";
import toast from "react-hot-toast";
import { supportedChain } from "../../utils/constants";

import { useAccount } from "wagmi";

const StayEarned = ({ printerRewards, showModal, isClaimOnly = true, source }) => {
  const { chainId, isConnected } = useAccount();

  const [loading, setLoading] = useState(false);
  const { printerClaimRewards } = useNfstay();

  const handleShow = () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    showModal();
  };
  const handleClaimRewards = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    try {
      setLoading(true);
      await printerClaimRewards();
      toast.success("Transaction Successful");
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative z-20">
      <div className="flex gap-3 items-center">
        <span className="text-xl md:text-[26px] text-white font-bold">Reward Earned</span>
        <img src={Stay} />
      </div>
      <div className="space-y-4 mt-4">
        <div
          className="w-full blurry border border-[#FFFFFF47] rounded-xl relative"
          style={{ background: "#2f2f3033" }}
        >
          <input
            className="w-full p-4 pr-20 bg-transparent text-white"
            value={
              source === "oldprinter"
                ? 0
                : printerRewards.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
            }
            readOnly
          />
          {/* <span className="absolute right-3 top-4 opacity-70 whitespace-nowrap text-white">
            = $1,250
          </span> */}
        </div>
        <div
          className={`flex ${isClaimOnly ? "max-md:flex-wrap" : "flex-wrap"} gap-3 justify-between items-center rounded-[12px]`}
        >
          <Button
            className="w-full text-[14px]"
            onClick={() => {
              handleShow();
            }}
            disabled={loading || source === "oldprinter"}
          >
            Claim & Reinvest
          </Button>

          <Button
            className={`${isClaimOnly ? "max-md:w-full md:w-[200px]" : "w-full"} max-sm:text-sm py-2 md:py-3 px-6 rounded-[12px] min-w-[137px] text-[14px]`}
            style={{
              border: `1px solid #FFFFFF1F`,
              borderRadius: "10px",
              background: "transparent",
            }}
            onClick={handleClaimRewards}
            disabled={loading || source === "oldprinter"}
          >
            {loading ? <BLoader /> : "Claim Only"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StayEarned;
