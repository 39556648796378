import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../../layouts/Modal";
import Button from "../../../common/Button";
import stay from "../../../assets/icons/stay.png";
import image1 from "../../../assets/images/progress1_1.png";
import image2 from "../../../assets/images/progress1_2.png";
import image3 from "../../../assets/images/progress1_3.png";
import image4 from "../../../assets/images/progress1_4.png";

const StaykingModal = ({ onHide }) => {
  const [isApproved, setIsApproved] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState({ isApproved: false, isStake: false });
  const [showLast, setShowLast] = useState(false);

  useEffect(() => {
    if (status.isStake && status.isApproved) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  return (
    <CustomModal
      title="Stay to Earn"
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={
              !status.isApproved && !status.isStake
                ? image1
                : status.isApproved && !status.isStake
                  ? image2
                  : status.isApproved && status.isStake
                    ? image3
                    : image4
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={image4}
            alt="step"
          />
        )}
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve STAY</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            onClick={() => {
              setIsApproved(true);
              setStatus({ isApproved: true, isStake: false });
            }}
            className="w-full flex items-center gap-2 justify-center text-main"
            disabled={status.isApproved}
            style={{
              color: !status.isApproved ? "#FFFFFF" : "var(--main)",
              background: status.isApproved
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
          >
            {!status.isApproved ? (
              <>
                <img
                  src={stay}
                  alt="stay"
                  className="size-6"
                />
                Approve STAY
              </>
            ) : (
              "Approved"
            )}
          </Button>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Stake STAY</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color: status.isApproved && !status.isStake ? "#FFFFFF" : "var(--main)",
              background:
                status.isApproved && !status.isStake
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            onClick={() => setStatus({ isApproved: true, isStake: true })}
            disabled={!status.isApproved}
          >
            <img
              src={stay}
              alt="stay"
              className="size-6"
            />
            Stake STAY
          </Button>
        </div>
        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(StaykingModal);
