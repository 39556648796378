import React, { useEffect, useState } from "react";
import rocks from "../../assets/images/rocks.png";
import Token from "../../assets/images/lp.png";
import Stay from "../../assets/images/Stay.png";
import { useNfstay, useTheme } from "../../context/ThemeContext";
import metaIcon from "../../assets/icons/metamask.png";

import { Tooltip } from "react-tooltip";
import { useAccount } from "wagmi";
import { lpAddress, stayAddress, supportedChain } from "../../utils/constants";
import toast from "react-hot-toast";
import BLoader from "../../common/BLoader";

const PortfolioAssets = ({
  totalUsdBalanceRocks,
  totalUsdBalanceSTay,
  totalUsdBalanceLp,
  stayPercent,
  rocksPercent,
  lpPercent,
}) => {
  const {
    porfolioDetails,
    assetPrices,
    printerDetails,
    getPortfolioValues,
    getPrinterDetails,
    account,
    userDetails,
    Farming_getUserInfo,
  } = useNfstay();
  const { theme } = useTheme();

  const { chainId, isConnected } = useAccount();

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    const details = async () => {
      if (isConnected) {
        await getPortfolioValues();
        await getPrinterDetails();
        await Farming_getUserInfo();
      }
    };
    details();
  }, [account, isConnected]);
  const data = [
    {
      iconUrl: rocks,
      Name: "Rocks",
      balance: porfolioDetails?.rocksTokens + printerDetails?.stakedNFTs || 0,
      totalAmount: totalUsdBalanceRocks || 0,
      currentPrice: assetPrices?.rockPrice || 0,
      // amount: porfolioDetails.rockPrice * porfolioDetails.rocksTokens,
      progressPercentage: `${rocksPercent}%`,
    },
    {
      iconUrl: Token,
      Name: "LP Token",
      balance: porfolioDetails?.lpTokens + Number(userDetails?.amount) || 0,
      totalAmount: totalUsdBalanceLp || 0,
      currentPrice: assetPrices?.lpPrice || 0,
      // amount: 0,
      progressPercentage: `${lpPercent}%`,
    },
    {
      iconUrl: Stay,
      Name: "Stay",
      balance: porfolioDetails?.stayTokens + printerDetails?.stakedSTAY || 0,
      totalAmount: totalUsdBalanceSTay || 0,
      currentPrice: assetPrices?.stayPrice || 0,
      // amount: 0,
      progressPercentage: `${stayPercent}%`,
    },
  ];

  const importToken = async address => {
    try {
      if (!isConnected) {
        toast.error("Wallet is not Connected");
        return;
      }
      if (chainId !== supportedChain) {
        toast.error("Switch to BNB Chain");
        return;
      }
      if (address.toLowerCase() == stayAddress.toLowerCase()) {
        setLoading(true);
      } else {
        setLoading2(true);
      }
      const tokenAddress = address;
      const tokenSymbol = address.toLowerCase() == stayAddress.toLowerCase() ? "STAY" : "Cake-LP";
      const tokenDecimals = 18;
      const tokenImage =
        address.toLowerCase() == stayAddress.toLowerCase()
          ? "https://s2.coinmarketcap.com/static/img/coins/64x64/30862.png"
          : "https://photos.pinksale.finance/file/pinksale-logo-upload/1714340276004-368f0e0e123a02b1a33b918d276dc843.png";

      await window?.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      toast.success("Token Added Successfully");
    } catch (error) {
      toast.error("Something Went Wrong");

      console.log(error);
    } finally {
      if (address.toLowerCase() == stayAddress.toLowerCase()) {
        setLoading(false);
      } else {
        setLoading2(false);
      }
    }
  };

  return (
    <div>
      {data.map((item, index) => (
        <div
          key={index}
          className="mt-5"
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center space-x-2 flex-wrap gap-y-2">
                <img
                  src={item.iconUrl}
                  alt="Icon"
                  className="h-9 object-cover"
                />
                <span className="text-base font-bold">{item.Name}</span>
                {item.Name !== "Rocks" && (
                  <>
                    {" "}
                    <Tooltip anchorSelect="#metamask">Add to Metamask</Tooltip>
                    <button
                      id="metamask"
                      className="relative cursor-pointer block max-sm:p-[6px] p-2 rounded-lg"
                      onClick={() => importToken(item.Name == "Stay" ? stayAddress : lpAddress)}
                      disabled={loading || loading2}
                      style={loading || loading2 ? { cursor: "not-allowed" } : {}}
                    >
                      {(loading && item.Name == "Stay") || (loading2 && item.Name == "LP Token") ? (
                        <BLoader isWhite={theme === "light" ? false : true} />
                      ) : (
                        <img
                          src={metaIcon}
                          alt="Metamask"
                        />
                      )}
                    </button>
                  </>
                )}
              </div>
              <span
                className={`ml-1 mt-2 block w-fit text-[11px] bg-bg_theme2 font-medium text-main rounded-[24px] px-2 py-1`}
              >
                {item?.balance?.toLocaleString()}
              </span>
            </div>
            <span className="text-base md:text-[18px] font-semibold text-main">
              ${item?.totalAmount?.toLocaleString()}
            </span>
          </div>

          <div className="flex justify-between items-center mt-3">
            <span className="text-[12px] font-medium text-primary">
              Current Price: ${item.currentPrice?.toLocaleString()}
            </span>
            {/* <span className="text-[12px] font-medium text-main opacity-70">
              Amount: ${item.totalAmount?.toLocaleString()}
            </span> */}
          </div>

          <div className={`w-full bg-bg_theme2 rounded-full h-1.5 mt-3`}>
            <div
              className="bg-bg_progress h-1.5 rounded-full"
              style={{ width: item.progressPercentage }}
            ></div>
            <div className="text-right ">
              <span className="text-[13px] font-medium text-main">{item.progressPercentage}</span>
            </div>
          </div>
          {data?.length - 1 !== index ? (
            <hr className="mt-10" />
          ) : (
            <hr className="mt-3 opacity-0" />
          )}
        </div>
      ))}
      <br />
    </div>
  );
};

export default PortfolioAssets;
