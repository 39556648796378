import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Card from "../common/Card";
import Stats from "../components/Stats";
import Swap from "../components/Swap";
import image from "../assets/images/buy.png";
import image2 from "../assets/images/buy2.png";
import { useNfstay, useTheme } from "../context/ThemeContext";
import stay from "../assets/icons/stayCoin.png";
import myUsd from "../assets/icons/myusd.png";
import stayUsdc from "../assets/icons/stayUsdc.png";
import lp from "../assets/icons/lp.png";
import SwapModal from "../components/modal/swap/SwapModal";
import Counter from "../common/Counter";
import { AiOutlineDollar } from "react-icons/ai";
import { useAccount } from "wagmi";

const Swapper = () => {
  const { theme } = useTheme();
  const { address: account, isConnected } = useAccount();

  const {
    lpRate,
    stayRate,
    Swapper_getStayRate,
    Swapper_getLpRate,
    oldAssets,
    oldAssetBalance,
  } = useNfstay();
  const stayOptions1 = [
    { value: "MYUSD/USDC LP", label: "MYUSD/USDC LP", icon: lp },
    { value: "MYUSD", label: "MYUSD", icon: myUsd },
  ];

  const stayOptions2 = [
    { value: "STAY/USDC LP", label: "STAY/USDC LP", icon: stayUsdc, price: lpRate },
  ];

  const stayOptions3 = [{ value: "STAY", label: "STAY", icon: stay, price: stayRate }];

  const [stayData1, setStayData1] = useState(stayOptions1?.[0] || {});
  const [stayData2, setStayData2] = useState(stayOptions2?.[0] || {});
  const [stayValue, setStayValue] = useState("");
  const [showModal, setShowModal] = useState(false);

  const stats = [
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),

      count: (
        <>
          <Counter
            end={Number(oldAssets.myusdBal)}
            thousandsSeparator=","
          />
        </>
      ),

      text: "MYUSD Balance",
    },
    {
      icon: (
        <AiOutlineDollar
          fill="white"
          size={22}
        />
      ),
      count: (
        <>
          <Counter
            end={Number(oldAssets.oldLpBal)}
            thousandsSeparator=","
          />
        </>
      ),

      text: "Old LP Balance",
    },
  ];

  useEffect(() => {
    const details = async () => {
      if (isConnected) await oldAssetBalance();
    };
    details();
  }, [account, isConnected]);

  useEffect(() => {
    (async () => {
      await Swapper_getStayRate();
      await Swapper_getLpRate();
    })();
  }, []);

  const handleStayChange1 = value => {
    setStayData1(value);
    if (value?.value === "MYUSD/USDC LP") {
      setStayData2(stayOptions2[0]);
    } else {
      setStayData2(stayOptions3[0]);
    }
  };

  const handleStayChange2 = value => {
    setStayData2(value);
  };

  return (
    <Layout>
      <Stats
        title="Migrate"
        subTitle=""
        stats={stats}
      />
      <div className="flex flex-wrap gap-4 w-full">
        <Card className={`w-full overflow-hidden flex gap-4 flex-wrap`}>
          <div className="w-full md:w-[calc(100%_-_516px)] relative max-md:gap-y-7 flex justify-between max-md:flex-wrap-reverse">
            <div className="max-md:hidden absolute md:top-0 w-[300px] left-[-1.5rem] md:h-[calc(100%_+_1.5rem)]">
              <img
                src={image}
                alt=""
                loading="lazy"
                className="h-full"
              />
            </div>
            <div className="w-full md:w-[calc(100%_-_250px)] ml-auto relative md:flex md:flex-col md:justify-center">
              <h2 className="text-main text-2xl md:text-3xl font-bold">Swap</h2>
              <p className="text-sm text-main opacity-70 max-w-[350px] my-7">
                {/* <ul className="list-disc space-y-1"> */}
                <p>
                  MyUSD and MyUSD-USDC LPs will be swapped for STAY and STAY-USDC LPs respectively
                  at their equivalent US dollar value at the time.
                </p>
                {/* </ul> */}
              </p>
            </div>
          </div>
          <div
            className={`w-[500px] ${theme === "dark" && "bg-[#424344]"} rounded-xl p-5 border border-[#00000014]`}
          >
            <Swap
              source="swapper"
              title=""
              btn="Swap Now"
              from="You're Paying"
              to="To Receive"
              onClick={() => setShowModal(true)}
              value={stayValue}
              setValue={setStayValue}
              options1={stayOptions1 || []}
              options2={stayData1?.value === "MYUSD/USDC LP" ? stayOptions2 : stayOptions3}
              data1={stayData1}
              data2={stayData2}
              handleChange1={handleStayChange1}
              handleChange2={handleStayChange2}
            />
          </div>
          <div className="relative md:hidden w-full">
            <div className="md:hidden translate-y-[13px] text-center">
              <img
                src={image2}
                alt=""
                loading="lazy"
                className="h-full w-full"
              />
            </div>
          </div>
        </Card>
      </div>

      {showModal && (
        <SwapModal
          setStayValue={setStayValue}
          onHide={() => setShowModal(false)}
          selected1={stayData1}
          selected2={stayData2}
          amount={stayValue}
        />
      )}
    </Layout>
  );
};

export default Swapper;
