import React, { memo } from "react";
import Countdown from "react-countdown";
import { useNfstay } from "../../context/ThemeContext";
import { Tooltip } from "react-tooltip";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoRocketOutline } from "react-icons/io5";
import { eventTime } from "../../utils/constants";

const CCountdown = ({ source }) => {
  const { printerDetails } = useNfstay();
  return (
    <div className="flex justify-end mb-3 sm:mb-5">
      <Countdown
        date={new Date(source === "eventTime" ? eventTime * 1000 : source === "farm" ? 1715641200 * 1000 : printerDetails.lockingPeriod)}
        renderer={({ days, hours, minutes }) => {
          return (
            <div
              className="flex gap-2 items-center backdrop-blur-md rounded-[10px] border border-[#FFFFFF40] max-sm:py-4 py-5 px-3"
              style={{
                background:
                  "linear-gradient(90.69deg, rgba(153, 69, 255, 0.3) -0.25%, rgba(32, 225, 159, 0.3) 100%)",
              }}
            >
              <div>
                <Tooltip anchorSelect={`#timer`}>
                  <p className="flex items-center gap-1">
                    {source === "eventTime"
                      ? "This offer will expire once the countdown hits 0"
                      :
                      source === "farm"  
                      ? "Pool Start Time"
                      :
                      "STAY Locking Period for APR BOOST"}
                  </p>
                </Tooltip>
                <RiErrorWarningLine
                  id={`timer`}
                  fill="#FFFFFF"
                  size={22}
                  className="cursor-pointer"
                />
              </div>
              <span className="text-white font-semibold text-lg sm:text-xl ">
                {days?.toString()?.padStart(2, 0)} D : {hours?.toString()?.padStart(2, 0)} H :{" "}
                {minutes?.toString()?.padStart(2, 0)} M
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default memo(CCountdown);
