import React from "react";

const Button = ({ className = "", children, ...rest }) => {
  return (
    <button
      className={`${className} bg-primary text-[#ffffff] border-[1px] border-primary max-sm:text-sm font-[600] px-4 py-2 md:px-6 md:py-3 rounded-[10px] ${rest.disabled && "cursor-not-allowed"} `}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
