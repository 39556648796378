import React from "react";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiSolidCopy } from "react-icons/bi";
import Button from "../../common/Button";
import friends from "../../assets/images/friends.svg";
import { useAccount } from "wagmi";

const Refer = () => {
  const { address,isConnected } = useAccount();

  return (
    <div className="w-full py-3 sm:py-4 flex items-center justify-center flex-col gap-y-4">
      <div className="flex items-center gap-x-1 md:gap-x-2">
        <img
          src={friends}
          loading="lazy"
          alt="10K+ Already Joined"
          className="max-sm:w-24"
        />
        <p className="text-main font-semibold">Many Already Joined</p>
      </div>
      <h1 className="text-3xl text-center md:text-5xl font-bold text-main mt-2 mb-1">
        Invite your friends to join NFsTay
      </h1>
      <p className="md:text-lg text-center font-semibold text-main opacity-60">
        Earn $25 per NFT they purchase
      </p>
      <div className="flex items-center justify-center flex-wrap gap-3 mb-2 w-full">
        {
          <p className="max-sm:w-[90%] border border-dashed border-[#9945FF] bg-[#9945FF14] text-primary max-sm:text-sm font-[600] px-4 py-2 md:px-6 md:py-3 rounded-[10px] text-ellipsis overflow-hidden">
            {isConnected
              ? `${process.env.REACT_APP_BASE_URL}/${address}`
              : "To generate referral link connect your wallet first"}
          </p>
        }

        <CopyToClipboard
          text={
            isConnected
              ? `${process.env.REACT_APP_BASE_URL}/?referralcode=${address}`
              : ""
          }
          onCopy={() => toast.success("Link copied!")}
        >
          <Button className="max-sm:w-[90%] flex justify-center items-center gap-x-2"
            disabled = {!isConnected}
            >
            <BiSolidCopy
              fill="white"
              size={20}
            />
            <span className="text-[#ffffff]">Copy</span>
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default Refer;
