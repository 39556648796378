import React from "react";
import Chart from "react-apexcharts";
import { useNfstay, useTheme } from "../../context/ThemeContext";

const PortfolioValues = ({ totalUsdBalance, rocksPercent, lpPercent, stayPercent }) => {
  const { porfolioDetails, assetPrices, printerDetails, userDetails } = useNfstay();
  const { theme } = useTheme();

  const data = [
    {
      label: "Rocks",
      // value: `${assetPrices.rockSupply ? (((porfolioDetails.rocksTokens + printerDetails.stakedNFTs) / assetPrices.rockSupply) * 100).toFixed(2) : 0}%`,
      value: `${rocksPercent}%`,
    },
    {
      label: "LP Tokens",
      value: `${lpPercent}%`,

      // value: `${assetPrices.lpSupply > 0 ? (((porfolioDetails.lpTokens + Number(userDetails.amount)) / assetPrices.lpSupply) * 100).toFixed(2) : 0}%`,
    },
    {
      label: "Stay",
      value: `${stayPercent}%`,

      // value: `${assetPrices.staySupply > 0 ? (((porfolioDetails.stayTokens + printerDetails.stakedSTAY) / assetPrices.staySupply) * 100).toFixed(2) : 0}%`,
    },
  ];

  const options = {
    series: [
      totalUsdBalance > 0 ? Math.floor(rocksPercent) : 0,
      totalUsdBalance > 0 ? Math.floor(lpPercent) : 0,
      totalUsdBalance > 0 ? Math.floor(stayPercent) : 0,
    ],
    options: {
      chart: {
        type: "donut",
        width: "100%",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 20,
          donut: {
            size: "80%",
          },
          customScale: 1,
          expandOnClick: false,
          dataLabels: {
            offset: 20,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      colors: ["#9945FF", "#20E19F", "#00A3FF"],
      grid: {
        padding: {
          bottom: -80,
        },
      },
    },
  };

  const totalAssets = () => {
    let total = 3;
    if (porfolioDetails.stayTokens + printerDetails.stakedSTAY === 0) total = total - 1;
    if (porfolioDetails.lpTokens + Number(userDetails.amount) === 0) total = total - 1;
    if (porfolioDetails.rocksTokens + printerDetails.stakedNFTs === 0) total = total - 1;
    return total;
  };

  return (
    <div className="relative">
      <Chart
        options={options.options}
        series={options.series}
        type="donut"
      />
      <div className="absolute text-center top-[70px] md:top-[100px] lg:top-[70px] left-[50%] -translate-x-[50%]">
        <p className="text-xl md:text-2xl text-main font-semibold">{totalAssets()}</p>
        <span className="text-main opacity-70 font-semibold">Total assets</span>
      </div>
      <div className="flex flex-col gap-3 mt-4">
        {data.map((item, index) => (
          <div
            key={index}
            className={`flex justify-between items-center bg-transparent border ${theme === "light" ? "border-[#00000012]" : "border-[#FFFFFF17]"} rounded-lg px-2.5 py-2 gap-1`}
          >
            <div className="flex items-center">
              <div
                className="w-1 h-6 rounded-full"
                style={{
                  backgroundColor:
                    item.label === "Stay"
                      ? "#00A3FF"
                      : item.label === "LP Tokens"
                        ? "#20E19F"
                        : "#9945FF",
                }}
              ></div>
              <span className="text-main text-opacity-50 text-sm font-medium ml-2">
                {item.label}
              </span>
            </div>
            <span className="text-main text-opacity-80 text-sm font-semibold">{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioValues;
