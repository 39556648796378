import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTheme } from "../context/ThemeContext";

const CustomModal = ({
  source,
  isTitleCenter,
  setShowModal,
  setLoader2,
  children,
  title,
  onHide,
  setAsset,
  assets,
}) => {
  const modalRef = useRef();
  const { theme } = useTheme();

  useEffect(() => {
    let handler = e => {
      if (!modalRef.current.contains(e.target)) {
        // onHide();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.body.style.overflowY = "scroll";
      document.removeEventListener("mousedown", handler);
    };
  }, [onHide]);

  useEffect(() => {
    document.body.style.overflowY = "hidden";
  }, []);

  return createPortal(
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 z-[9990] blurry"></div>
      <div
        ref={modalRef}
        className="w-[322px] sm:w-[450px] max-w-[450px] model-cont fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[9999] h-auto p-4 md:p-6 bg-bg_theme rounded-lg"
      >
        <div className="relative pb-2 mb-2 font-[500]">
          <p
            className={`text-xl md:text-2xl font-[600] text-main ${isTitleCenter && "text-center"}`}
          >
            {title}
          </p>
          <IoIosCloseCircleOutline
            size={24}
            className="cursor-pointer absolute right-0 top-1"
            fill={theme === "light" ? "#000000" : "#ffffff"}
            onClick={() => {
              onHide();

              if (source === "upgrade" || source === "token") {
                setLoader2(false);
                setAsset(assets[0]);
                setShowModal(source === "token");
              }
            }}
          />
        </div>
        <div>{children}</div>
      </div>
    </>,
    document.getElementById("portal"),
  );
};

export default CustomModal;
