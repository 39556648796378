import React, { memo } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

const Stats = ({ title, subTitle, stats }) => {
  return (
    <div className="flex flex-col gap-4 justify-between lg:flex-row lg:items-center mb-8 md:mb-12">
      <div>
        <h1 className="text-[26px] md:text-[38px] font-bold text-white">{title}</h1>
        <p className="text-[15px] md:text-[18px] mt-1 uppercase opacity-70 text-white">
          {subTitle}
        </p>
      </div>

      <div className="flex gap-2 md:gap-3 flex-wrap">
        {stats.map((itm, idx) => (
          <div
            key={idx}
            className={`${stats?.length - 1 === idx ? "md:min-w-[194px]" : "md:min-w-[165px]"} bg-[#b4b4b442] backdrop-blur-md ${!itm?.isGradient && "rounded-[10px]"} border border-[#FFFFFF40] max-sm:p-2 py-2 px-3 max-md:w-[48%]`}
            style={{
              ...(itm?.isGradient && {
                borderWidth: "1px",
                borderImage: "var(--bg_grad) 1",
              }),
            }}
          >
            <div className="flex gap-[10px]">
              <span className="mt-1">{itm.icon}</span>
              <div>
                <p className="text-white font-semibold text-lg sm:text-xl md:text-2xl mb-1">
                  {itm.count}
                </p>
                <p className="text-white opacity-70 text-sm md:text-base font-medium">{itm.text}</p>
              </div>
              {title === "Printer" && idx == 2 && (
                <span className="mt-1">
                  <Tooltip anchorSelect={`#apr`}>
                    <p className="flex items-center gap-1">APR is based on a $500 Rock NFT</p>
                  </Tooltip>
                  <RiErrorWarningLine
                    id={`apr`}
                    fill="#FFFFFF"
                    size={22}
                    className="cursor-pointer"
                  />
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Stats);
