// import React, { memo, useEffect, useState } from "react";
// import ConfettiExplosion from "react-confetti-explosion";
// import CustomModal from "../../../layouts/Modal";
// import Button from "../../../common/Button";
// import icon from "../../../assets/images/rocks.png";
// import stay from "../../../assets/icons/stay.png";
// import image1 from "../../../assets/images/progress2_1.png";
// import image2 from "../../../assets/images/progress2_2.png";
// import image3 from "../../../assets/images/progress2_3.png";
// import image4 from "../../../assets/images/progress2_4.png";
// import image5 from "../../../assets/images/progress2_5.png";

// const ClaimStay = ({ onHide }) => {
//   const [isExploding, setIsExploding] = useState(false);
//   const [status, setStatus] = useState({ isClaimed: false, isApproved: false, isReInvest: false });
//   const [showLast, setShowLast] = React.useState(false);

//   useEffect(() => {
//     if (status.isClaimed && status.isApproved && status.isReInvest) {
//       setIsExploding(true);
//       setShowLast(true);
//       setTimeout(() => {
//         onHide();
//       }, 2000);
//     }
//   }, [status]);

//   return (
//     <CustomModal
//       title="Stake To Earn"
//       onHide={onHide}
//     >
//       {isExploding && (
//         <div className="flex justify-center">
//           <ConfettiExplosion
//             particleCount={500}
//             zIndex={999999}
//           />
//         </div>
//       )}
//       <div className="pl-4 relative">
//         {!showLast ? (
//           <img
//             className="absolute -left-3 md:-left-2 h-[392px] sm:h-[358px] top-[3px]"
//             src={
//               !status.isClaimed && !status.isApproved && !status.isReInvest
//                 ? image1
//                 : status.isClaimed && !status.isApproved && !status.isReInvest
//                   ? image2
//                   : status.isClaimed && status.isApproved && !status.isReInvest
//                     ? image3
//                     : status.isClaimed && status.isApproved && status.isReInvest
//                       ? image4
//                       : image5
//             }
//             alt="step"
//           />
//         ) : (
//           <img
//             className="absolute -left-3 md:-left-2 h-[392px] sm:h-[358px] top-[3px]"
//             src={image5}
//             alt="step"
//           />
//         )}
//         <div>
//           <div className="flex justify-between items-center mb-2">
//             <div>
//               <p className="text-main font-bold">Claim STAY</p>
//               <p className="max-sm:text-sm max-sm:max-w-[200px] text-main opacity-70 font-medium">
//                 Nunc aliquet pretium quam egestas metus
//               </p>
//             </div>
//             <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
//           </div>
//           <Button
//             onClick={() => setStatus(prev => ({ ...prev, isClaimed: true }))}
//             className="w-full flex items-center gap-2 justify-center text-main"
//             style={{
//               color: status.isClaimed ? "var(--main)" : "#FFFFFF",
//               background: status.isClaimed
//                 ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
//                 : "var(--primary)",
//             }}
//           >
//             <img
//               src={stay}
//               alt="STAY"
//               className="size-6"
//             />
//             Claim STAY
//           </Button>
//         </div>
//         <div>
//           <div className="flex justify-between items-center mb-2">
//             <div>
//               <p className="text-main font-bold">Approve STAY</p>
//               <p className="max-sm:text-sm max-sm:max-w-[200px] text-main opacity-70 font-medium">
//                 Nunc aliquet pretium quam egestas metus
//               </p>
//             </div>
//             <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
//           </div>
//           <Button
//             onClick={() => status.isClaimed && setStatus(prev => ({ ...prev, isApproved: true }))}
//             className="w-full flex items-center gap-2 justify-center text-main"
//             style={{
//               color: !status.isApproved && status.isClaimed ? "#FFFFFF" : "var(--main)",
//               background:
//                 status.isClaimed && !status.isApproved
//                   ? "var(--primary)"
//                   : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
//             }}
//           >
//             <img
//               src={stay}
//               alt="STay"
//               className="size-6"
//             />
//             Approve STAY
//           </Button>
//         </div>

//         <div className="mt-3">
//           <div className="flex justify-between items-start mb-2">
//             <div>
//               <p className="text-main font-bold">Reinvest STAY</p>
//               <p className="max-sm:text-sm max-sm:max-w-[200px] text-main opacity-70 font-medium">
//                 Nunc aliquet pretium quam egestas metus
//               </p>
//             </div>
//             <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 3</span>
//           </div>
//           <Button
//             className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
//             style={{
//               color:
//                 status.isApproved && status.isClaimed && !status.isReInvest
//                   ? "#FFFFFF"
//                   : "var(--main)",
//               background:
//                 status.isClaimed && status.isApproved && !status.isReInvest
//                   ? "var(--primary)"
//                   : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
//             }}
//             onClick={() => status.isApproved && setStatus(prev => ({ ...prev, isReInvest: true }))}
//           >
//             <img
//               src={stay}
//               alt="STAY"
//               className="size-6"
//             />
//             Reinvest STAY
//           </Button>
//         </div>
//         <div className="mt-3">
//           <div className="flex justify-between items-start mb-2">
//             <div>
//               <p className="text-main font-bold">Reinvest STAY</p>
//               <p className="max-sm:text-sm max-sm:max-w-[200px] text-main opacity-70 font-medium">
//                 Nunc aliquet pretium quam egestas metus
//               </p>
//             </div>
//             <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 3</span>
//           </div>
//           <Button
//             className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
//             style={{
//               color:
//                 status.isApproved && status.isClaimed && !status.isReInvest
//                   ? "#FFFFFF"
//                   : "var(--main)",
//               background:
//                 status.isClaimed && status.isApproved && !status.isReInvest
//                   ? "var(--primary)"
//                   : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
//             }}
//             onClick={() => status.isApproved && setStatus(prev => ({ ...prev, isReInvest: true }))}
//           >
//             <img
//               src={stay}
//               alt="STAY"
//               className="size-6"
//             />
//             Reinvest STAY
//           </Button>
//         </div>
//         <div className="mt-3">
//           <div className="flex justify-between items-start mb-2">
//             <div>
//               <p className="text-main font-bold">Success</p>
//               <p className="max-sm:text-sm max-sm:max-w-[200px] text-main opacity-70 font-medium">
//                 Nunc aliquet pretium quam egestas metus
//               </p>
//             </div>
//             <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 4</span>
//           </div>
//         </div>
//       </div>
//     </CustomModal>
//   );
// };

// export default memo(ClaimStay);

import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../../layouts/Modal";
import Button from "../../../common/Button";
import lp from "../../../assets/icons/stayUsdc.png";
import stay from "../../../assets/icons/stay.png";

import image1 from "../../../assets/images/progress3_1.png";
import image2 from "../../../assets/images/progress3_2.png";
import image3 from "../../../assets/images/progress3_3.png";
import image4 from "../../../assets/images/progress3_4.png";
import image5 from "../../../assets/images/progress3_5.png";
import image6 from "../../../assets/images/progress3_6.png";
import { useNfstay } from "../../../context/ThemeContext";
import toast from "react-hot-toast";
import BLoader from "../../../common/BLoader";

const ClaimStay = ({ maxBalance, onHide }) => {
  const {
    farmingPendingShare,
    Farming_ClaimRewards,
    checkTokenApprovalToZapper,
    approveToZapper,
    zapper_swapping,
    Farming_stakeLps,
  } = useNfstay();

  const [depositValue, setDepositValue] = useState("");
  const [claimedValue, setClaimedValue] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [isLoadingClaim, setLoadingClaim] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);
  const [isLoadingZap, setIsLoadingZap] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState({
    isClaimed: false,
    isApproved: false,
    isZap: false,
    isReInvest: false,
  });
  const [showLast, setShowLast] = React.useState(false);
  useEffect(() => {
    if (status.isClaimed && status.isZap && status.isApproved && status.isReInvest) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  const approveToken = async () => {
    try {
      setIsLoadingApprove(true);
      await approveToZapper("STAY");
      status.isClaimed && setStatus(prev => ({ ...prev, isApproved: true }));
      setIsApproved(true);
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const handleZapIn = async () => {
    try {
      setIsLoadingZap(true);
      const tx = await zapper_swapping("STAY", claimedValue);
      setClaimedValue("");
      setDepositValue(tx);
      // await maxBalance("LP");
      status.isApproved && setStatus(prev => ({ ...prev, isZap: true }));
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingZap(false);
    }
  };

  const claim = async () => {
    try {
      setLoadingClaim(true);
      if (farmingPendingShare === 0) throw new Error("No Rewards Available");
      const val = await Farming_ClaimRewards();
      setClaimedValue(val);
      const res = await checkTokenApprovalToZapper("STAY");
      if (res) {
        setStatus(prev => ({ ...prev, isClaimed: true, isApproved: true }));
      } else {
        setStatus(prev => ({ ...prev, isClaimed: true }));
      }
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoadingClaim(false);
    }
  };

  const handleDeposit = async () => {
    try {
      setIsLoadingDeposit(true);
      await Farming_stakeLps(depositValue);
      toast.success("Transaction Successful");
      setDepositValue("");
      // setInputVal("");
      status.isZap && setStatus(prev => ({ ...prev, isReInvest: true }));
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingDeposit(false);
    }
  };

  return (
    <CustomModal
      title="Stake To Earn"
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[340px] sm:h-[377px] top-[3px]"
            src={
              !status.isClaimed && !status.isApproved && !status.isZap && !status.isReInvest
                ? image1
                : status.isClaimed && !status.isApproved && !status.isZap && !status.isReInvest
                  ? image2
                  : status.isClaimed && status.isApproved && !status.isZap && !status.isReInvest
                    ? image3
                    : status.isClaimed && status.isApproved && status.isZap && !status.isReInvest
                      ? image4
                      : status.isClaimed && status.isApproved && status.isZap && status.isReInvest
                        ? image5
                        : image6
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[350px] sm:h-[380px] top-[3px]"
            src={image6}
            alt="step"
          />
        )}
        <div className="mb-1">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Claim STAY</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            onClick={claim}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color: !status.isClaimed ? "#FFFFFF" : "var(--main)",

              background: status.isClaimed
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
            disabled={
              isLoadingZap ||
              isLoadingApprove ||
              isLoadingClaim ||
              isLoadingDeposit ||
              status.isClaimed
            }
          >
            {isLoadingClaim ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={stay}
                  alt="STAY"
                  className="size-6"
                />{" "}
                Claim STAY
              </>
            )}
          </Button>
        </div>
        <div className="mb-2">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve STAY</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            onClick={approveToken}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color: !status.isApproved && status.isClaimed ? "#FFFFFF" : "var(--main)",
              background:
                status.isClaimed && !status.isApproved
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            disabled={
              isLoadingZap ||
              isLoadingApprove ||
              isLoadingClaim ||
              isLoadingDeposit ||
              !status.isClaimed ||
              status.isApproved
            }
          >
            {isLoadingApprove ? (
              <BLoader />
            ) : (
              <>
                {!status.isApproved ? (
                  <>
                    <img
                      src={stay}
                      alt="STay"
                      className="size-6"
                    />
                    Approve STAY
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Create LP Token</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 3</span>
          </div>
          <Button
            onClick={() => handleZapIn()}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color:
                status.isClaimed && status.isApproved && !status.isZap ? "#FFFFFF" : "var(--main)",
              background:
                status.isClaimed && status.isApproved && !status.isZap
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            disabled={
              isLoadingZap ||
              isLoadingApprove ||
              isLoadingClaim ||
              isLoadingDeposit ||
              !status.isClaimed ||
              !status.isApproved ||
              status.isZap
            }
          >
            {isLoadingZap ? <BLoader /> : <>ZAP IN ⚡️</>}
          </Button>
        </div>

        <div className="mt-2">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Deposit LP</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 4</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color:
                status.isApproved && status.isClaimed && status.isZap && !status.isReInvest
                  ? "#FFFFFF"
                  : "var(--main)",
              background:
                status.isClaimed && status.isApproved && status.isZap && !status.isReInvest
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            onClick={() => handleDeposit()}
            disabled={
              isLoadingZap ||
              isLoadingApprove ||
              isLoadingClaim ||
              isLoadingDeposit ||
              !status.isClaimed ||
              !status.isApproved ||
              !status.isZap ||
              status.isReInvest
            }
          >
            {isLoadingDeposit ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={lp}
                  alt="lp"
                  className="h-6"
                />{" "}
                Deposit LP
              </>
            )}
          </Button>
        </div>
        <div className="mt-2">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(ClaimStay);
