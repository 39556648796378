import React, { useEffect, useState } from "react";
import { useNfstay, useTheme } from "../../context/ThemeContext";

const Badges2 = ({ active, setActive, gradient }) => {
  const { printerDetails } = useNfstay();
  const data = [
    "Rent",
    "Studio",
    "Apartment",
    "Home",
    "Development",
    "Commercial",
    "Luxury",
    "Business",
    "Mogul",
    "World",
  ];

  const getEstimatedLevel = _quantity => {
    let _level;
    if (_quantity === 0) _level = 0;
    else if (_quantity <= 5) _level = 1;
    else if (_quantity <= 15) _level = 2;
    else if (_quantity <= 25) _level = 3;
    else if (_quantity <= 35) _level = 4;
    else if (_quantity <= 50) _level = 5;
    else if (_quantity <= 75) _level = 6;
    else if (_quantity <= 100) _level = 7;
    else if (_quantity <= 150) _level = 8;
    else if (_quantity <= 250) _level = 9;
    else _level = 10;

    return _level;
  };

  // const getEstimatedLevel = _quantity => {
  //   let _level;
  //   if (_quantity === 0) _level = 0;
  //   else if (_quantity <= 2) _level = 1;
  //   else if (_quantity <= 4) _level = 2;
  //   else if (_quantity <= 6) _level = 3;
  //   else if (_quantity <= 8) _level = 4;
  //   else if (_quantity <= 10) _level = 5;
  //   else if (_quantity <= 12) _level = 6;
  //   else if (_quantity <= 14) _level = 7;
  //   else if (_quantity <= 16) _level = 8;
  //   else if (_quantity <= 18) _level = 9;
  //   else _level = 10;

  //   return _level;
  // };

  const { theme } = useTheme();

  return (
    <div className="rounded-[11px_11px_0_0] overflow-hidden">
      <div className="w-full flex max-lg:gap-3 max-lg:bg-bg_theme max-lg:p-2 items-center overflow-x-scroll">
        {data?.map((itm, idx) => (
          <Div
            key={idx}
            idx={idx}
            theme={theme}
            active={active}
            onClick={() => setActive(idx)}
            gradient={gradient}
            getEstimatedLevel={getEstimatedLevel}
            printerDetails={printerDetails}
          >
            <P>{idx + 1}</P>
            <span className="text-sm pl-[6px]">{itm}</span>
          </Div>
        ))}
      </div>
    </div>
  );
};

const P = ({ children }) => {
  return (
    <p className="text-center min-w-[18px] max-w-[18px] bg-main text-main_r text-xs rounded font-extrabold">
      {children}
    </p>
  );
};

const Div = ({
  idx,
  active,
  children,
  theme,
  gradient,
  printerDetails,
  getEstimatedLevel,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={`${printerDetails.currentLevel !== getEstimatedLevel(printerDetails.stakedNFTs) && active !== getEstimatedLevel(printerDetails.stakedNFTs) - 1 && idx === getEstimatedLevel(printerDetails.stakedNFTs) - 1 && "glow-btn background-color: var(--bg_grad)"} cursor-pointer flex items-center flex-1 p-3 border-bg_theme2
            ${active === idx ? `${theme === "light" && "max-lg:[&>*]:text-white"} max-lg:bg-primary bg-bg_theme` : "bg-bg_theme2 [&>*]:opacity-80"}
            max-lg:rounded-lg `}
      style={{
        borderWidth: window.innerWidth > 1024 && "4px 0 0 0",
        borderImage:
          window.innerWidth > 1024
            ? gradient === idx
              ? "var(--bg_grad) 1"
              : "var(--bg_theme2) 1"
            : "",
      }}
    >
      {children}
    </div>
  );
};

export default Badges2;
