import React from "react";
import { CountUp } from "use-count-up";

const Counter = ({ ...rest }) => {
  return (
    <CountUp
      isCounting
      start={0}
      duration={1}
      {...rest}
    />
  );
};

export default Counter;
