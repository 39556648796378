import "@rainbow-me/rainbowkit/styles.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./App.css";
import { Route, useLocation } from "react-router-dom";
import { ROUTES } from "./utils/routes";
import { useEffect, useState } from "react";
import { useTheme } from "./context/ThemeContext";
import { Toaster } from "react-hot-toast";
import Loader from "./components/Loader";

import { RainbowKitProvider, darkTheme, lightTheme } from "@rainbow-me/rainbowkit";


function App() {
  const { theme } = useTheme();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const interval = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const themeConfig = {
    modalSize: "compact",
    theme:
      theme !== "light"
        ? darkTheme({
            accentColor: "#9945ff",
            accentColorForeground: "white",
            borderRadius: "medium",
            fontStack: "rounded",
            overlayBlur: "small",
          })
        : lightTheme({
            accentColor: "#9945ff",
            accentColorForeground: "white",
            borderRadius: "medium",
            fontStack: "rounded",
            overlayBlur: "small",
          }),
  };

  return (
      <RainbowKitProvider {...themeConfig}>
        {isLoading && <Loader />}
        {ROUTES?.map((itm, idx) => (
          <Route
            key={`${itm.path}-${idx}`}
            exact
            path={itm.path}
            component={itm.component}
          />
        ))}

        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </RainbowKitProvider>
  );
}

export default App;
