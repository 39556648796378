import React from "react";

const PortFolioTable = ({ paginatedData }) => {
  return (
    <>
      <div className="w-full overflow-x-scroll table-scroll">
        <table className="w-full text-sm text-left rtl:text-right mb-5">
          <thead className={`text-xs uppercase bg-bg_theme2 rounded-[8px]`}>
            <tr>
              <th
                scope="col"
                className="px-4 py-3"
              >
                #
              </th>
              <th
                scope="col"
                className="px-4 py-3"
              >
                Source
              </th>
              <th
                scope="col"
                className="px-4 py-3"
              >
                Transaction
              </th>
              <th
                scope="col"
                className="px-4 py-3"
              >
                Amount
              </th>
              <th
                scope="col"
                className="px-4 py-3"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((itm, idx) => (
              <tr key={idx}>
                <th
                  scope="row"
                  className="px-4 py-2 font-medium text-main"
                >
                  {idx + 1}
                </th>
                <td className="text-nowrap px-4 py-2 text-main font-medium">{itm.source}</td>
                <td className="text-nowrap px-4 py-2 font-medium text-[#7F8798]">{itm.date}</td>
                <td className="text-nowrap px-4 py-2 font-medium text-main">
                  {Number(itm.amountEther).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  STAY
                </td>
                <td className="text-nowrap px-4 py-2">
                  <button
                    style={{ cursor: "default" }}
                    className="flex items-center p-4 py-[2px] text-sm font-medium border rounded-full"
                  >
                    <span className=" ext-[12px] text-primary">Claimed</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {paginatedData?.length === 0 && (
          <p className="w-full text-center font-medium">No history found.</p>
        )}
      </div>
    </>
  );
};

export default PortFolioTable;
