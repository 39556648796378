import React, { memo, useEffect, useState } from "react";

import lp from "../../assets/icons/stayUsdc.png";
import image1 from "../../assets/images/progress2_1.png";
import image2 from "../../assets/images/progress2_2.png";
import image3 from "../../assets/images/progress2_3.png";
import image4 from "../../assets/images/progress2_4.png";
import image5 from "../../assets/images/progress2_5.png";
import stay from "../../assets/icons/stay.png";
import rocks from "../../assets/images/rocks.png";

import { useNfstay } from "../../context/ThemeContext";
import toast from "react-hot-toast";
import CustomModal from "../../layouts/Modal";
import ConfettiExplosion from "react-confetti-explosion";
import Button from "../../common/Button";
import BLoader from "../../common/BLoader";

const StakeROCKsModal = ({ isApprovedRocksAndStay, setInputVal, inputVal, onHide }) => {
  const [isApproved, setIsApproved] = useState(false);
  const [isApprovedROCKS, setIsApprovedROCKS] = useState(false);
  const [isExploding, setIsExploding] = useState(false);
  const [status, setStatus] = useState({ isZap: false, isApproved: false, isDeposit: false });
  const [showLast, setShowLast] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeposit, setIsLoadingDeposit] = useState(false);

  const { IsapproveStayToPrinter, approveRocks, approveStayToPrinter, stakeRocks } = useNfstay();

  useEffect(() => {
    if (status.isZap && status.isApproved && status.isDeposit) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  const handleDeposit = async () => {
    try {
      setIsLoadingDeposit(true);
      await stakeRocks(inputVal);
      toast.success("Transaction Successful");
      setInputVal("");
      status.isApproved && setStatus(prev => ({ ...prev, isDeposit: true }));
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingDeposit(false);
    }
  };

  const approveStay = async asset => {
    try {
      setIsLoadingApprove(true);
      await approveStayToPrinter(asset);

      status.isZap && setStatus(prev => ({ ...prev, isApproved: true }));
      setIsApproved(true);
    } catch (e) {
      console.log(e);
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingApprove(false);
    }
  };

  const handleApproveRocks = async () => {
    try {
      setIsLoading(true);
      await approveRocks();
      const res = await IsapproveStayToPrinter();
      if (res) {
        setIsApprovedROCKS(true);
        setStatus(prev => ({ ...prev, isZap: true, isApproved: true }));
      } else {
        setStatus(prev => ({ ...prev, isZap: true }));
      }
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (isApprovedRocksAndStay.isApproveRocks) {
        setStatus(prev => ({ ...prev, isZap: true }));
        if (isApprovedRocksAndStay.isApprovedStay)
          setStatus(prev => ({ ...prev, isApproved: true }));
      }
    })();
  }, []);

  return (
    <CustomModal
      title="Stake To Earn"
      onHide={onHide}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[268px] sm:h-[293px] top-[3px]"
            src={
              !status.isZap && !status.isApproved && !status.isDeposit
                ? image1
                : status.isZap && !status.isApproved && !status.isDeposit
                  ? image2
                  : status.isZap && status.isApproved && !status.isDeposit
                    ? image3
                    : status.isZap && status.isApproved && status.isDeposit
                      ? image4
                      : image5
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[268px] sm:h-[293px] top-[3px]"
            src={image5}
            alt="step"
          />
        )}
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve ROCKs</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color: status.isZap ? "var(--main)" : "#FFFFFF",
              background: status.isZap
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
            onClick={handleApproveRocks}
            disabled={isLoadingDeposit || isLoading || isLoadingApprove || status.isZap}
          >
            {isLoading ? (
              <BLoader />
            ) : (
              <>
                {!status.isZap ? (
                  <>
                    <img
                      src={rocks}
                      alt="rocks"
                      className="size-6"
                    />
                    Approve ROCKs
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>
        <div className="mt-1">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve STAY </p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            onClick={approveStay}
            className="w-full flex items-center gap-2 justify-center text-main"
            style={{
              color: !status.isApproved && status.isZap ? "#FFFFFF" : "var(--main)",
              background:
                status.isZap && !status.isApproved
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            disabled={
              isLoadingDeposit ||
              isLoading ||
              isLoadingApprove ||
              !status.isZap ||
              status.isApproved
            }
          >
            {isLoadingApprove ? (
              <BLoader />
            ) : (
              <>
                {!status.isApproved ? (
                  <>
                    <img
                      src={stay}
                      alt="STAY"
                      className="size-6"
                    />{" "}
                    Approve STAY
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Stake ROCKs</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 3</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color:
                status.isApproved && status.isZap && !status.isDeposit ? "#FFFFFF" : "var(--main)",
              background:
                status.isZap && status.isApproved && !status.isDeposit
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            onClick={handleDeposit}
            disabled={
              isLoadingDeposit ||
              isLoading ||
              isLoadingApprove ||
              !status.isZap ||
              !status.isApproved ||
              status.isDeposit
            }
          >
            {isLoadingDeposit ? (
              <BLoader />
            ) : (
              <>
                <img
                  src={rocks}
                  alt="rocks"
                  className="size-6"
                />
                Stake ROCKs
              </>
            )}
          </Button>
        </div>
        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(StakeROCKsModal);
