import React, { memo, useState } from "react";
import Stay from "../assets/images/Stay.png";
import Button from "../common/Button";
import { useNfstay, useTheme } from "../context/ThemeContext";
import toast from "react-hot-toast";
import BLoader from "../common/BLoader";
import { supportedChain } from "../utils/constants";
import { useAccount } from "wagmi";

const StayEarned = ({ farmingPendingShare, text, showModal }) => {
  const { theme } = useTheme();
  const { chainId, isConnected } = useAccount();

  const { Farming_ClaimRewards } = useNfstay();
  const [loading, setLoading] = useState(false);

  const handleShow = () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    if (farmingPendingShare == 0) {
      toast.error("No Rewards Available");
      return;
    }

    showModal();
  };
  const withdraw = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }
    try {
      setLoading(true);
      if (farmingPendingShare == 0) throw new Error("No Rewards Available");
      await Farming_ClaimRewards();
      toast.success("Transaction Successful");
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <div>
          <span className="text-xl md:text-[26px] text-main font-bold">Stay Earned</span>
        </div>
        <div>
          <img src={Stay} />
        </div>
      </div>
      <div className="space-y-4 mt-4">
        <input
          className="w-full bg-[#F1F4F9] bg-bg_theme2 p-4 rounded-md"
          value={farmingPendingShare.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          // placeholder="0.0"
          readOnly
        />
        <div className="flex max-md:flex-wrap gap-3 justify-between items-center">
          <Button
            onClick={() => {
              handleShow();
            }}
            className="w-full"
            disabled={loading}
          >
            Claim & Reinvest
          </Button>
          <Button
            className="max-md:w-full text-main max-sm:text-sm py-2 md:py-3 px-6 min-w-[94px]"
            style={{
              border: `1px solid ${theme === "light" ? "rgba(1, 6, 15, 0.12)" : "#FFFFFF1F"}`,
              borderRadius: "10px",
              background: "transparent",
            }}
            onClick={withdraw}
            disabled={loading}
          >
            {loading ? <BLoader isWhite={false} /> : "Claim"}
          </Button>
        </div>
      </div>
      <p className="text-main mt-4 text-[14px] opacity-60 font-medium">{text}</p>
    </div>
  );
};

export default memo(StayEarned);
