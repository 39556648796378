import React, { memo, useEffect, useState } from "react";
import rocks from "../assets/images/rocks.png";
import stay from "../assets/images/Stay.png";
import lp from "../assets/images/lp.png";
import Button from "../common/Button";
import { useTheme, useNfstay } from "../context/ThemeContext";
import Select, { components } from "react-select";
import { BNB, lpAddress, stayAddress, supportedChain, usdcAddress } from "../utils/constants";
import toast from "react-hot-toast";
import BLoader from "../common/BLoader";
import { useAccount } from "wagmi";
import { IoChevronDown } from "react-icons/io5";

const Option = props => (
  <components.Option {...props}>
    <img
      src={props.data.icon}
      alt="logo"
      className="swap-icon"
      loading="lazy"
    />
    {props.data.label}
  </components.Option>
);

const StartEarn = ({
  isStayKing = false,
  isFarm = false,
  balanceTitle,
  loader,
  btnTitle1,
  btnTitle2,
  onClick1,
  assets,
  asset,
  handleChangeAsset,
  desc,
  inputVal,
  setInputVal,
  maxbal,
  maxBalance,
}) => {
  const { theme } = useTheme();

  const { chainId, isConnected } = useAccount();

  const { userDetails, Farming_Unstake } = useNfstay();

  const [unstakeLoading, setunstakeLoading] = useState(false);
  const [unstakeAmount, setUnstakeAmount] = useState("");
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {asset?.icon && (
        <img
          src={asset?.icon}
          alt="Icon"
          loading="lazy"
          className="swap-selected-icon"
        />
      )}
      {children}
    </components.SingleValue>
  );

  const unstake = async () => {
    if (!isConnected) {
      toast.error("Wallet is not Connected");
      return;
    }
    if (chainId !== supportedChain) {
      toast.error("Switch to BNB Chain");
      return;
    }

    try {
      setunstakeLoading(true);
      await Farming_Unstake(unstakeAmount);
      toast.success("Transaction Successful");
      setUnstakeAmount("");
      await maxBalance();
    } catch (e) {
      toast.error(e.reason || e.message || e.Error || "Something went wrong");
    } finally {
      setunstakeLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2 items-center">
          <img src={isStayKing ? stay : isFarm ? lp : rocks} />
          <span className="text-xl md:text-[26px] text-main font-bold">Start Earning</span>
        </div>
        {/* {!isStayKing && (
          <div className="text-end">
            <p className="text-main font-bold">8 Hours EPOCH Duration</p>
            <span className="text-main text-sm opacity-70 font-bold">Boardroom Regulation</span>
          </div>
        )} */}
      </div>
      <div className="flex justify-between items-center mb-2">
        <span className="text-main text-sm opacity-70 font-bold">{balanceTitle}</span>
        <p className="text-main text-sm font-bold">
          <span className="text-main opacity-70">Your Balance:</span>{" "}
          {Number(maxbal - inputVal >= 0 ? maxbal - inputVal : 0).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="space-y-4">
        <div className="flex gap-2 justify-between items-center max-sm:flex-wrap-reverse">
          <div className="w-full bg-[#F1F4F9] bg-bg_theme2 p-2 rounded-md flex gap-2 items-center">
            <input
              type="number"
              onChange={e => {
                setInputVal(e.target.value);
              }}
              value={inputVal}
              placeholder="Amount For Liquidity"
              pattern="\d*"
              min={0.0}
              className="w-full min-h-full bg-transparent"
            />
            <Button
              className="border-primary text-main"
              style={{ background: "transparent" }}
              onClick={() => {
                setInputVal(maxbal);
              }}
            >
              Max
            </Button>
            <Button
              className="min-w-fit max-md:hidden"
              onClick={() => onClick1(inputVal)}
              disabled={!(inputVal > 0) || parseFloat(inputVal) > parseFloat(maxbal) || loader}
            >
              {btnTitle1}
            </Button>
          </div>

          {assets && assets?.length > 0 && (
            <div className="relative max-sm:w-full">
              <Select
                className="h-[40px] sm:h-[50px] react-select-modal"
                classNamePrefix="react-select-modal"
                value={asset}
                options={assets}
                isSearchable={false}
                onChange={handleChangeAsset}
                styles={{
                  singleValue: base => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                  }),
                }}
                components={{
                  Option,
                  SingleValue,
                }}
              />
              <IoChevronDown
                className="absolute right-2 max-sm:top-3 top-4"
                stroke={"var(--main)"}
              />
            </div>
          )}
        </div>
        <Button
          className="w-full mt-3 md:hidden"
          onClick={() => onClick1(inputVal)}
          disabled={!(inputVal > 0) || parseFloat(inputVal) > parseFloat(maxbal) || loader}
        >
          {btnTitle1}
        </Button>
      </div>
      <div className="flex justify-between items-center mt-4 mb-2">
        <span className="text-main text-sm opacity-70 font-bold">Unstake LP tokens</span>
        <p className="text-main text-sm font-bold">
          <span className="text-main opacity-70">Staked:</span>
          {Number(
            userDetails.amount - unstakeAmount < 0 ? 0 : userDetails.amount - unstakeAmount,
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </div>
      <div className="space-y-4">
        <div className="w-full bg-[#F1F4F9] bg-bg_theme2 p-2 rounded-md flex gap-2 items-center">
          <input
            type="number"
            pattern="\d*"
            placeholder="Amount to be Unstake"
            min={0.0}
            value={unstakeAmount}
            className="w-full min-h-full bg-transparent"
            onChange={e => setUnstakeAmount(e.target.value)}
          />
          <Button
            className="border-primary text-main"
            style={{ background: "transparent" }}
            onClick={() => setUnstakeAmount(userDetails.amount)}
          >
            Max
          </Button>
          <Button
            className="max-md:hidden py-3 px-6 font-semibold text-main min-w-[143px]"
            style={{
              border: `1px solid ${theme === "light" ? "rgba(1, 6, 15, 0.12)" : "#FFFFFF1F"}`,
              borderRadius: "10px",
              background: "transparent",
            }}
            onClick={unstake}
            disabled={
              unstakeLoading ||
              parseFloat(unstakeAmount) > parseFloat(userDetails.amount) ||
              !(unstakeAmount > 0)
            }
          >
            {unstakeLoading ? <BLoader isWhite={false} /> : btnTitle2}
          </Button>
        </div>
        <button
          className="md:hidden w-full text-sm py-2 font-semibold px-6"
          style={{
            border: `1px solid ${theme === "light" ? "rgba(1, 6, 15, 0.12)" : "#FFFFFF1F"}`,
            borderRadius: "10px",
          }}
          onClick={unstake}
          disabled={
            unstakeLoading ||
            parseFloat(unstakeAmount) > parseFloat(userDetails.amount) ||
            !(unstakeAmount > 0)
          }
        >
          {unstakeLoading ? <BLoader isWhite={false} /> : btnTitle2}
        </button>
      </div>
      {desc && <p className="text-main mt-4 text-[14px] opacity-60 font-medium">{desc}</p>}
    </div>
  );
};

export default memo(StartEarn);
