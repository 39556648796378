import React, { useEffect, useState } from "react";
import Button from "../../common/Button";
import card1 from "../../assets/images/card1.png";
import card2 from "../../assets/images/card2.png";
import card3 from "../../assets/images/card3.png";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useNfstay } from "../../context/ThemeContext";
import { useAccount } from "wagmi";

const Cards = () => {
  const {
    account,
    Farming_getUserInfo,
    userDetails,
    assetPrices,
    farmingPendingShare,
    printerRewards,
    printerDetails,
    getPrinterDetails,
    Farming_getPendingShare,
    floorPrice,
    getFloorPrice,
  } = useNfstay();
  const router = useHistory();
  const { isConnected } = useAccount();
  const data = [
    {
      image: card1,
      title: "Printer",
      priceTitle: "Unclaimed Rewards",
      price: (
        (Number(printerRewards) + Number(printerDetails.pendingRewards)) *
        Number(assetPrices.stayPrice)
      ).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      image: card2,
      title: "Farm",
      priceTitle: "Unclaimed Rewards",
      price: (
        (Number(farmingPendingShare) + Number(userDetails.pendingRewards)) *
        Number(assetPrices.stayPrice)
      ).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      image: card3,
      title: "Marketplace",
      priceTitle: "Floor Price",
      price: floorPrice,
    },
  ];

  useEffect(() => {
    const details = async () => {
      await getFloorPrice();
    };
    details();
  }, []);

  useEffect(() => {
    (async () => {
      if (isConnected) {
        await Farming_getUserInfo();
        await getPrinterDetails();
        await Farming_getPendingShare();
      }
    })();
  }, [account, isConnected]);

  return (
    <div className="w-full flex flex-wrap justify-between gap-6 py-3">
      {data?.map((itm, idx) => (
        <div
          key={idx}
          className="w-full md:flex-1 bg-bg_theme rounded-xl p-4 md:p-5"
        >
          <Image
            key={idx}
            itm={itm}
          />
          <div className="my-5 flex items-center justify-between">
            <p className="text-main text-xl md:text-2xl font-semibold mb-2">{itm.title}</p>
            <p
              className="px-2 py-[2px] text-white text-sm font-medium rounded-md"
              style={{
                background: `linear-gradient(92.87deg, #9945FF 0%, #20E19F 100%),
linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))`,
              }}
            >
              {itm.priceTitle}: ${itm.price}
            </p>
          </div>
          <Button
            className="w-full mt-3 text-main"
            onClick={() => router.push(`/${itm?.title?.toLowerCase()}`)}
            style={{ background: "transparent" }}
          >
            Go to {itm.title}
          </Button>
        </div>
      ))}
    </div>
  );
};

const Image = ({ itm }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className="w-full h-[300px] rounded-lg overflow-hidden relative">
      <img
        src={itm.image}
        loading="lazy"
        className="w-full h-full object-cover"
        onLoad={() => setIsLoading(false)}
        alt={itm.title}
      />
      {isLoading && (
        <Skeleton
          sx={{ position: "absolute", top: 0, left: 0, right: 0 }}
          animation="wave"
          variant="rounded"
          height="100%"
        />
      )}
    </div>
  );
};
export default Cards;
