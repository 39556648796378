import React, { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import Card from "../common/Card";
import Stats from "../components/Stats";
import SwapD from "../components/SwapD";
import image from "../assets/images/event.jpeg";
import image2 from "../assets/images/buy2.png";
import { useNfstay, useTheme } from "../context/ThemeContext";
import usdc from "../assets/icons/usdc.png";
import rocks from "../assets/icons/rocks.png";
import SwapModalD from "../components/modal/swap/SwapModalD";
import { IoDiamondOutline } from "react-icons/io5";
import CCountdown from "../components/printer/CCountdown";

const SwapperD = () => {
  const { theme } = useTheme();
  const { itemsRemaining, discountedPrice, getItemsReminaing, getDicountedPrice } = useNfstay();

  const stayOptions1 = [{ value: "ROCKS", label: "ROCKS", icon: rocks }];

  const stayOptions2 = [{ value: "USDC", label: "USDC", icon: usdc, price: discountedPrice }];

  const [stayData1, setStayData1] = useState(stayOptions1?.[0] || {});
  const [stayData2, setStayData2] = useState(stayOptions2?.[0] || {});
  const [stayValue, setStayValue] = useState("");

  const [showModal, setShowModal] = useState(false);

  const stats = [
    {
      icon: (
        <IoDiamondOutline
          stroke="white"
          size={22}
        />
      ),
      count: itemsRemaining,
      text: "ROCKS Remaining",
    },
  ];

  useEffect(() => {
    (async () => {
      await getDicountedPrice();
      await getItemsReminaing();
    })();
  }, []);
  const handleStayChange2 = value => {
    setStayData2(value);
  };

  return (
    <Layout>
      <CCountdown source="eventTime" />
      <Stats
        title="Mint Rocks"
        subTitle=""
        stats={stats}
      />

      <div className="flex flex-wrap gap-4 w-full">
        <Card className={`w-full overflow-hidden flex gap-4 flex-wrap`}>
          <div className="w-full md:w-[calc(100%_-_516px)] relative max-md:gap-y-7 flex justify-between max-md:flex-wrap-reverse">
            <div className="max-md:hidden absolute md:top-[-1.5rem] w-[300px] left-[-2.5rem] md:h-[calc(100%_+_3rem)]">
              <img
                src={image}
                alt=""
                loading="lazy"
                className="h-full"
              />
            </div>
            <div className="w-full md:w-[calc(100%_-_250px)] ml-auto relative md:flex md:flex-col md:justify-center">
              <h2 className="text-main text-2xl md:text-3xl font-bold">Exclusive 20% OFF</h2>
              <p className="text-sm text-main opacity-70 max-w-[350px] my-7">
                <ul className="list-disc space-y-1">
                  <li>This Exclusive Offer is availbale only to attendees of Next Blockchain Expo.</li>
                  <li>
                    Please note that this link will expire in 72 hours after the conclusion of the
                    event.
                  </li>
                  <li>Act fast! Only 100 ROCKS are available for this special event offer.</li>
                </ul>
              </p>
            </div>
          </div>
          <div
            className={`w-[500px] ${theme === "dark" && "bg-[#424344]"} rounded-xl p-5 border border-[#00000014]`}
          >
            <SwapD
              source="swapper"
              title=""
              btn="Buy Now"
              from="You're Buying"
              to="You're Paying"
              onClick={() => setShowModal(true)}
              value={stayValue}
              setValue={setStayValue}
              options1={stayOptions1 || []}
              options2={stayOptions2}
              data1={stayData1}
              data2={stayData2}
              // handleChange1={handleStayChange1}
              handleChange2={handleStayChange2}
            />
          </div>
          <div className="relative md:hidden w-full">
            <div className="md:hidden translate-y-[13px] text-center">
              <img
                src={image}
                alt=""
                loading="lazy"
                className="h-full w-full"
              />
            </div>
          </div>
        </Card>
      </div>

      {showModal && (
        <SwapModalD
          setStayValue={setStayValue}
          onHide={() => setShowModal(false)}
          selected1={stayData1}
          selected2={stayData2}
          amount={stayValue}
        />
      )}
    </Layout>
  );
};

export default SwapperD;
