import React, { memo, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import CustomModal from "../../layouts/Modal";
import Button from "../../common/Button";
import stay from "../../assets/icons/stay.png";
import image1 from "../../assets/images/progress1_1.png";
import image2 from "../../assets/images/progress1_2.png";
import image3 from "../../assets/images/progress1_3.png";
import image4 from "../../assets/images/progress1_4.png";
import { useNfstay } from "../../context/ThemeContext";
import BLoader from "../../common/BLoader";
import { stayAddress } from "../../utils/constants";
import toast from "react-hot-toast";

const STAYBuyBadgeModal = ({
  source,
  setShowModal,
  setLoader2,
  isApprovedSTAY,
  badgeId,
  onHide,
  setAsset,
  assets,
}) => {
  const [isApproved, setIsApproved] = useState(false);
  const [isExploding, setIsExploding] = React.useState(false);
  const [status, setStatus] = useState({ isApproved: false, isBuy: false });
  const [showLast, setShowLast] = useState(false);
  const [isLoadingApprove, setIsloadingApprove] = useState(false);
  const [isLoadingBuy, setIsLoadingBuy] = useState(false);
  const { approveStayToPrinter, IsapproveStayToPrinter, buyBadge } = useNfstay();

  useEffect(() => {
    if (status.isBuy && status.isApproved) {
      setIsExploding(true);
      setShowLast(true);
      setTimeout(() => {
        onHide();
      }, 2000);
    }
  }, [status]);

  useEffect(() => {
    setStatus({ isApproved: isApprovedSTAY, isBuy: false });
  }, []);

  const handleApprove = async () => {
    try {
      setIsloadingApprove(true);
      await approveStayToPrinter();
      setStatus({ isApproved: true, isBuy: false });
      setIsApproved(true);
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsloadingApprove(false);
    }
  };

  const handleBuy = async () => {
    try {
      setIsLoadingBuy(true);
      await buyBadge(badgeId, stayAddress);
      toast.success("Transaction Successful");

      setStatus({ isApproved: true, isBuy: true });
    } catch (e) {
      toast.error(e.reason || e.message || "Something went wrong");
    } finally {
      setIsLoadingBuy(false);
    }
  };

  return (
    <CustomModal
      setShowModal={setShowModal}
      setLoader2={setLoader2}
      title="Buy Badge"
      onHide={onHide}
      source={source}
      setAsset={setAsset}
      assets={assets}
    >
      {isExploding && (
        <div className="flex justify-center">
          <ConfettiExplosion
            particleCount={500}
            zIndex={999999}
          />
        </div>
      )}
      <div className="pl-4 relative">
        {!showLast ? (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={
              !status.isApproved && !status.isBuy
                ? image1
                : status.isApproved && !status.isBuy
                  ? image2
                  : status.isApproved && status.isBuy
                    ? image3
                    : image4
            }
            alt="step"
          />
        ) : (
          <img
            className="absolute -left-1 h-[193px] sm:h-[207px] top-[3px]"
            src={image4}
            alt="step"
          />
        )}
        <div>
          <div className="flex justify-between items-center mb-2">
            <div>
              <p className="text-main font-bold">Approve STAY</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 1</span>
          </div>
          <Button
            onClick={() => handleApprove()}
            className="w-full flex items-center gap-2 justify-center text-main"
            disabled={status.isApproved || isLoadingApprove}
            style={{
              color: status.isApproved ? "var(--main)" : "#FFFFFF",
              background: status.isApproved
                ? `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`
                : "var(--primary)",
            }}
          >
            {isLoadingApprove ? (
              <BLoader />
            ) : (
              <>
                {!status.isApproved ? (
                  <>
                    <img
                      src={stay}
                      alt="stay"
                      className="size-6"
                    />
                    Approve STAY
                  </>
                ) : (
                  "Approved"
                )}
              </>
            )}
          </Button>
        </div>

        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Buy Badge</p>
            </div>
            <span className="max-sm:text-sm text-main opacity-70 font-medium">Step 2</span>
          </div>
          <Button
            className={`w-full flex items-center gap-2 justify-center border border-primary text-main`}
            style={{
              color: status.isApproved && !status.isBuy ? "#FFFFFF" : "var(--main)",
              background:
                status.isApproved && !status.isBuy
                  ? "var(--primary)"
                  : `linear-gradient(90.57deg, rgba(153, 69, 255, 0.2) 0%, rgba(32, 225, 159, 0.2) 100%)`,
            }}
            onClick={() => handleBuy()}
            disabled={!status.isApproved || isLoadingBuy}
          >
            {isLoadingBuy ? <BLoader /> : "Buy Badge"}
          </Button>
        </div>
        <div className="mt-3">
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="text-main font-bold">Success</p>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default memo(STAYBuyBadgeModal);
